import React from "react";

import SideDrawer from "components/Common Components/SideDrawer/SideDrawer";
import ScorerList from "components/Scorer/Index/ScorerList";

function ScorerIndex() {
  return (
    <>
      <SideDrawer>
        <div style={{ padding: "3.2rem" }}>
          <ScorerList />
        </div>
      </SideDrawer>
    </>
  );
}

export default ScorerIndex;
