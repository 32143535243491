import React from "react";

import SideDrawer from "components/Common Components/SideDrawer/SideDrawer";
import ViewGroundForm from "components/Grounds/ViewGround/ViewGroundForm";

function ViewScorer() {
  return (
    <>
      <SideDrawer>
        <div style={{ padding: "3.2rem" }}>
          <ViewGroundForm />
        </div>
      </SideDrawer>
    </>
  );
}

export default ViewScorer;
