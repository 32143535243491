/* eslint-disable react-hooks/rules-of-hooks */

import React, { useState } from "react";

// components
import Step from "../Step";
import StepProgressBar from "../StepProgressBar";
import BackdropLoader from "components/Common Components/Loaders/LoaderBackdrop";
import { toast } from "react-toastify";

// hooks
import { useForm, FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";

// services
import { createTournament } from "services/tournamentsV3";

// styles
import styles from "./style.module.scss";

interface IndexProps {
  config: any;
  formType: string;
  tournamentId?: string;
}

const index = ({ config, formType, tournamentId }: IndexProps) => {
  const navigate = useNavigate();

  const methods = useForm();
  const {
    trigger,
    formState: { errors },
  } = methods;
  const [step, setStep] = useState(1);

  const [pageLoading, setPageLoading] = useState(false);

  const createNewTournament = async (formData: any) => {
    setPageLoading(true);
    let result = await createTournament(formData);
    if (result && !result.isError) {
      navigate("/tournament-v3");
      toast.success("Successfully created!");
    } else {
      toast.error("Tournament creation failed");
    }
    setPageLoading(false);
  };

  const onSubmit = (data: any) => {
    if (formType === "edit") {
      data.tournamentId = tournamentId;
    }
    console.log(data);
    createNewTournament(data);
  };

  const nextStep = async () => {
    if (formType === "view") {
      setStep((prev) => prev + 1);
    }
    //
    else {
      const stepFields = config[step - 1].inputFields.map(
        (field: any) => field.inputKey
      );
      const isStepValid = await trigger(stepFields);

      if (isStepValid) {
        setStep((prev) => prev + 1);
      }
    }
  };

  const prevStep = () => setStep((prev) => prev - 1);

  const allStepNames = config.map((step: any) => step.heading);

  if (pageLoading) {
    return <BackdropLoader loaderState={pageLoading} />;
  }

  return (
    <div className={styles["step-form-container"]}>
      <StepProgressBar
        currentStepNumber={step}
        allStepNames={allStepNames}
        setStep={setStep}
      />
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className={styles["form-container"]}
        >
          {config.map((stepConfig: any, index: number) => {
            return (
              step === index + 1 && (
                <Step
                  key={index}
                  heading={stepConfig.heading}
                  inputFields={stepConfig.inputFields}
                  unregister={methods.unregister}
                  formType={formType}
                  errors={errors} // Pass errors to the Step component
                />
              )
            );
          })}

          <div className={styles["buttons-container"]}>
            {step > 1 && (
              <div onClick={prevStep} className={styles["back-button"]}>
                PREV
              </div>
            )}
            {step < config.length ? (
              <div onClick={nextStep} className={styles["next-button"]}>
                NEXT
              </div>
            ) : formType !== "view" ? (
              <button
                type="submit"
                disabled={!methods.formState.isValid}
                style={{
                  backgroundColor: !methods.formState.isValid ? "#dbeb9a" : "",
                }}
                className={styles["submit-button"]}
              >
                Submit
              </button>
            ) : (
              <></>
            )}
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default index;
