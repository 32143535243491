const colors = {
  white: "#FFF",

  amberTen: "#FFF7E5",
  amberTwenty: "#FFEDC8",
  amberSixty: "#D29822",

  grayTen: "#F5F5F5",
  grayTwenty: "#EDEDED",
  grayThirty: "#E3E3E3",
  grayForty: "#CCC",
  grayFifty: "#B0B0B0",
  graySixty: "#757575",
  graySeventy: "#545454",
  grayEighty: "#333",
  grayNinety: "#262626",

  redTen: "#FDF3F2",
  redTwenty: "#F9D9D7",
  redThirty: "#F2ADA5",
  redForty: "#E7685A",
  redFifty: "#DC2804",
  redSixty: "#B32306",
  redSeventy: "#8A1E08",

  greenTen: "#EDF7F2",
  greenTwenty: "#BBE2CE",
  greenForty: "#50B47F",
  greenFifty: "#05945B"
};

export default colors;
