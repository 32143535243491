/* eslint-disable no-unused-vars */
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { Divider, Grid, Typography } from "@mui/material";
import { toast } from "react-toastify";

import FieldMapper from "components/Common Components/Form Fields/FormUtils/FieldMapper";
import FormButton from "components/Common Components/Buttons/FormButton";
import BasicModal from "components/Common Components/Modal/BasicModal";
import DiscardModal from "components/Common Components/Modal/DiscardModal";

import {
  leftSideColConfig,
  rightSideColConfig,
  sportsSectionConfig,
  cricketColConfig,
  badmintonColConfig,
  footballColConfig,
} from "../formConfig";
import { createGround, formatGroundData } from "services/grounds";
import colors from "config/colorConfig";
import styles from "../styles.module.css";
import BackdropLoader from "components/Common Components/Loaders/LoaderBackdrop";

import {
  getAllStateListMapped,
  getCitiesListMapped,
} from "components/Common Components/Location/helper";

import EntityDropDown from "components/Common Components/Dropdowns/EntityDropdown";

import {
  getAllSportsListMapped,
  getAllAmenitiesListMapped,
  getCricketListMapped,
  getBadmintonListMapped,
  getFootballListMapped,
} from "components/Common Components/Sports/helper";

function CreateScorerForm() {
  const navigate = useNavigate();

  const methods = useForm({
    criteriaMode: "firstError",
    mode: "onBlur",
    shouldFocusError: true,
  });

  const {
    setValue,
    handleSubmit,
    formState: { isValid, isDirty },
  } = methods;

  const [modalState, setModalState] = useState(false);
  const [loaderState, setLoaderState] = useState(false);
  const [selectedState, setSelectedState] = useState<string>("");
  const [selectedSports, setSelectedSports] = useState<string[]>([]);

  const [selectedAmenities, setSelectedAmenities] = useState<string[]>([]);
  const [selectedCricket, setSelectedCricket] = useState<string[]>([]);
  const [selectedBadminton, setSelectedBadminton] = useState<string[]>([]);
  const [selectedFootball, setSelectedFootball] = useState<string[]>([]);

  const onStateValueChange = useCallback(
    (item: any) => {
      setSelectedState(item.id);
      setValue("city", { id: " ", label: " " });
    },
    [setSelectedState]
  );

  const onAmenitiesValueChange = useCallback(
    (values: any) => {
      setSelectedAmenities(values.map((val: any) => val.id));
    },
    [setSelectedAmenities]
  );

  const onCricketValueChange = useCallback(
    (values: any) => {
      setSelectedCricket(values.map((val: any) => val.id));
    },
    [setSelectedCricket]
  );

  const onBadmintonValueChange = useCallback(
    (values: any) => {
      setSelectedBadminton(values.map((val: any) => val.id));
    },
    [setSelectedBadminton]
  );

  const onFootballValueChange = useCallback(
    (values: any) => {
      setSelectedFootball(values.map((val: any) => val.id));
    },
    [setSelectedFootball]
  );

  const onSportsValueChange = useCallback(
    (values: any) => {
      setSelectedSports(values.map((val: any) => val.id));
    },
    [setSelectedSports]
  );

  const formSubmitHandler = async (formData: any) => {
    setLoaderState(true);
    const formattedGroundData = formatGroundData(formData);

    const result = await createGround(formattedGroundData);
    if (result && !result?.isError) {
      toast.success("Ground created successfully!");
      navigate("/grounds");
    } else {
      toast.error(result?.reason);
    }
    setLoaderState(false);
  };

  const formConfig = {
    amenities: {
      disabled: false,
      optionsFetcher: getAllAmenitiesListMapped,
      OptionsDisplayComp: EntityDropDown,
      populateOptions: true,
      onValueChange: onAmenitiesValueChange,
    },
    uploadPhoto: {
      disabled: false,
    },
    groundName: {
      disabled: false,
    },
    groundAddress: {
      disabled: false,
    },
    googleMapsLink: {
      disabled: false,
    },
    groundOwner: {
      disabled: false,
    },
    groundContact: {
      disabled: false,
    },
    groundSports: {
      disabled: false,
    },
    cricketPitchType: {
      disabled: false,
    },
    footballPitchType: {
      disabled: false,
    },
    badmintonCourtType: {
      disabled: false,
    },
    groundImages: {
      disabled: false,
    },
    isActive: {
      disabled: false,
    },
    state: {
      disabled: false,
      optionsFetcher: getAllStateListMapped,
      OptionsDisplayComp: EntityDropDown,
      onValueChange: onStateValueChange,
    },
    city: {
      disabled: false,
      optionsFetcher: getCitiesListMapped,
      OptionsDisplayComp: EntityDropDown,
      idParam: selectedState,
    },
    sports: {
      disabled: false,
      optionsFetcher: getAllSportsListMapped,
      OptionsDisplayComp: EntityDropDown,
      populateOptions: true,
      onValueChange: onSportsValueChange,
    },
  };

  const cricketFormConfig = {
    sportsCricket: {
      disabled: false,
      optionsFetcher: getCricketListMapped,
      OptionsDisplayComp: EntityDropDown,
      populateOptions: true,
      onValueChange: onCricketValueChange,
    },
  };

  const badmintonFormConfig = {
    sportsBadminton: {
      disabled: false,
      optionsFetcher: getBadmintonListMapped,
      OptionsDisplayComp: EntityDropDown,
      populateOptions: true,
      onValueChange: onBadmintonValueChange,
    },
  };

  const footballFormConfig = {
    sportsFootball: {
      disabled: false,
      optionsFetcher: getFootballListMapped,
      OptionsDisplayComp: EntityDropDown,
      populateOptions: true,
      onValueChange: onFootballValueChange,
    },
  };

  return (
    <>
      <div className={styles["form-heading"]}>Add ground</div>

      <div className={styles["form-container"]}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(formSubmitHandler)}>
            <Grid container columnSpacing={6}>
              <Grid item xs={12} md={6}>
                <FieldMapper
                  fieldsConfig={leftSideColConfig(formConfig, "create")}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FieldMapper
                  fieldsConfig={rightSideColConfig(formConfig, "create")}
                />
              </Grid>
            </Grid>

            <Divider sx={{ my: 3 }} />

            <Grid container columnSpacing={6}>
              <Grid item xs={12} md={8}>
                <FieldMapper
                  fieldsConfig={sportsSectionConfig(formConfig, "create")}
                />
              </Grid>
              {selectedSports.includes("CRICKET") && (
                <Grid item xs={12} md={8}>
                  <Typography sx={{ mt: 1, fontSize: 16, color: "blue" }}>
                    1. CRICKET
                  </Typography>
                  <FieldMapper
                    fieldsConfig={cricketColConfig(cricketFormConfig, "create")}
                  />
                </Grid>
              )}
              {selectedSports.includes("FOOTBALL") && (
                <Grid item xs={12} md={8}>
                  <Typography sx={{ mt: 2, fontSize: 16, color: "blue" }}>
                    2. FOOTBALL
                  </Typography>
                  <FieldMapper
                    fieldsConfig={footballColConfig(
                      footballFormConfig,
                      "create"
                    )}
                  />
                </Grid>
              )}
              {selectedSports.includes("BADMINTON") && (
                <Grid item xs={12} md={8}>
                  <Typography sx={{ mt: 2, fontSize: 16, color: "blue" }}>
                    3. BADMINTON
                  </Typography>
                  <FieldMapper
                    fieldsConfig={badmintonColConfig(
                      badmintonFormConfig,
                      "create"
                    )}
                  />
                </Grid>
              )}
            </Grid>

            <Divider sx={{ mt: 2, mb: 0 }} />

            <Grid container item columnGap={3}>
              <FormButton
                type="button"
                fontColor={colors.redSixty}
                backgroundColor={colors.white}
                borderColor={colors.grayThirty}
                onClick={() =>
                  !isDirty
                    ? navigate("/tournament")
                    : setModalState(!modalState)
                }
              >
                Discard
              </FormButton>

              <FormButton
                type="submit"
                fontColor={colors.white}
                backgroundColor={colors.grayNinety}
                disabledColor={colors.grayFifty}
                disabledBackground={colors.grayTwenty}
                disabled={!isValid}
              >
                Save ground
              </FormButton>
            </Grid>
          </form>
        </FormProvider>
      </div>
      <BasicModal modalState={modalState} handleModalState={setModalState}>
        <DiscardModal
          modalState={modalState}
          handleModalState={setModalState}
        />
      </BasicModal>
      <BackdropLoader loaderState={loaderState} />
    </>
  );
}

export default CreateScorerForm;
