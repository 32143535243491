// CSVDownloadComponent.tsx
import React from "react";
import { Dialog, DialogContent, DialogActions, Grid } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import FormButton from "components/Common Components/Buttons/FormButton";
import FieldMapper from "components/Common Components/Form Fields/FormUtils/FieldMapper";
import colors from "config/colorConfig";
import { toast } from "react-toastify";

interface CSVDownloadComponentProps {
  open: boolean;
  fieldsMapperConfig?: any;
  handleDownloadDialogClose: () => void; 
  getPaginatedAPI: (data: any) => any;
  fileName?: string
}


const CSVDownloadComponent: React.FC<CSVDownloadComponentProps> = ({
  open,
  fieldsMapperConfig = {},
  handleDownloadDialogClose,
  getPaginatedAPI,
  fileName,
}) => {
  const methods = useForm();

  const { reset } = methods;

  const formSubmitHandler = async (data: any) => {
    const payload = {
      page: parseInt(data.offset) + 1,
      pageSize: parseInt(data.limit),
    };
    let result = await getPaginatedAPI(payload);
    if (result && !result?.isError) {
      const resultList = result?.data?.list;
      if (!resultList?.length) {
        toast.error("No Records were found!!");
      } else {
        downloadCSV(resultList, fileName || "result.csv");
      }
      handleDownloadDialogClose();
      reset();
    } else {
      toast.error(result?.reason);
    }
  };
  
  const downloadCSV = (data: any, filename: any) => {
    const columns = Object.keys(data[0]);
  
    const csvContent = columns.join(",") + "\n";
  
    const csvRows = data.map((item: any) =>
      columns.map((column) => item[column]).join(",")
    );
  
    const csv = csvContent + csvRows.join("\n");
  
    const blob = new Blob([csv], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
  };

  return (
    <Dialog open={open} onClose={handleDownloadDialogClose} maxWidth  ="md" fullWidth>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(formSubmitHandler)}>
          <DialogContent>
            <Grid container>
              <Grid item xs={12} md={6}>
                <FieldMapper fieldsConfig={[fieldsMapperConfig]} />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <FormButton
              fontColor={colors.white}
              backgroundColor={colors.grayNinety}
              disabledColor={colors.grayFifty}
              disabledBackground={colors.grayTwenty}
              onClick={handleDownloadDialogClose}
            >
              Cancel
            </FormButton>
            <FormButton
              type="submit"
              fontColor={colors.white}
              backgroundColor={colors.grayNinety}
              disabledColor={colors.grayFifty}
              disabledBackground={colors.grayTwenty}
            >
              Submit
            </FormButton>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default CSVDownloadComponent;