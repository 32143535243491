import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { toast } from "react-toastify";

import DataTable from "components/Common Components/DataTable/Index";
import HeaderOptionsButton from "components/Common Components/Buttons/HeaderOptionsButton";
import EmptyList from "components/Common Components/EmptyList";

import { logFirebaseEvent } from "config/firebase";
import { MatchListConfig } from "./listConfig";
import colors from "config/colorConfig";
import { MATCH_PAGE_SIZE, STATUS_LIST } from "utils/constants";
import { createBulkMatches, getMatchesListPaginated } from "services/matches";
import styles from "./styles.module.css";
import CSVDownloadComponent from "components/Common Components/CSVDownload/CSVDownloadComponent";
import { FieldType } from "components/Common Components/Form Fields/FieldAttributes";
import { getTournamentsListPaginated } from "services/touranments";
import CSVUploadComponent from "components/Common Components/CSVUpload/CSVUploadComponent";
import BackdropLoader from "components/Common Components/Loaders/LoaderBackdrop";

function MatchList() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { tournamentId } = useParams();

  const [dataList, setDataList] = useState([]);
  const [totalRows, setTotalRows] = useState(10);
  const [emptyListFlag, setEmptyListFlag] = useState(false);
  const [loaderState, setLoaderState] = useState(false)

  const [open, setOpen] = React.useState(false);
  const [csvLoader, setCSVLoader] = React.useState(false);
  const [downloadDialogOpen, setDownloadDialogOpen] = React.useState(false);
  const [csvDataList, setCSVDataList] = useState<{ [key: string]: string }[]>(
    []
  );
  const [myFiles, setMyFiles] = useState<File[]>([]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleDownloadDialogOpen = () => setDownloadDialogOpen(true);
  const handleDownloadDialogClose = () => setDownloadDialogOpen(false);

  const requiredColumns: any = {
    tournament_id: "253dba22-7fa1-11ee-b962-0242ac120002",
    team_a: "ea165ee8-9348-11ee-b9d1-0242ac120003",
    team_b: "ea165ee8-9348-11ee-b9d1-0242ac120004",
    match_sport: "cricket",
    scorer_id: "989c969f-f4a9-4b94-b391-86180c7ea475",
    match_type: "Semi-Final",
    start_time: "2023-12-05 00:00:00",
    end_time: "2023-12-10 13:19:17",
    no_of_overs: 16,
    super_over: 0,
    stadium: "National Stadium",
    city: "Mumbai",
    ball_type: "Tennis",
    lineup_size: 15,
    max_overs_per_bowler: 6,
    pitch_type: "Green",
  };

  const sampleRow: any = {
    tournament_id: "253dba22-7fa1-11ee-b962-0242ac120002",
    match_type: "Semi-Final",
    start_time: "2023-12-05 00:00:00",
    stadium: "National Stadium",
    no_of_overs: 16,
    ball_type: "Tennis",
    link: "https://www.youtube.com/watch?v=rKZqbjnjiDs",
    end_time: "2023-12-10 13:19:17",
    lineup_size: 15,
    team_a: "ea165ee8-9348-11ee-b9d1-0242ac120003",
    team_b: "ea165ee8-9348-11ee-b9d1-0242ac120004",
    scorer_id: "989c969f-f4a9-4b94-b391-86180c7ea475",
    match_sport: "cricket",
    max_overs_per_bowler: 6,
    super_over: 0,
    city: "Mumbai",
    pitch_type: "Green",
  };

  const getMatchesList = async (reqData: any) => {
    setLoaderState(true)
    let result = await getMatchesListPaginated(reqData);
    if (result && !result?.isError) {
      const matchesList = result?.data?.list;
      if (!matchesList?.length) {
        setEmptyListFlag(true);
        setLoaderState(false)
        return;
      }
      setDataList(matchesList);
      setTotalRows(result?.data?.pagination?.totalRows);
    } else {
      toast.error(result?.reason);
    }
    setLoaderState(false)
  };

  const handleRowClick = (matchData: any) => {
    const matchId = matchData?.row?.match_id;
    logFirebaseEvent({
      event_name: "match_view",
      tournament_id: tournamentId,
      match_id: matchId,
    });
    navigate(`/match/view/${matchId}`);
  };

  const handleCSVDataList = async () => {
    if (csvDataList.length > 0) {
      try {
        setCSVLoader(true);
        await createBulkMatches(csvDataList);
        setCSVLoader(false);
        handleClose();
        toast.success("Successfully created!");
      } catch (error: any) {
        toast.error(error);
      }
    }
  };

  const handleSortingAndPagination = (
    page: number,
    pageSize: number,
    sortKey: string,
    sortType: string
  ) => {
    let data: any = {};
    let sortOptions: any = {};

    if (sortKey) {
      sortOptions.key = sortKey;
      sortOptions.type = sortType.toUpperCase();

      data.sortby = sortOptions;
    }
    data.page = page;
    data.pageSize = pageSize;
    data.filter = {
      tournament_id: tournamentId,
    };
    getMatchesList(data);
  };

  const checkTournamentStatus = () => {
    if (
      state?.tournament_status === STATUS_LIST.CANCELLED ||
      state?.tournament_status === STATUS_LIST.ABONDENED ||
      state?.tournament_status === STATUS_LIST.COMPLETED
    ) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    const reqData = {
      page: 1,
      pageSize: MATCH_PAGE_SIZE,
      sortby: {
        key: "match_type",
        type: "ASC",
      },
      filter: {
        tournament_id: tournamentId,
      },
    };
    getMatchesList(reqData);
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <div
          className={styles["form-heading"]}
          data-testid="match-index-heading"
        >
          Matches &nbsp;
          <span className={styles["tournament_name"]}>
            {`(${state?.tournament_name})`}
          </span>
        </div>
        {checkTournamentStatus() ? (
          <div>
            <HeaderOptionsButton
              fontColor={colors.white}
              backgroundColor={colors.grayNinety}
              onClick={() => {
                logFirebaseEvent({
                  event_name: "match_add",
                  tournament_id: tournamentId,
                });
                navigate("/match/create/" + tournamentId, {
                  state: { tournamentName: state?.tournament_name },
                });
              }}
              disabled={
                state?.tournament_status === STATUS_LIST.CANCELLED ||
                state?.tournament_status === STATUS_LIST.ABONDENED ||
                state?.tournament_status === STATUS_LIST.COMPLETED
              }
            >
              Add Match +
            </HeaderOptionsButton>

            <HeaderOptionsButton
              fontColor={colors.white}
              backgroundColor={colors.grayNinety}
              onClick={handleOpen}
              data-testid="upload-matches-btn"
            >
              Bulk Upload
            </HeaderOptionsButton>
            <HeaderOptionsButton
              fontColor={colors.white}
              backgroundColor={colors.grayNinety}
              onClick={handleDownloadDialogOpen}
              data-testid="download-matches-btn"
            >
              Bulk Download
            </HeaderOptionsButton>
          </div>
        ) : (
          <></>
        )}
      </Box>
      {dataList?.length > 0 ? (
        <DataTable
          columns={MatchListConfig}
          data={dataList}
          totalRows={totalRows}
          pageSize={MATCH_PAGE_SIZE}
          onRowClick={handleRowClick}
          sortPaginationHandler={handleSortingAndPagination}
        />
      ) : (
        <></>
      )}

      {emptyListFlag ? <EmptyList message="No Matches Created!" /> : <></>}

      <CSVUploadComponent
        handleClose={handleClose}
        open={open}
        csvLoader={csvLoader}
        setCSVDataList={setCSVDataList}
        requiredColumns={requiredColumns}
        handleCSVDataList={handleCSVDataList}
        setMyFiles={setMyFiles}
        myFiles={myFiles}
        integerColumnFields={[
          "no_of_overs",
          "lineup_size",
          "max_overs_per_bowler",
          "super_over",
        ]}
        sampleRow={sampleRow}
      />

      <CSVDownloadComponent
        open={downloadDialogOpen}
        handleDownloadDialogClose={handleDownloadDialogClose}
        getPaginatedAPI={getTournamentsListPaginated}
        fileName={"matches_list.csv"}
        fieldsMapperConfig={[
          {
            type: FieldType.NUMBER,
            label: "Total records",
            name: "limit",
            required: true,
            rules: {
              required: {
                value: true,
                message: "This field is mandatory.",
              },
            },
          },
          {
            type: FieldType.NUMBER,
            label: "Start from",
            name: "offset",
            required: true,
            rules: {
              required: {
                value: true,
                message: "This field is mandatory.",
              },
            },
          },
        ]}
      />
      <BackdropLoader loaderState={loaderState}/>
    </>
  );
}

export default MatchList;
