/*eslint-disable*/
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { Box, Grid } from "@mui/material";
import { toast } from "react-toastify";

import FieldMapper from "components/Common Components/Form Fields/FormUtils/FieldMapper";
import HeaderOptionsButton from "components/Common Components/Buttons/HeaderOptionsButton";
import ViewTeamMenu from "./KebabMenu";

import { leftSideColConfig, rightSideColConfig } from "../formConfig";
import { logFirebaseEvent } from "config/firebase";
import colors from "config/colorConfig";
import { formatGetTeamData, getTeamDetailsById } from "services/teams";
import styles from "../styles.module.css";
import editIcon from "assets/editIcon.svg";
import BackdropLoader from "components/Common Components/Loaders/LoaderBackdrop";

import {
  getAllStateListMapped,
  getCitiesListMapped,
} from "components/Common Components/Location/helper";

import EntityDropDown from "components/Common Components/Dropdowns/EntityDropdown";

function ViewTeamForm() {
  const methods = useForm({
    criteriaMode: "firstError",
    mode: "onSubmit",
    shouldFocusError: true,
  });
  const { setValue } = methods;

  const { teamId } = useParams();
  const navigate = useNavigate();

  const [formData, setFormData] = useState<any>();
  const [loaderState, setLoaderState] = useState(false);
  const [selectedState, setSelectedState] = useState<string>("");

  const onStateValueChange = useCallback(
    (item: any) => {
      setSelectedState(item.id);
      setValue("city", { id: " ", label: " " });
    },
    [setSelectedState]
  );

  const initializePageData = async () => {
    setLoaderState(true);
    if (teamId) {
      let result = await getTeamDetailsById(teamId);
      if (result && !result?.isError && result?.data.length > 0) {
        let formattedData = formatGetTeamData(result?.data[0]);
        setTeamData(formattedData);
        setFormData(formattedData);
      } else {
        toast.error(result?.reason);
      }
    } else {
      toast.error("There was some error! Please try again");
    }
    setLoaderState(false);
  };

  const setTeamData = (data: any) => {
    for (const [key, value] of Object.entries(data)) {
      value && setValue(key, value);
    }
    setValue("is_active", data?.is_active);
    setValue("city_id", data?.city);
  };

  useEffect(() => {
    initializePageData();
  }, []);

  const formConfig = {
    teamName: {
      disabled: true,
    },
    teamDisplayName: {
      disabled: true,
    },
    tickerDisplayName: {
      disabled: true,
    },
    coachName: {
      disabled: true,
    },
    teamParent: {
      disabled: true,
    },
    uploadPhoto: {
      disabled: true,
    },
    homeCity: {
      disabled: true,
    },
    isActive: {
      disabled: true,
    },
    facebook: {
      disabled: true,
    },
    instagram: {
      disabled: true,
    },
    linkedin: {
      disabled: true,
    },
    primaryColor: {
      disabled: true,
    },
    secondaryColor: {
      disabled: true,
    },
    state: {
      disabled: true,
      optionsFetcher: getAllStateListMapped,
      OptionsDisplayComp: EntityDropDown,
      onValueChange: onStateValueChange,
    },
    city: {
      disabled: true,
      optionsFetcher: getCitiesListMapped,
      OptionsDisplayComp: EntityDropDown,
      idParam: selectedState,
    },
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <div className={styles["form-heading"]}>
          <Box display="flex" alignItems="center">
            <span> Team Details</span>
          </Box>
        </div>

        <div className={styles["header-options"]}>
          <HeaderOptionsButton
            fontColor={colors.grayNinety}
            backgroundColor={colors.white}
            borderColor={colors.grayThirty}
            onClick={() => {
              navigate("/team/edit/" + teamId, {
                state: { teamData: formData },
              });
            }}
          >
            Edit Team &nbsp;
            <img
              src={editIcon}
              alt="edit-icon"
              style={{ verticalAlign: "text-bottom" }}
            />
          </HeaderOptionsButton>
          <ViewTeamMenu selectedTeamData={formData} />
        </div>
      </Box>

      <div className={styles["form-container"]}>
        <FormProvider {...methods}>
          <form>
            <Grid container spacing={6}>
              <Grid item xs={12} md={6}>
                <FieldMapper
                  fieldsConfig={leftSideColConfig(formConfig, "view")}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FieldMapper
                  fieldsConfig={rightSideColConfig(formConfig, "view")}
                />
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </div>
      <BackdropLoader loaderState={loaderState} />
    </>
  );
}

export default ViewTeamForm;
