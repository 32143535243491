import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { toast } from "react-toastify";

import HeaderOptionsButton from "components/Common Components/Buttons/HeaderOptionsButton";
import DataTable from "components/Common Components/DataTable/Index";
import EmptyList from "components/Common Components/EmptyList";

import { logFirebaseEvent } from "config/firebase";
import colors from "config/colorConfig";
import { TeamListConfig } from "./listConfig";
import { TEAM_PAGE_SIZE } from "utils/constants";
import { createBulkTeams, getTeamsListPaginated } from "services/teams";
import styles from "./styles.module.css";
import CSVUploadComponent from "components/Common Components/CSVUpload/CSVUploadComponent";
import CSVDownloadComponent from "components/Common Components/CSVDownload/CSVDownloadComponent";
import { FieldType } from "components/Common Components/Form Fields/FieldAttributes";
import BackdropLoader from "components/Common Components/Loaders/LoaderBackdrop";

function TeamsList() {
  const navigate = useNavigate();

  const [dataList, setDataList] = useState([]);
  const [totalRows, setTotalRows] = useState(10);
  const [emptyListFlag, setEmptyListFlag] = useState(false);
  const [loaderState, setLoaderState] = useState(false)

  const [open, setOpen] = React.useState(false);
  const [downloadDialogOpen, setDownloadDialogOpen] = React.useState(false);
  const [csvLoader, setCSVLoader] = React.useState(false);
  const [csvDataList, setCSVDataList] = useState<{ [key: string]: string }[]>(
    []
  );
  const [myFiles, setMyFiles] = useState<File[]>([]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleDownloadDialogOpen = () => setDownloadDialogOpen(true);
  const handleDownloadDialogClose = () => setDownloadDialogOpen(false);

  const requiredColumns: any = {
    short_name: "short_name",
    name: "name",
    is_active: "is_active",
    team_city: "team_city",
    image_url: "image_url",
    tournament_id: "tournament_id",
  };

  const sampleRow: any = {
    name: "Aditya",
    short_name: "Adi",
    team_city: "Gurugram",
    tournament_id: 1,
    image_url: "https://img_url",
    is_active: 1,
  };

  const handleCSVDataList = async () => {
    if (csvDataList.length > 0) {
      try {
        setCSVLoader(true);
        await createBulkTeams(csvDataList);
        setCSVLoader(false);
        handleClose();
        toast.success("Successfully created!");
      } catch (error: any) {
        toast.error(error);
      }
    }
  };

  const getTeamsList = async (reqData: any) => {
    setLoaderState(true)
    let result = await getTeamsListPaginated(reqData);
    if (result && !result?.isError) {
      const teamsList = result?.data?.list;
      if (!teamsList?.length) {
        setLoaderState(false)
        setEmptyListFlag(true);
        return;
      }
      setDataList(teamsList);
      setTotalRows(result?.data?.pagination?.totalRows);
    } else {
      toast.error(result?.reason);
    }
    setLoaderState(false)
  };

  const handleRowClick = (teamData: any) => {
    const teamId = teamData?.row?.team_id;
    logFirebaseEvent({
      event_name: "team_view",
      team_id: teamId,
    });
    navigate(`/team/view/${teamId}`);
  };

  const handleSortingAndPagination = (
    page: number,
    pageSize: number,
    sortKey: string,
    sortType: string
  ) => {
    const reqData: any = {};
    const sortOptions: any = {};

    if (sortKey) {
      sortOptions.key = sortKey;
      sortOptions.type = sortType.toUpperCase();

      reqData.sortby = sortOptions;
    }
    reqData.page = page;
    reqData.pageSize = pageSize;

    getTeamsList(reqData);
  };

  useEffect(() => {
    logFirebaseEvent({ event_name: "team_home" });
    getTeamsList({});
  }, []);

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <div className={styles["form-heading"]}>Teams &nbsp;</div>
        <div>
          <HeaderOptionsButton
            fontColor={colors.white}
            backgroundColor={colors.grayNinety}
            onClick={() => {
              logFirebaseEvent({ event_name: "team_add" });
              navigate("/team/create");
            }}
          >
            Add Teams +
          </HeaderOptionsButton>
          <HeaderOptionsButton
            fontColor={colors.white}
            backgroundColor={colors.grayNinety}
            onClick={handleOpen}
            data-testid="upload-tournament-btn"
          >
            Bulk Upload
          </HeaderOptionsButton>
          <HeaderOptionsButton
            fontColor={colors.white}
            backgroundColor={colors.grayNinety}
            onClick={handleDownloadDialogOpen}
            data-testid="upload-tournament-btn"
          >
            Bulk Download
          </HeaderOptionsButton>
        </div>
      </Box>
      {dataList?.length ? (
        <DataTable
          columns={TeamListConfig}
          data={dataList}
          totalRows={totalRows}
          pageSize={TEAM_PAGE_SIZE}
          onRowClick={handleRowClick}
          sortPaginationHandler={handleSortingAndPagination}
        />
      ) : (
        <></>
      )}
      {emptyListFlag ? <EmptyList message="No Teams Created!" /> : <></>}
      <CSVUploadComponent
        open={open}
        handleClose={handleClose}
        csvLoader={csvLoader}
        setCSVDataList={setCSVDataList}
        requiredColumns={requiredColumns}
        handleCSVDataList={handleCSVDataList}
        setMyFiles={setMyFiles}
        myFiles={myFiles}
        integerColumnFields={["tournament_id", "is_active"]}
        sampleRow={sampleRow}
      />
      <CSVDownloadComponent
        open={downloadDialogOpen}
        handleDownloadDialogClose={handleDownloadDialogClose}
        fieldsMapperConfig={[
          {
            type: FieldType.NUMBER,
            label: "Total records",
            name: "limit",
            required: true,
            rules: {
              required: {
                value: true,
                message: "This field is mandatory.",
              },
            },
          },
          {
            type: FieldType.NUMBER,
            label: "Start from",
            name: "offset",
            required: true,
            rules: {
              required: {
                value: true,
                message: "This field is mandatory.",
              },
            },
          },
        ]}
        fileName={"team_list.csv"}
        getPaginatedAPI={getTeamsListPaginated}
      />
      <BackdropLoader loaderState={loaderState} />
    </>
  );
}

export default TeamsList;
