export const MATCHTYPE = [
  { label: "League", value: "league match" },
  { label: "Knock-Out", value: "knock-out match" },
  { label: "Quarter-Final", value: "quarter-final" },
  { label: "Semi-Final", value: "semi-final" },
  { label: "Final", value: "final" },
];

export const NUMBEROFOVERS = [
  { label: "5 Overs", value: 5 },
  { label: "6 Overs", value: 6 },
  { label: "7 Overs", value: 7 },
  { label: "8 Overs", value: 8 },
  { label: "9 Overs", value: 9 },
  { label: "10 Overs", value: 10 },
  { label: "11 Overs", value: 11 },
  { label: "12 Overs", value: 12 },
  { label: "13 Overs", value: 13 },
  { label: "14 Overs", value: 14 },
  { label: "15 Overs", value: 15 },
  { label: "20 Overs", value: 20 },
  { label: "50 Overs", value: 50 },
];

export const SPORTS = [
  {
    label: "BADMINTON MEN",
    value: "BADMINTONMEN",
  },
  {
    label: "BADMINTON WOMEN",
    value: "BADMINTONWOMEN",
  },
  {
    label: "TABLE TENNIS MEN",
    value: "TABLETENNISMEN",
  },
  {
    label: "TABLE TENNIS WOMEN",
    value: "TABLETENNISWOMEN",
  },
  {
    label: "VOLLEYBALL",
    value: "VOLLEYBALL",
  },
  {
    label: "THROWBALL",
    value: "THROWBALL",
  },
];

export const ISACTIVE = [
  {
    label: "True",
    value: 1,
  },
  {
    label: "False",
    value: 0,
  },
];

export const LINEUPSIZE = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
  { label: "6", value: 6 },
  { label: "7", value: 7 },
  { label: "8", value: 8 },
  { label: "9", value: 9 },
  { label: "10", value: 10 },
  { label: "11", value: 11 },
  { label: "12", value: 12 },
  { label: "13", value: 13 },
  { label: "14", value: 14 },
  { label: "15", value: 15 },
];

export const MAXOVERSPERBOWLER = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
  { label: "6", value: 6 },
  { label: "7", value: 7 },
  { label: "8", value: 8 },
  { label: "9", value: 9 },
  { label: "10", value: 10 },
  { label: "11", value: 11 },
  { label: "12", value: 12 },
  { label: "13", value: 13 },
  { label: "14", value: 14 },
  { label: "15", value: 15 },
];

export const SPORTSTYPE = [
  { label: "MENS SINGLE", value: "mens-single" },
  { label: "WOMENS SINGLE", value: "womens-single" },
  { label: "MENS DOUBLE", value: "mens-double" },
  { label: "WOMENS DOUBLE", value: "womens-double" },
  { label: "MIXED DOUBLE", value: "mixed-double" },
  { label: "MENS", value: "mens" },
  { label: "WOMENS", value: "womens" },
];

export const MATCHTYPENTPC = [
  { label: "LEAGUE", value: "league" },
  { label: "SEMI FINAL", value: "semi-final" },
  { label: "FINAL", value: "final" },
];

export const MATCHSTATUSNTPC = [
  { label: "SCHEDULED", value: "SCHEDULED" },
  { label: "ONGOING", value: "ONGOING" },
  { label: "COMPLETED", value: "RESULT" },
];
