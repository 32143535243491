import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";

interface DatePickerProps {
  inputKey: string;
  isRequired: boolean;
  label: string;
  presentValue: string; // Change to string for date
  placeholder: string;
  isEditable?: boolean;
  onInputChange: any;
}

const DatePicker = ({
  inputKey,
  isRequired,
  label,
  presentValue,
  placeholder,
  isEditable,
  onInputChange,
}: DatePickerProps) => {
  const [date, setDate] = useState(presentValue || "");

  // const getTodayDate = () => {
  //   const today = new Date();
  //   const year = today.getFullYear();
  //   const month = String(today.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
  //   const day = String(today.getDate()).padStart(2, "0");
  //   return `${year}-${month}-${day}`;
  // };

  useEffect(() => {
    setDate(presentValue || "");
  }, [presentValue]);

  const handleChange = (event: any) => {
    setDate(event.target.value);
    onInputChange(event.target.value); // Call the onInputChange function
  };

  return (
    <div className={styles["date-input-container"]}>
      <div className={styles["label"]}>
        <label>
          {label}
          {isRequired && <span style={{ color: "red" }}> *</span>}
        </label>
      </div>
      <input
        type="date"
        className={styles["date-input"]}
        value={date}
        onChange={handleChange}
        placeholder={placeholder}
        required={isRequired}
        disabled={!isEditable}
        // min={getTodayDate()}
        onClick={(e) => e.currentTarget.showPicker()}
      />
    </div>
  );
};

export default DatePicker;
