import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { Box, Grid } from "@mui/material";
import { toast } from "react-toastify";

import FieldMapper from "components/Common Components/Form Fields/FormUtils/FieldMapper";
import HeaderOptionsButton from "components/Common Components/Buttons/HeaderOptionsButton";
import { StyledStatusChip } from "components/Common Components/DataTable/DatatableConfig";
import ViewTournamentMenu from "./KebabMenu";

import {
  formatViewTournamentData,
  getTournamentDetailsById,
} from "services/touranments";
import { leftSideColConfig, rightSideColConfig } from "../formConfig";
import colors from "config/colorConfig";
import { logFirebaseEvent } from "config/firebase";
import styles from "../styles.module.css";
import BackdropLoader from "components/Common Components/Loaders/LoaderBackdrop";

function ViewTournamentForm() {
  const methods = useForm({
    criteriaMode: "firstError",
    mode: "onSubmit",
    shouldFocusError: true,
  });
  const { setValue } = methods;

  const { id } = useParams();
  const navigate = useNavigate();

  const [formData, setFormData] = useState<any>({});
  const [loaderState, setLoaderState] = useState(false)

  const initializePageData = async () => {
    setLoaderState(true)
    if (id) {
      const result = await getTournamentDetailsById(id);

      if (result && !result?.isError && result?.data.length) {
        const formattedData = formatViewTournamentData(result?.data[0]);
        setTournamentData(formattedData);
        setFormData(formattedData);
      } else {
        toast.error(result?.reason);
      }
    } else {
      toast.error("There was some error! Please try again");
    }
    setLoaderState(false)
  };

  const setTournamentData = (data: any) => {
    for (const [key, value] of Object.entries(data)) {
      value && setValue(key, value);
    }
    setValue("is_active", data?.is_active);
  };

  const handleViewMatchesClick = () => {
    logFirebaseEvent({
      event_name: "tournament_view_matches",
      tournament_id: id,
      tournament_status: formData?.status,
    });
    navigate("/matches/" + id, {
      state: {
        tournament_name: formData?.name,
        tournament_id: id,
        tournament_status: formData?.status,
      },
    });
  };

  useEffect(() => {
    initializePageData();
  }, []);

  const formConfig = {
    tName: {
      disabled: true,
    },
    startDate: {
      disabled: true,
    },
    endDate: {
      disabled: true,
    },
    format: {
      disabled: true,
    },
    isActive: {
      disabled: true,
    },
    type: {
      disabled: true,
    },
    ballType: {
      disabled: true,
    },
    playerOfSeries: {
      disabled: true,
      idParam: id,
    },
    uploadPhoto: {
      disabled: true,
    },
    displayFormat: {
      disabled: true,
    },
    hostCity: {
      disabled: true,
    },
    sport: {
      disabled: true,
    },
    winningTeamID: {
      disabled: true,
      idParam: id,
    },
    isSubstitutionAllowed: {
      disabled: true,
    },
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <div className={styles["form-heading"]}>
          <Box display="flex" alignItems="center">
            <span> Tournament Details</span> &nbsp;
            {formData && <span>{StyledStatusChip(formData?.status)}</span>}
          </Box>

          {id && <div className={styles["tournament-id"]}>ID - #{id}</div>}
        </div>

        <div className={styles["header-options"]}>
          <HeaderOptionsButton
            fontColor={colors.white}
            backgroundColor={colors.grayNinety}
            onClick={() => handleViewMatchesClick()}
          >
            View Matches
          </HeaderOptionsButton>
          <ViewTournamentMenu selectedTournamentData={formData} />
        </div>
      </Box>

      <div className={styles["form-container"]}>
        <FormProvider {...methods}>
          <form>
            <Grid container spacing={6}>
              <Grid item xs={12} md={6}>
                <FieldMapper
                  fieldsConfig={leftSideColConfig(formConfig, "view")}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FieldMapper
                  fieldsConfig={rightSideColConfig(formConfig, "view")}
                />
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </div>
      <BackdropLoader loaderState={loaderState} />
    </>
  );
}

export default ViewTournamentForm;
