import React from "react";

import SideDrawer from "components/Common Components/SideDrawer/SideDrawer";
import CreateScorerForm from "components/Scorer/CreateScorer/CreateScorerForm";

function CreateScorer() {
  return (
    <>
      <SideDrawer>
        <div style={{ padding: "3.2rem" }}>
          <CreateScorerForm />
        </div>
      </SideDrawer>
    </>
  );
}

export default CreateScorer;
