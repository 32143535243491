import React from "react";
import { Grid } from "@mui/material";

import FieldFactory from "./FieldFactory";

function FieldMapper({
  fieldsConfig,
  additionalConfigs,
}: {
  fieldsConfig: Array<any>;
  additionalConfigs?: any;
}) {
  return (
    <>
      {fieldsConfig.map((fields: any, key: number) => {
        if (fields.length === 1) {
          let properties = { ...fields[0], ...additionalConfigs };
          return (
            <Grid item key={key} xs={12} mt={3}>
              <FieldFactory fieldProps={properties} />
            </Grid>
          );
        } else {
          let len = fields.length;
          return (
            <Grid item container columnSpacing={2} key={key}>
              {fields.map((item: any, idx: number) => {
                let properties = { ...item, ...additionalConfigs };
                return (
                  <Grid item key={idx} xs={12} md={12 / len} mt={3}>
                    <FieldFactory fieldProps={properties} />
                  </Grid>
                );
              })}
            </Grid>
          );
        }
      })}
    </>
  );
}

export default FieldMapper;
