/* eslint-disable */
import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useFormContext, Controller } from "react-hook-form";
import { Box, ButtonBase } from "@mui/material";
import { styled } from "@mui/material/styles";
import { toast } from "react-toastify";

import { FileUploadButton } from "./FormUtils/FormStyles";
import { uploadImagetoS3 } from "services/touranments";

import styles from "./styles.module.css";
import fileIcon from "assets/FileArrowUp.svg";
import uploadIcon from "assets/upload-image.svg";

function FormInputFile(props: any) {
  const { name, label, rules, required, disabled, formType } = props;

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const [file, setFile] = useState<File | undefined>();
  const [preview, setPreview] = useState<any>(null);

  const { setValue, register, control } = useFormContext();

  const handleImageUpload = async (file: any) => {
    let result = await uploadImagetoS3(file);

    if (result && !result.isError) {
      setValue(name, result?.data?.image_url, {
        shouldDirty: true,
        shouldValidate: true,
      });
      toast.success("Successfully Uploaded!");
    } else if (result === undefined || result?.isError) {
      toast.error("There was some error uploading! Please try again.");
    }
  };

  const onDrop = useCallback(async (acceptedFiles: any) => {
    if (acceptedFiles[0].size > 2500000) {
      toast.error("File size can not be larger than 2MB!");
      return;
    }
    setFile(acceptedFiles[0]);

    const fileReader = new FileReader();

    fileReader.onload = function () {
      setPreview(fileReader.result);
    };

    fileReader.readAsDataURL(acceptedFiles[0]);
    await handleImageUpload(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/jpg": [],
      "image/webp": [],
    },
  });

  const handleOnChange = (e: React.FormEvent<HTMLInputElement>) => {
    const fileReader = new FileReader();

    const target = e.target as HTMLInputElement & {
      files: FileList;
    };
    if (target.files[0].size > 2500000) {
      toast.error("File size can not be larger than 2MB!");
      return;
    }

    fileReader.onload = function () {
      setPreview(fileReader.result);
    };

    fileReader.readAsDataURL(target.files[0]);
    setFile(target.files[0]);
    handleImageUpload(target.files[0]);
  };

  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue={null}
        rules={rules}
        render={({ fieldState: { error }, field: { value } }) => (
          <>
            <label className={styles["form-label"]}>
              {label}{" "}
              {required ? (
                <span className={styles["required-symbol"]}>*</span>
              ) : (
                <></>
              )}
            </label>
            <div className="container" style={{ width: "100%" }}>
              {!value ? (
                <div {...getRootProps({})} className={styles["drag-drop-area"]}>
                  <img src={uploadIcon} />

                  <input
                    {...register(name, {
                      ...rules,
                      onChange: (e) => handleOnChange(e),
                    })}
                    {...getInputProps()}
                    type="file"
                    accept="image/*"
                    data-testid={name}
                  />
                  {isDragActive ? (
                    <p>Drop the file here</p>
                  ) : (
                    <div>
                      <p>
                        Drop your files here or <b>browse</b>
                      </p>
                    </div>
                  )}
                </div>
              ) : (
                <></>
              )}

              {value ? (
                <Box display="flex" justifyContent="unset">
                  {preview ? (
                    <img src={preview} className={styles["image-preview"]} />
                  ) : (
                    <img src={value} className={styles["image-preview"]} />
                  )}
                  {!disabled ? (
                    <ButtonBase component="label" sx={FileUploadButton}>
                      Replace &nbsp; <img src={fileIcon} />
                      <VisuallyHiddenInput
                        type="file"
                        accept="image/*"
                        onChange={handleOnChange}
                        name={name}
                        disabled={disabled}
                      />
                    </ButtonBase>
                  ) : (
                    <></>
                  )}
                  <ButtonBase
                    component="label"
                    sx={FileUploadButton}
                    onClick={() => {
                      navigator?.clipboard?.writeText(value);
                      toast.success("Image URL Copied!");
                    }}
                  >
                    Copy URL
                  </ButtonBase>
                </Box>
              ) : (
                <></>
              )}
            </div>
            <div className={styles["error-msg"]}>
              {error ? error.message : null}
            </div>
          </>
        )}
      />
    </>
  );
}

export default FormInputFile;
