import { parseISO } from "date-fns";
import dayjs from "dayjs";

import { ENV, apiEndpoints, baseUrl } from "config/apiEndpoints";
import { GET, PATCH, POST, PUT } from "services";
import { HandleLogOut, getAccessToken } from "utils/Helper";
import { BULK_UPLOAD_CHUNK_SIZE, TOURNAMENT_PAGE_SIZE } from "utils/constants";

export const getTournamentsListPaginated = async (data: any) => {
  if (!Object.keys(data).length) {
    data = {
      page: 1,
      pageSize: TOURNAMENT_PAGE_SIZE,
    };
  }
  let result = await POST(
    baseUrl[ENV] + apiEndpoints.tournamentsPaginatedList,
    data
  );
  return result;
};

export const getTournamentDetailsById = async (tournamentId: string) => {
  const params = { tournamentId: tournamentId };
  let result = await GET(baseUrl[ENV] + apiEndpoints.getTournamentById, params);
  return result;
};

export const createTournament = async (data: any) => {
  data["start_date"] = formatDateForPost(data["start_date"]);
  data["end_date"] = formatDateForPost(data["end_date"]);
  data["is_active"] = parseInt(data["is_active"]);
  data["isSubstitutionAllowed"] = parseInt(data["isSubstitutionAllowed"]);

  data["status"] = "SCHEDULED";
  let result = await POST(baseUrl[ENV] + apiEndpoints.createTournament, data);
  return result;
};

export const editTournamentDetails = async (data: any) => {
  data["start_date"] = formatDateForPost(data["start_date"]);
  data["end_date"] = formatDateForPost(data["end_date"]);
  data["is_active"] = parseInt(data["is_active"]);
  data["isSubstitutionAllowed"] = parseInt(data["isSubstitutionAllowed"]);
  data["player_of_series"] = data["player_of_series"]?.id;
  data["winning_team_id"] = data["winning_team_id"]?.id;
  let result = await PUT(baseUrl[ENV] + apiEndpoints.createTournament, data);
  return result;
};

export const getPlayerListByTournament = async (tournamentId: string) => {
  const params = { tournamentId: tournamentId };
  let result = await GET(
    baseUrl[ENV] + apiEndpoints.getPlayersByTournament,
    params
  );
  return result;
};

export const updateTournamentStatus = async (data: any) => {
  let result = await PATCH(
    baseUrl[ENV] + apiEndpoints.updateTournamentStatus,
    data
  );
  return result;
};

export const cancelTournament = async (tournamentId: string) => {
  let data = { tournamentId: tournamentId };
  let result = await PATCH(baseUrl[ENV] + apiEndpoints.cancelTournament, data);
  return result;
};

export const uploadImagetoS3 = async (file: any) => {
  var headers = new Headers();
  headers.append("accept", "*/*");
  headers.append("authorization", `Bearer ${getAccessToken()}`);

  var formdata = new FormData();
  formdata.append("doc_type", "IMAGE");
  formdata.append("file", file);

  let response: any;

  try {
    response = await fetch(baseUrl[ENV] + apiEndpoints.uploadAsset, {
      method: "POST",
      headers: headers,
      body: formdata,
      redirect: "follow",
    });
  } catch (error) {
    console.log(error);
    return;
  }
  if (response?.status === 401) {
    HandleLogOut();
    return;
  }
  const resultText = await response?.text();
  const result = resultText === "" ? {} : JSON.parse(resultText);
  return result;
};

export const formatViewTournamentData = (data: any) => {
  let finalData = data;
  for (const [key, value] of Object.entries(data)) {
    if (key === "start_date" || key === "end_date") {
      if (typeof value === "string") {
        finalData[key] = parseISO(value);
      }
    }
    if (key === "player_of_series") {
      finalData["player_of_series"] = {
        id: finalData["playerDetails"]?.player_id,
        label: finalData["playerDetails"]?.name,
        shortName: finalData["playerDetails"]?.short_name,
        image: finalData["playerDetails"]?.image_url,
      };
    }
    if (key === "winning_team_id") {
      finalData["winning_team_id"] = {
        id: finalData["winningTeamDetails"]?.team_id,
        label: finalData["winningTeamDetails"]?.name,
        location: finalData["winningTeamDetails"]?.team_city,
        image: finalData["winningTeamDetails"]?.image_url,
      };
    }
  }
  delete finalData?.createdAt;
  delete finalData?.updatedAt;
  delete finalData?.playerDetails;
  delete finalData?.winningTeamDetails;
  delete finalData?.id;
  return finalData;
};

// export const formatEditTournamentData = (data: any) => {
//   data["start_date"] = formatDateForPost(data["start_date"])
//   data["end_date"] = formatDateForPost( data["end_date"])

//   delete data?.description;
//   delete data?.location;

//   return data;
// };

const formatDateForPost = (data: any) => {
  return dayjs(data).format("YYYY-MM-DD") + " 00:00:00";
};


export const getPlayerListMapped = async (tournamentId: string) => {
  let result = await getPlayerListByTournament(tournamentId)

  if(result && !result?.isError){
    return result?.data?.map((player: any) => {
      return {
        id: player?.player_id,
        label: player?.player_name,
        shortName: player?.short_player_name,
        image: player?.image_url,
      };
    });
  }
}

export const createBulkTournaments = async (data: any) => {
  const chunks = [];
  for (let i = 0; i < data.length; i += BULK_UPLOAD_CHUNK_SIZE) {
    chunks.push(data.slice(i, i + BULK_UPLOAD_CHUNK_SIZE));
  }

  for (const chunk of chunks) {
    try {
      await POST(baseUrl[ENV] + apiEndpoints.createBulkTournaments, chunk);
    } catch (err) {
      console.error("Error sending chunk:", err);
      throw err;
    }
  }
};

// export const getPlayerListMapped = async (tournamentId: string) => {
//   let result = await getPlayerListByTournament(tournamentId);

//   if (result && !result?.isError) {
//     return result?.data?.map((player: any) => {
//       return {
//         id: player?.player_id,
//         label: player?.player_name,
//         shortName: player?.short_player_name,
//         image: player?.image_url,
//       };
//     });
//   }
// };

export const getTournamentTeams = async (tournamentId: string) => {
  const params = { tournamentId: tournamentId };
  let result = await GET(
    baseUrl[ENV] + apiEndpoints.getTournamentTeams,
    params
  );
  if (result && !result?.isError) {
    return result?.data?.teams?.map((team: any) => {
      return {
        id: team?.teamId,
        label: team?.name,
        // location: team?.team_city,
        image: team?.imgUrl,
      };
    });
  }
  return [];
};
