/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { Box, Grid } from "@mui/material";
import { toast } from "react-toastify";

import FieldMapper from "components/Common Components/Form Fields/FormUtils/FieldMapper";
import FormButton from "components/Common Components/Buttons/FormButton";
import HeaderOptionsButton from "components/Common Components/Buttons/HeaderOptionsButton";
import EntityDropDown from "components/Common Components/Dropdowns/EntityDropdown";
import { StyledStatusChip } from "components/Common Components/DataTable/DatatableConfig";
import ViewMatchMenu from "./KebabMenu";

import { leftSideColConfig, rightSideColConfig } from "../formConfig";
import {
  getScorersListMapped,
  formatGetNtpcMatchDetailsData,
  formatPostNtpcMatchDetailsData,
} from "../helper";
import { logFirebaseEvent } from "config/firebase";
import colors from "config/colorConfig";
import { STATUS_LIST } from "utils/constants";
import {
  getMatchDetailsById,
  getNtpcMatchDetailsById,
  editNtpcMatch,
} from "services/matches";
import styles from "../styles.module.css";
import editIcon from "assets/editIcon.svg";
import BackdropLoader from "components/Common Components/Loaders/LoaderBackdrop";

function ViewMatchForm() {
  const methods = useForm({
    criteriaMode: "firstError",
    mode: "onSubmit",
    shouldFocusError: true,
  });

  const {
    handleSubmit,
    setValue,
    watch,
    setError,
    getValues,
    formState: { isDirty },
  } = methods;

  const { matchId } = useParams();
  const navigate = useNavigate();

  const [formData, setFormData] = useState<any>({});
  const [loaderState, setLoaderState] = useState(false);

  const formSubmitHandler = async (data: any) => {
    setLoaderState(true);
    const formattedData = formatPostNtpcMatchDetailsData(data);

    let result = await editNtpcMatch(formattedData);
    if (result && !result?.isError) {
      navigate(-1);
      toast.success("Successful!");
    } else {
      toast.error(result?.reason);
    }
    setLoaderState(false);
  };

  const initializePageData = async () => {
    setLoaderState(true);
    if (matchId) {
      const result = await getNtpcMatchDetailsById(matchId);

      if (result && !result?.isError && result?.data) {
        const matchData = result?.data?.match;
        const formattedData = formatGetNtpcMatchDetailsData(matchData);
        setMatchData(formattedData);
        setFormData(formattedData);
      } else {
        toast.error(result?.reason);
      }
    } else {
      toast.error("There was some error! Please try again");
    }
    setLoaderState(false);
  };

  const setMatchData = (data: any) => {
    for (const [key, value] of Object.entries(data)) {
      value && setValue(key, value);
    }
    setValue("is_active", data?.is_active);
  };

  useEffect(() => {
    initializePageData();
  }, []);

  const formConfig = {};

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <div className={styles["form-heading"]}>
          <Box display="flex" alignItems="center">
            <span> Match Details</span> &nbsp;
            {formData && <span>{StyledStatusChip(formData?.status)}</span>}
          </Box>

          <div className={styles["match-id"]}>NTPC</div>
          {matchId && <div className={styles["match-id"]}>ID - #{matchId}</div>}
        </div>

        <div className={styles["header-options"]}>
          <HeaderOptionsButton
            fontColor={colors.grayNinety}
            backgroundColor={colors.white}
            borderColor={colors.grayThirty}
            disabled={
              formData?.status === STATUS_LIST.CANCELLED ||
              formData?.staus === STATUS_LIST.ABONDENED ||
              formData?.status === STATUS_LIST.COMPLETED ||
              formData?.status === STATUS_LIST.RESULT
            }
            onClick={() => {
              navigate("/match/edit/" + matchId, {
                state: {
                  matchData: formData,
                },
              });
            }}
          >
            Edit Match &nbsp;
            <img
              src={editIcon}
              alt="edit-icon"
              style={{ verticalAlign: "text-bottom" }}
            />
          </HeaderOptionsButton>
          <ViewMatchMenu selectedMatchData={formData} />
        </div>
      </Box>

      <div className={styles["form-container"]}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(formSubmitHandler)}>
            <Grid container spacing={6}>
              <Grid item xs={12} md={6}>
                {formData?.team_a ? (
                  <Grid item xs={12} mt={3}>
                    <label className={styles["form-label"]}>
                      Team A<span className={styles["required-symbol"]}>*</span>
                    </label>
                    <Box display="flex" mt={1.5}>
                      <img
                        src={formData?.team_a?.image}
                        loading="lazy"
                        className={styles["team-logo"]}
                        alt="team-logo"
                      />
                      &nbsp;
                      <Link
                        to={`/team/view/` + formData?.team_a?.id}
                        className={styles["team-name"]}
                      >
                        {formData?.team_a?.label}
                      </Link>
                    </Box>
                  </Grid>
                ) : (
                  <></>
                )}

                {formData?.team_b ? (
                  <Grid item xs={12} mt={3}>
                    <label className={styles["form-label"]}>
                      Team B<span className={styles["required-symbol"]}>*</span>
                    </label>
                    <Box display="flex" mt={1.5}>
                      <img
                        src={formData?.team_b?.image}
                        loading="lazy"
                        className={styles["team-logo"]}
                        alt="team-logo"
                      />
                      &nbsp;
                      <Link
                        to={`/team/view/` + formData?.team_b?.id}
                        className={styles["team-name"]}
                      >
                        {formData?.team_b?.label}
                      </Link>
                    </Box>
                  </Grid>
                ) : (
                  <></>
                )}

                <FieldMapper
                  fieldsConfig={leftSideColConfig(formConfig, "view")}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FieldMapper
                  fieldsConfig={rightSideColConfig(formConfig, "view")}
                />
              </Grid>
            </Grid>
            {/* <Divider sx={{ my: 3 }} /> */}
            <Grid container item columnGap={3}>
              <FormButton
                type="button"
                fontColor={colors.redSixty}
                backgroundColor={colors.white}
                borderColor={colors.grayThirty}
                onClick={() => navigate(-1)}
              >
                Discard
              </FormButton>

              <FormButton
                type="submit"
                fontColor={colors.white}
                backgroundColor={colors.grayNinety}
                disabledColor={colors.grayFifty}
                disabledBackground={colors.grayTwenty}
                disabled={!isDirty}
              >
                Save changes
              </FormButton>
            </Grid>
          </form>
        </FormProvider>
      </div>
      <BackdropLoader loaderState={loaderState} />
    </>
  );
}

export default ViewMatchForm;
