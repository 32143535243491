import React from "react";

import { FieldType } from "../FieldAttributes";
import { FormInputText } from "../FormTextField";
import { FormInputDate } from "../FormInputDate";
import { FormInputRadio } from "../FormInputRadio";
import { FormInputDropdown } from "../FormInputDropDown";
import FormInputFile from "../FormInputFile";
import { FormSearchDropdown } from "../FormDropdownSearch";
import { FormDropdownSearchLive } from "../FormDropdownSearchLive";
import { FormInputDateTime } from "../FormInputDateTime";
import { FormMultiselectLive } from "../FormMultiselectLive";
import { FormColorPicker } from "../FormColorPicker";

function FieldFactory(props: any) {
  const { fieldProps } = props;

  if (fieldProps.type === FieldType.TEXT)
    return <FormInputText {...fieldProps} />;
  else if (fieldProps.type === FieldType.DATE)
    return <FormInputDate {...fieldProps} />;
  else if (fieldProps.type === FieldType.RADIO)
    return <FormInputRadio {...fieldProps} />;
  else if (fieldProps.type === FieldType.SELECT)
    return <FormInputDropdown {...fieldProps} />;
  else if (fieldProps.type === FieldType.FILE)
    return <FormInputFile {...fieldProps} />;
  else if (fieldProps.type === FieldType.AUTOCOMPLETE)
    return <FormSearchDropdown {...fieldProps} />;
  else if (fieldProps.type === FieldType.AUTOCOMPLETELIVE)
    return <FormDropdownSearchLive {...fieldProps} />;
  else if (fieldProps.type === FieldType.MULTISELECTLIVE)
    return <FormMultiselectLive {...fieldProps} />;
  else if (fieldProps.type === FieldType.TIME)
    return <FormInputDateTime {...fieldProps} />;
  else if (fieldProps.type === FieldType.NUMBER)
    return <FormInputText {...fieldProps} />;
  else if (fieldProps.type === FieldType.COLOR)
    return <FormColorPicker {...fieldProps} />;

  return <></>;
}

export default FieldFactory;
