/*eslint-disable*/
import { debounce } from "@mui/material";
import { useEffect, useState } from "react";

const useResponsive = () => {
  const [state, setState] = useState(true);
  const [width, setWidth] = useState(window.innerWidth);

  const onResizeHandler = () => {
    setWidth(window.innerWidth);
    if (window.innerWidth >= 990) {
      setState(true);
    } else setState(false);
  };

  const debouncedCall = debounce(onResizeHandler, 400);

  useEffect(() => {
    onResizeHandler();

    window.addEventListener("resize", debouncedCall, false);

    return () => {
      window.removeEventListener("resize", debouncedCall, false);
    };
  }, []);

  return state;
};

export default useResponsive;
