/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { Box, Grid, Divider, Typography } from "@mui/material";
import { toast } from "react-toastify";

import FieldMapper from "components/Common Components/Form Fields/FormUtils/FieldMapper";
import HeaderOptionsButton from "components/Common Components/Buttons/HeaderOptionsButton";

import {
  leftSideColConfig,
  rightSideColConfig,
  sportsSectionConfig,
  cricketColConfig,
  badmintonColConfig,
  footballColConfig,
} from "../formConfig";
import colors from "config/colorConfig";
import { formatGetGroundData, getGroundDetailsById } from "services/grounds";
import styles from "../styles.module.css";
import BackdropLoader from "components/Common Components/Loaders/LoaderBackdrop";

function ViewGroundForm() {
  const methods = useForm({
    criteriaMode: "firstError",
    mode: "onBlur",
    shouldFocusError: true,
  });

  const { setValue, watch } = methods;

  const watchSports = watch("sports");
  const listSportAvailable = watchSports?.map((sport: any) => sport?.value);

  const navigate = useNavigate();
  const { groundId } = useParams();

  const [formData, setFormData] = useState<any>();
  const [loaderState, setLoaderState] = useState(false);

  const initializePageData = async () => {
    setLoaderState(true);
    if (groundId) {
      let result = await getGroundDetailsById(groundId);
      if (result && !result?.isError) {
        let formattedData = formatGetGroundData(result?.data);
        setGroundData(formattedData);
        setFormData(formattedData);
      } else {
        toast.error(result?.reason);
      }
    } else {
      toast.error("There was some error! Please try again");
    }
    setLoaderState(false);
  };

  const setGroundData = (data: any) => {
    for (const [key, value] of Object.entries(data)) {
      value && setValue(key, value);
    }
    setValue("city_id", data?.city);
    setValue("isActive", data?.isActive);
  };

  useEffect(() => {
    initializePageData();
  }, []);

  const formConfig = {
    amenities: {
      disabled: true,
    },
    uploadPhoto: {
      disabled: true,
    },
    groundName: {
      disabled: true,
    },
    groundAddress: {
      disabled: true,
    },
    googleMapsLink: {
      disabled: true,
    },
    groundOwner: {
      disabled: true,
    },
    groundContact: {
      disabled: true,
    },
    groundSports: {
      disabled: true,
    },
    cricketPitchType: {
      disabled: true,
    },
    footballPitchType: {
      disabled: true,
    },
    badmintonCourtType: {
      disabled: true,
    },
    groundImages: {
      disabled: true,
    },
    isActive: {
      disabled: true,
    },
    state: {
      disabled: true,
    },
    city: {
      disabled: true,
    },
    sports: {
      disabled: true,
    },
  };

  const cricketFormConfig = {
    sportsCricket: {
      disabled: true,
    },
  };

  const badmintonFormConfig = {
    sportsBadminton: {
      disabled: true,
    },
  };

  const footballFormConfig = {
    sportsFootball: {
      disabled: true,
    },
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <div className={styles["form-heading"]}>
          <Box display="flex" alignItems="center">
            <span> View Ground</span>
          </Box>
          {groundId && (
            <div className={styles["scorer-id"]}>ID - #{groundId}</div>
          )}
        </div>

        <div className={styles["header-options"]}>
          <HeaderOptionsButton
            fontColor={colors.grayNinety}
            backgroundColor={colors.white}
            borderColor={colors.grayThirty}
            onClick={() => {
              navigate("/ground/edit/" + groundId, {
                state: { groundData: formData },
              });
            }}
          >
            Edit details
          </HeaderOptionsButton>
        </div>
      </Box>

      <div className={styles["form-container"]}>
        <FormProvider {...methods}>
          <form>
            <Grid container columnSpacing={6}>
              <Grid item xs={12} md={6}>
                <FieldMapper
                  fieldsConfig={leftSideColConfig(formConfig, "view")}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FieldMapper
                  fieldsConfig={rightSideColConfig(formConfig, "view")}
                />
              </Grid>
            </Grid>

            <Divider sx={{ mt: 2, mb: 0 }} />

            <Grid container columnSpacing={6}>
              <Grid item xs={12} md={8}>
                <FieldMapper
                  fieldsConfig={sportsSectionConfig(formConfig, "create")}
                />
              </Grid>
              {listSportAvailable && listSportAvailable.includes("CRICKET") && (
                <Grid item xs={12} md={8}>
                  <Typography sx={{ mt: 1, fontSize: 16, color: "blue" }}>
                    1. CRICKET
                  </Typography>
                  <FieldMapper
                    fieldsConfig={cricketColConfig(cricketFormConfig, "create")}
                  />
                </Grid>
              )}
              {listSportAvailable &&
                listSportAvailable.includes("FOOTBALL") && (
                  <Grid item xs={12} md={8}>
                    <Typography sx={{ mt: 2, fontSize: 16, color: "blue" }}>
                      2. FOOTBALL
                    </Typography>
                    <FieldMapper
                      fieldsConfig={footballColConfig(
                        footballFormConfig,
                        "create"
                      )}
                    />
                  </Grid>
                )}
              {listSportAvailable &&
                listSportAvailable.includes("BADMINTON") && (
                  <Grid item xs={12} md={8}>
                    <Typography sx={{ mt: 2, fontSize: 16, color: "blue" }}>
                      3. BADMINTON
                    </Typography>
                    <FieldMapper
                      fieldsConfig={badmintonColConfig(
                        badmintonFormConfig,
                        "create"
                      )}
                    />
                  </Grid>
                )}
            </Grid>
          </form>
        </FormProvider>
      </div>
      <BackdropLoader loaderState={loaderState} />
    </>
  );
}

export default ViewGroundForm;
