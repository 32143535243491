import React from "react";

import SideDrawer from "components/Common Components/SideDrawer/SideDrawer";
import CreateMatchForm from "components/Ntpc/CreateMatch/CreateMatchForm";

function CreateMatch() {
  return (
    <>
      <SideDrawer>
        <div style={{ padding: "3.2rem" }}>
          <CreateMatchForm />
        </div>
      </SideDrawer>
    </>
  );
}

export default CreateMatch;
