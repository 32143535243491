import React from "react";
import { useNavigate } from "react-router";
import { Box } from "@mui/material";

import ErrorImage from "assets/donutError.svg";
import HeaderOptionsButton from "components/Common Components/Buttons/HeaderOptionsButton";
import colors from "config/colorConfig";

function ErrorCommon() {
    const navigate = useNavigate()
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight="100%"
      >
        <img src={ErrorImage} alt="error" width="50%" />
        <br />
        <br />

        <div style={{ fontSize: "4rem" }}>An Unexpected Error Occured!</div>
        <br />
        <HeaderOptionsButton
              fontColor={colors.grayNinety}
              backgroundColor={colors.white}
              borderColor={colors.grayForty}
              onClick={() => navigate(-1)}
            >
              Return to Previous Page
            </HeaderOptionsButton>
      </Box>
    </>
  );
}

export default ErrorCommon;
