/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  Autocomplete,
  Box,
  FormControl,
  TextField,
  autocompleteClasses,
} from "@mui/material";
import { debounce } from "@mui/material/utils";

import {
  SearchDropdownStyles,
  SelectFieldStyles,
} from "./FormUtils/FormStyles";

import styles from "./styles.module.css";
import Icon from "assets/Search.svg";

const IconPop = () => {
  return <img src={Icon} style={{ marginTop: "0.3rem" }} />;
};

export const FormDropdownSearchLive = (props: any) => {
  const {
    name,
    label,
    rules,
    placeholder,
    disabled,
    required,
    optionsFetcher,
    idParam,
    OptionsDisplayComp,
  } = props;

  const FieldStyles = {
    ...SelectFieldStyles,
    ...{
      [`& .${autocompleteClasses.popupIndicator}`]: {
        transform: "none",
      },
    },
  };

  const { control} = useFormContext();
  const [data, setData] = useState<any>([]);
  const [inputVal, setInputVal] = useState("");

  const fetchOptions = React.useMemo(
    () =>
      debounce(async (input) => {
        if (optionsFetcher) {
          if(idParam){
            let result = await optionsFetcher(input, idParam);
            if (result) setData(result);
          }else{
            let result = await optionsFetcher(input);
            if (result) setData(result);
          }
        } else setData([]);
      }, 400),
    [idParam]
  );
  useEffect(() => {
    let active = true;

    if (inputVal.length >= 3) {
      fetchOptions(inputVal);
    }

    return () => {
      active = false;
    };
  }, [inputVal, fetchOptions]);

  return (
    <>
      {data ? (
        <FormControl size="small" fullWidth>
          <label className={styles["form-label"]}>
            {label}{" "}
            {required ? (
              <span className={styles["required-symbol"]}>*</span>
            ) : (
              <></>
            )}
          </label>
          <Controller
            control={control}
            name={name}
            rules={rules}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <>
                <Autocomplete
                  //   disableClearable
                  filterOptions={(x) => x}
                  includeInputInList
                  filterSelectedOptions
                  popupIcon={<IconPop />}
                  onChange={(_event, item) => onChange(item)}
                  getOptionLabel={(option: any) =>
                    option?.label || value?.label || null
                  }
                  isOptionEqualToValue={(option, value) =>
                    option?.id === value?.id
                  }
                  value={value !== undefined ? value : null}
                  onInputChange={(event, newInputValue) => {
                    setInputVal(newInputValue);
                  }}
                  sx={FieldStyles}
                  disablePortal
                  options={data ? data : []}
                  disabled={disabled}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={placeholder}
                      sx={SearchDropdownStyles}
                      size="small"
                      error={!!error}
                    />
                  )}
                  renderOption={(props, option: any) =>
                    OptionsDisplayComp ? (
                      <OptionsDisplayComp data={option} {...props} key={option?.id}/>
                    ) : (
                      <Box
                        component="li"
                        display="flex"
                        sx={{
                          fontSize: "1.4rem",
                        }}
                        {...props}
                      >
                        <div>{option?.label}</div>
                      </Box>
                    )
                  }
                />

                <div className={styles["error-msg"]}>
                  {error ? error.message : null}
                </div>
              </>
            )}
          />
        </FormControl>
      ) : (
        <> </>
      )}
    </>
  );
};
