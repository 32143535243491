import React from "react";
import { Outlet, Navigate } from "react-router-dom";

import useAuthHandlers from "../hooks/useAuthHandlers";

const PrivateRoutes = (): React.JSX.Element => {
  const authHook = useAuthHandlers();

  const isLoggedIn = authHook.checkIsLoggedIn();

  return isLoggedIn ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoutes;
