import React from "react";
import styles from "./styles.module.scss";
import { StyledStatusChip } from "components/Common Components/DataTable/DatatableConfig";
import { useNavigate } from "react-router-dom";

interface FormHeaderProps {
  formType: string;
  tournamentId?: string;
  status?: string;
}

const FormHeader = ({ formType, tournamentId, status }: FormHeaderProps) => {
  const navigate = useNavigate();

  const handleEditTournamentClick = () => {
    window.location.href = `/tournament-new/edit/${tournamentId}`;
  };

  const handleViewMatchesClick = () => {
    navigate("/matches/" + tournamentId);
  };
  return (
    <>
      {formType === "view" && (
        <div className={styles["header"]}>
          <div className={styles["form-heading"]}>
            <div>Tournament Details</div>
            <span>{StyledStatusChip(status || "")}</span>
          </div>
          <div className={styles["tournament-actions"]}>
            <button
              className={styles["view-matches"]}
              onClick={() => {
                handleViewMatchesClick();
              }}
            >
              View Matches
            </button>
            <button
              className={styles["edit-tournament"]}
              onClick={handleEditTournamentClick}
            >
              Edit Details
            </button>
          </div>
        </div>
      )}

      {formType === "edit" && (
        <div className={styles["form-heading"]}>
          <div>Edit Tournament</div>
          <span>{StyledStatusChip(status || "")}</span>
        </div>
      )}

      {formType === "create" && (
        <div className={styles["form-heading"]}>
          <div className={styles["form-heading"]}>
            <div>Create Tournament</div>
          </div>
        </div>
      )}
    </>
  );
};

export default FormHeader;
