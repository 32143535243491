import { FieldType } from "components/Common Components/Form Fields/FieldAttributes";
import {
  MATCHTYPE,
  NUMBEROFOVERS,
  ISACTIVE,
  SPORTS,
  LINEUPSIZE,
  MAXOVERSPERBOWLER,
  MATCHCATEGORY,
} from "./MatchConstants";

export const leftSideColConfig = (formConfig: any, formType?: string) => {
  const config = [
    [
      {
        type: formType !== "view" ? FieldType.AUTOCOMPLETELIVE : "",
        label: "Team A name",
        name: "team_a",
        placeholder: "Search Team A name",
        disabled: formConfig?.teamAName?.disabled,
        optionsFetcher: formConfig?.teamAName?.optionsFetcher,
        OptionsDisplayComp: formConfig?.teamAName?.OptionsDisplayComp,
        idParam: formConfig?.teamAName?.idParam,
        required: true,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: formType !== "view" ? FieldType.AUTOCOMPLETELIVE : "",
        label: "Team B name",
        name: "team_b",
        placeholder: "Search Team B name",
        disabled: formConfig?.teamBName?.disabled,
        optionsFetcher: formConfig?.teamBName?.optionsFetcher,
        OptionsDisplayComp: formConfig?.teamBName?.OptionsDisplayComp,
        idParam: formConfig?.teamBName?.idParam,
        required: true,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.RADIO,
        label: "isActive",
        name: "is_active",
        disabled: formConfig.isActive.disabled,
        required: true,
        options: ISACTIVE,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.RADIO,
        label: "Sport",
        name: "match_sport",
        disabled: formConfig.sport.disabled,
        required: true,
        options: SPORTS,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.AUTOCOMPLETE,
        label: "Scorer",
        name: "scorer_id",
        placeholder: "Enter scorer name",
        disabled: formConfig.scorer.disabled,
        optionsFetcher: formConfig?.scorer?.optionsFetcher,
        OptionsDisplayComp: formConfig?.teamBName?.OptionsDisplayComp,
        required: true,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.SELECT,
        label: "Match Type",
        name: "match_type",
        placeholder: "Enter match type",
        disabled: formConfig.matchType.disabled,
        required: true,
        options: MATCHTYPE,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
  ];
  return config;
};

export const rightSideColConfig = (formConfig: any, formName?: string) => {
  const config = [
    [
      {
        type: FieldType.TIME,
        label: "Start Time",
        name: "start_time",
        placeholder: "DD/MM/YYYY HH:mm:ss",
        disabled: formConfig.startTime.disabled,
        required: true,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.SELECT,
        label: "Overs",
        name: "no_of_overs",
        placeholder: "Select Overs",
        disabled: formConfig.overs.disabled,
        required: false,
        // formConfig?.overs?.required !== undefined
        //   ? formConfig?.overs?.required
        //   : true,
        options: NUMBEROFOVERS,
        rules: {
          required: {
            value: false,
            // formConfig?.overs?.required !== undefined
            //   ? formConfig?.overs?.required
            //   : true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.TEXT,
        label: "Venue",
        name: "stadium",
        placeholder: "Enter Venue",
        disabled: formConfig.venue.disabled,
        required: false,
        rules: {
          required: {
            value: false,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.TEXT,
        label: "URL Link",
        name: "link",
        placeholder: "Enter URL",
        disabled: formConfig.urlLink.disabled,
        required: false,
        rules: {},
      },
    ],
    [
      {
        type: FieldType.SELECT,
        label: "Line Up Size",
        name: "lineup_size",
        placeholder: "Enter Line up Size",
        disabled: formConfig.lineUpSize.disabled,
        options: LINEUPSIZE,
        required: true,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.SELECT,
        label: "Max overs per bowler",
        name: "max_overs_per_bowler",
        placeholder: "Select max overs per bowler",
        disabled: formConfig.oversPerBowler.disabled,
        required: false,
        options: MAXOVERSPERBOWLER,
        rules: {
          required: {
            value: false,
            message: "This field is mandatory.",
          },
        },
      },
    ],
  ];
  return config;
};

export const badmintonConfig = (formConfig: any, formName?: string) => {
  const config = [
    [
      {
        type: FieldType.SELECT,
        label: "Match Category",
        name: "match_category",
        placeholder: "Enter Match Category",
        disabled: formConfig.matchCategory.disabled,
        required: false,
        options: MATCHCATEGORY,
        rules: {
          required: {
            value: false,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.TEXT,
        label: "Match Name",
        name: "match_name",
        placeholder: "Enter Match Name",
        disabled: formConfig.matchName.disabled,
        required: false,
        rules: {
          required: {
            value: false,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.TEXT,
        label: "Match Number",
        name: "match_number",
        placeholder: "Enter Match Number",
        disabled: formConfig.matchNumber.disabled,
        required: false,
        rules: {
          required: {
            value: false,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.TEXT,
        label: "Match Sequence Number",
        name: "sequence_number",
        placeholder: "Enter Match Sequence Number",
        disabled: formConfig.sequenceNumber.disabled,
        required: false,
        rules: {
          required: {
            value: false,
            message: "This field is mandatory.",
          },
        },
      },
    ],
  ];

  return config;
};
export const indoorCricketConfig = (formConfig: any, formName?: string) => {
  const config = [
    [
      {
        type: FieldType.TEXT,
        label: "Balls per Over",
        name: "balls_per_over",
        placeholder: "Enter number of balls per over",
        disabled: formConfig.ballsPerOver.disabled,
        required: true,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
          pattern: {
            value: /^\d+$/,
            message: "Please enter a valid number.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.TEXT,
        label: "Overs per Skin",
        name: "overs_per_skin",
        placeholder: "Enter number of overs per skin",
        disabled: formConfig.oversPerSkin.disabled,
        required: true,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
          pattern: {
            value: /^\d+$/,
            message: "Please enter a valid number.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.TEXT,
        label: "Skins per Inning",
        name: "skins_per_inning",
        placeholder: "Enter number of skins per inning",
        disabled: formConfig.skinsPerInning.disabled,
        required: true,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
          pattern: {
            value: /^\d+$/,
            message: "Please enter a valid number.",
          },
        },
      },
    ],
  ];

  return config;
};

