import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { toast } from "react-toastify";

import HeaderOptionsButton from "components/Common Components/Buttons/HeaderOptionsButton";
import DataTable from "components/Common Components/DataTable/Index";
import EmptyList from "components/Common Components/EmptyList";

import { getScorerListPaginated } from "services/scorers";
import { ScorerListConfig } from "./listConfig";
import { logFirebaseEvent } from "config/firebase";
import colors from "config/colorConfig";
import { SCORER_PAGE_SIZE } from "utils/constants";
import styles from "./styles.module.css";
import BackdropLoader from "components/Common Components/Loaders/LoaderBackdrop";

function ScorerList() {
  const navigate = useNavigate();

  const [dataList, setDataList] = useState([]);
  const [totalRows, setTotalRows] = useState(10);
  const [emptyListFlag, setEmptyListFlag] = useState(false);
  const [loaderState, setLoaderState] = useState(false)

  const getScorerList = async (reqData: any) => {
    setLoaderState(true)
    let result = await getScorerListPaginated(reqData);
    if (result && !result?.isError) {
      const scorerList = result?.data?.list;
      if (!scorerList?.length) {
        setLoaderState(false)
        setEmptyListFlag(true);
        return;
      }
      setDataList(scorerList);
      setTotalRows(result?.data?.pagination?.totalRows);
    } else {
      toast.error(result?.reason);
    }
    setLoaderState(false)
  };

  const handleRowClick = (scorerData: any) => {
    const scorerId = scorerData?.row?.scorerId;
    logFirebaseEvent({
      event_name: "scorer_view",
      scorer_id: scorerId,
    });
    navigate(`/scorer/view/${scorerId}`);
  };

  const handleSortingAndPagination = (
    page: number,
    pageSize: number,
    sortKey: string,
    sortType: string
  ) => {
    const reqData: any = {};
    const sortOptions: any = {};

    if (sortKey) {
      sortOptions.key = sortKey;
      sortOptions.type = sortType.toUpperCase();

      reqData.sortby = sortOptions;
    }
    reqData.page = page;
    reqData.pageSize = pageSize;
    getScorerList(reqData);
  };

  useEffect(() => {
    logFirebaseEvent({ event_name: "scorer_home" });
    getScorerList({});
  }, []);

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <div className={styles["form-heading"]}>Scorers &nbsp;</div>
        <div>
          <HeaderOptionsButton
            fontColor={colors.white}
            backgroundColor={colors.grayNinety}
            onClick={() => {
              logFirebaseEvent({ event_name: "scorer_add" });
              navigate("/scorer/create");
            }}
          >
            Add Scorer +
          </HeaderOptionsButton>
        </div>
      </Box>
      {dataList?.length ? (
        <DataTable
          columns={ScorerListConfig}
          data={dataList}
          totalRows={totalRows}
          pageSize={SCORER_PAGE_SIZE}
          onRowClick={handleRowClick}
          sortPaginationHandler={handleSortingAndPagination}
        />
      ) : (
        <></>
      )}
      {emptyListFlag ? <EmptyList message="No Scorer Created!" /> : <></>}
      <BackdropLoader loaderState={loaderState} />
    </>
  );
}

export default ScorerList;
