import React from "react";

import SideDrawer from "components/Common Components/SideDrawer/SideDrawer";
import MatchList from "components/Ntpc/Index/MatchList";

function MatchIndex() {
  return (
    <>
      <SideDrawer>
        <div style={{ padding: "3.2rem" }}>
          <MatchList />
        </div>
      </SideDrawer>
    </>
  );
}

export default MatchIndex;
