import React from "react";

import styles from "./styles.module.css";
import locale from "../../locale/locale.json";

function OtpExhausted() {
  const content = locale["en"];
  return (
    <div className={styles["form-wrapper-otp-failed"]}>
      <div className={styles["form-content"]}>
        <div
          className={styles["verification-failed-msg"]}
          data-test-id="otp-failed"
        >
          {content.OTP_EXHAUSTED_HEADING}
        </div>
        <div className={styles["contact-admin-msg"]}>
          <p>{content.OTP_EXHAUSTED_TEXT}</p>
        </div>
      </div>
    </div>
  );
}

export default OtpExhausted;
