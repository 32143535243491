import { ENV, apiEndpoints, baseUrl } from "config/apiEndpoints";
import { GET, POST } from "services";
import { SCORER_PAGE_SIZE } from "utils/constants";

export const getScorerListPaginated = async (reqData: any) => {
  if (!Object.keys(reqData).length) {
    reqData = {
      page: 1,
      pageSize: SCORER_PAGE_SIZE,
    };
  }
  let result = await POST(
    baseUrl[ENV] + apiEndpoints.scorersPaginatedList,
    reqData
  );
  return result;
};

export const getScorerDetailsById = async (scorerId: string) => {
  const params = { scorerId: scorerId };
  const result = await GET(baseUrl[ENV] + apiEndpoints.getScorerById, params);
  return result;
};

export const createScorer = async (scorerData: any) => {
  const result = await POST(
    baseUrl[ENV] + apiEndpoints.createScorer,
    scorerData
  );
  return result;
};

export const editScorer = async (scorerData: any) => {
  const result = await POST(baseUrl[ENV] + apiEndpoints.editScorer, scorerData);
  return result;
};

export const getMatchListByScorerId = async (
  scorerId: string,
  reqData: any
) => {
  if (!Object.keys(reqData).length) {
    reqData = {
      page: 1,
      pageSize: SCORER_PAGE_SIZE,
    };
  }
  let result = await POST(
    baseUrl[ENV] + apiEndpoints.getMatchesByScorerId + "/" + scorerId,
    reqData
  );
  return result;
};

export const formatGetScorerData = (scorerData: any) => {
  const extraDataKeys = ["createdAt", "updatedAt", "id"];

  for (let key of extraDataKeys) {
    delete scorerData[key];
  }

  return scorerData;
};
