import React from "react";
import { Outlet, Navigate } from "react-router-dom";

import useAuthHandlers from "../hooks/useAuthHandlers";

const RestrictedRoutes = (): React.JSX.Element => {
  const authHook = useAuthHandlers();

  const isLoggedIn = authHook.checkIsLoggedIn();

  return !isLoggedIn ? <Outlet /> : <Navigate to="/" />;
};

export default RestrictedRoutes;
