import React, { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import {
  DateFieldCalenderStyles,
  DateFieldStyles,
} from "./FormUtils/FormStyles";
import styles from "./styles.module.css";

export const FormInputDateTime = (props: any) => {
  const { name, label, disabled, rules, required } = props;
  const { control } = useFormContext();

  const [open, setOpen] = useState(false);

  return (
    <>
      <label className={styles["form-label"]}>
        {label}{" "}
        {required ? (
          <span className={styles["required-symbol"]}>*</span>
        ) : (
          <></>
        )}
      </label>

      <LocalizationProvider dateAdapter={AdapterDateFns} data-testid={name}>
        <Controller
          name={name}
          control={control}
          defaultValue={null}
          rules={rules}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <>
              <DateTimePicker
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                value={value}
                onChange={onChange}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    error: !!error,
                    name: name,
                    onClick: () => setOpen(true),
                  },
                  layout: { sx: DateFieldCalenderStyles },
                }}
                sx={DateFieldStyles}
                disabled={disabled}
                ampm={false}
                format="dd/MM/yyyy HH:mm"
                disablePast
                data-testid={name}
                aria-label={name}
              />
              <div className={styles["error-msg"]}>
                {error ? error.message : null}
              </div>
            </>
          )}
        />
      </LocalizationProvider>
    </>
  );
};
