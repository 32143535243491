/*eslint-disable*/
import React from "react";
import { GridColDef } from "@mui/x-data-grid";
import {
  StyledBoolean,
  ValueWithCopy,
} from "components/Common Components/DataTable/DatatableConfig";

export const GroundListConfig: GridColDef[] = [
  {
    field: "name",
    headerName: "Ground Name",
    flex: 1,
    disableColumnMenu: true,
    hideable: false,
  },
  {
    field: "venueId",
    headerName: "ID",
    flex: 2,
    disableColumnMenu: true,
    hideable: false,
    renderCell: (params: any) => {
      return ValueWithCopy(params.value);
    },
  },
  {
    field: "city",
    headerName: "City",
    flex: 1,
    disableColumnMenu: true,
    hideable: false,
  },

  {
    field: "isActive",
    headerName: "IS ACTIVE",
    sortable: true,
    flex: 1,
    disableColumnMenu: true,
    renderCell: (params: any) => {
      const value = params.value === 0 ? 0 : 1;
      return StyledBoolean(value);
    },
  },
];
