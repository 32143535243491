import { FieldType } from "components/Common Components/Form Fields/FieldAttributes";
import {
  GENDER,
  ISACTIVE,
  PLAYERBATTINGHAND,
  PLAYERBOWLINGHAND,
  PLAYERBOWLINGTYPE,
  PLAYERTYPE,
} from "./PlayerConstants";

import { CRICKET_ATTRIBUTES, FOOTBALL_ATTRIBUTES, BADMINTON_ATTRIBUTES } from "components/Common Components/Sports/SportsConstants";
export const personalDetailsLeftColConfig = (formConfig: any ,formName?: string) =>{
  const config = [
    [
      {
        type: FieldType.TEXT,
        label: "Full name",
        name: "name",
        placeholder: "Enter full name",
        disabled: formConfig.name.disabled,
        required: true,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.TEXT,
        label: "Display name",
        name: "short_name",
        placeholder: "Enter display name",
        disabled: formConfig.displayName.disabled,
        required: true,
        maxLength: 10,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type:  formName !== "view" ? FieldType.AUTOCOMPLETE : FieldType.TEXT,
        label: "State",
        name: "state",
        placeholder: "Enter State",
        disabled: formConfig?.state?.disabled || false,
        optionsFetcher: formConfig?.state?.optionsFetcher,
        OptionsDisplayComp: formConfig?.state?.OptionsDisplayComp,
        onValueChange: formConfig?.state?.onValueChange,
        required: true,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type:  formName !== "view" ? FieldType.AUTOCOMPLETELIVE : FieldType.TEXT,
        label: "City",
        name: "city",
        placeholder: "Enter city",
        disabled: formConfig.city.disabled,
        optionsFetcher: formConfig?.city?.optionsFetcher,
        OptionsDisplayComp: formConfig?.city?.OptionsDisplayComp,
        idParam: formConfig?.city?.idParam,
        required: true,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.DATE,
        label: "Player date of birth",
        name: "dob",
        placeholder: "DD/MM/YYYY",
        disabled: formConfig.playerDateOfBirth.disabled,
        required: true,
        disablePast: false,
        disableFuture: true,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
  ];
  return config;
}

export const personalDetailsMiddleColConfig = (formConfig: any ,formName?: string) =>{
  const config = [
    [
      {
        type: FieldType.TEXT,
        label: "LinkedIn",
        name: "linkedin_handle",
        placeholder: "Enter linkedIn handle",
        disabled: formConfig.name.disabled,
        required: false,
        rules: {
          required: {
            value: false,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.TEXT,
        label: "Instagram",
        name: "instagram_handle",
        placeholder: "Enter instagram handle",
        disabled: formConfig.name.disabled,
        required: false,
        rules: {
          required: {
            value: false,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.TEXT,
        label: "facebook",
        name: "facebook_handle",
        placeholder: "Enter facebook handle",
        disabled: formConfig.name.disabled,
        required: false,
        rules: {
          required: {
            value: false,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.TEXT,
        label: "Idol",
        name: "idol",
        placeholder: "Enter idol",
        disabled: formConfig.name.disabled,
        required: false,
        rules: {
          required: {
            value: false,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.TEXT,
        label: "Contact number",
        name: "mobile",
        placeholder: "Enter contact number",
        disabled: formConfig.contactNumber.disabled,
        required: false,
        maxLength: 10,
        rules: {
          required: {
            value: false,
            message: "This field is mandatory.",
          },
          pattern: {
            value: /^[6-9]\d{9}$/gi,
            message: "Please enter a valid number.",
          },
        },
      },
    ],
  ]
  return config;
}

export const personalDetailssRightColConfig = (formConfig: any, formName?: string) => {
  const config = [
    [
      {
        type: FieldType.FILE,
        label: "Upload Photo",
        name: "image_url",
        disabled: formConfig.uploadPhoto.disabled,
        required: true,
        formType: "Scorer",
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.RADIO,
        label: "isActive",
        name: "is_active",
        disabled: formConfig.isActive.disabled,
        required: true,
        options: ISACTIVE,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.RADIO,
        label: "Gender",
        name: "gender",
        disabled: formConfig.gender.disabled,
        required: true,
        options: GENDER,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
  ];
  return config;
};

export const sportsSectionConfig = (formConfig: any, formType?: string) => {
  const config = [
    [
      {
        type: FieldType.MULTISELECTLIVE,
        label: "Select sports",
        name: "sports",
        placeholder: "select sports",
        disabled: formConfig?.sports?.disabled,
        optionsFetcher: formConfig?.sports?.optionsFetcher,
        OptionsDisplayComp: formConfig?.sports?.OptionsDisplayComp,
        populateOptions: formConfig?.sports?.populateOptions || false,
        onValueChange: formConfig?.sports?.onValueChange,
        required: true,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
  ]

  return config;
}

export const cricketColConfig = (formConfig: any, formType?: string) => {
  const config = [
    [
      {
        type: FieldType.MULTISELECTLIVE,
        label: "Player Teams",
        name: "cricket_teams",
        placeholder: "Search for Teams",
        disabled: formConfig?.playerTeam?.disabled,
        optionsFetcher: formConfig?.playerTeam?.optionsFetcher,
        OptionsDisplayComp: formConfig?.playerTeam?.OptionsDisplayComp,
        idParam: formConfig?.playerTeam?.idParam,
        required: true,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.SELECT,
        label: "Player Role",
        name: "cricket_cricketRole",
        placeholder: "Select player role",
        disabled: formConfig?.playerType?.disabled,
        required: true,
        options: CRICKET_ATTRIBUTES.PLAYERTYPE,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.RADIO,
        label: "Player Batting Hand",
        name: "cricket_battingHand",
        disabled: formConfig.playerBattingHand.disabled,
        required: true,
        options: CRICKET_ATTRIBUTES.PLAYERBATTINGHAND,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.RADIO,
        label: "Player Bowling Hand",
        name: "cricket_bowlingHand",
        disabled: formConfig.playerBowlingHand.disabled,
        required: true,
        options: CRICKET_ATTRIBUTES.PLAYERBOWLINGHAND,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.SELECT,
        label: "Player bowling type",
        name: "cricket_bowlingType",
        placeholder: "Select player bowling type",
        disabled: formConfig.playerBowlingType.disabled,
        required: true,
        options: CRICKET_ATTRIBUTES.PLAYERBOWLINGTYPE,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
  ];
  return config;
};

export const footballColConfig = (formConfig: any, formType?: string) => {
  const config = [
    [
      {
        type: FieldType.MULTISELECTLIVE,
        label: "Player Teams",
        name: "football_teams",
        placeholder: "Search for Teams",
        disabled: formConfig?.playerTeam?.disabled,
        optionsFetcher: formConfig?.playerTeam?.optionsFetcher,
        OptionsDisplayComp: formConfig?.playerTeam?.OptionsDisplayComp,
        idParam: formConfig?.playerTeam?.idParam,
        required: true,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.SELECT,
        label: "Player Role",
        name: "football_footballRole",
        placeholder: "Select player role",
        disabled: formConfig?.playerType?.disabled,
        required: true,
        options: FOOTBALL_ATTRIBUTES.PLAYERTYPE,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.SELECT,
        label: "Player Primary Foot",
        name: "football_primaryFoot",
        placeholder: "Select primary Foot",
        disabled: formConfig?.playerPrimaryFoot?.disabled,
        required: true,
        options: FOOTBALL_ATTRIBUTES.PLAYERPRIMARYFOOT,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
  ];
  return config;
};

export const badmintonColConfig = (formConfig: any, formType?: string) => {
  const config = [
    [
      {
        type: FieldType.MULTISELECTLIVE,
        label: "Player Teams",
        name: "badminton_teams",
        placeholder: "Search for Teams",
        disabled: formConfig?.playerTeam?.disabled,
        optionsFetcher: formConfig?.playerTeam?.optionsFetcher,
        OptionsDisplayComp: formConfig?.playerTeam?.OptionsDisplayComp,
        idParam: formConfig?.playerTeam?.idParam,
        required: true,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.SELECT,
        label: "Player Primary Hand",
        name: "badminton_primaryHand",
        placeholder: "Select primary Hand",
        disabled: formConfig?.playerPrimaryHand?.disabled,
        required: true,
        options: BADMINTON_ATTRIBUTES.PLAYERPRIMARYHAND,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
  ];
  return config;
};

export const leftSideColConfig = (formConfig: any, formType?: string) => {
  const config = [
    [
      {
        type: FieldType.TEXT,
        label: "Full name",
        name: "name",
        placeholder: "Enter full name",
        disabled: formConfig.name.disabled,
        required: true,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.TEXT,
        label: "Display name",
        name: "short_name",
        placeholder: "Enter display name",
        disabled: formConfig.displayName.disabled,
        required: true,
        maxLength: 10,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.TEXT,
        label: "Contact number",
        name: "mobile",
        placeholder: "Enter contact number",
        disabled: formConfig.contactNumber.disabled,
        required: false,
        maxLength: 10,
        rules: {
          required: {
            value: false,
            message: "This field is mandatory.",
          },
          pattern: {
            value: /^[6-9]\d{9}$/gi,
            message: "Please enter a valid number.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.TEXT,
        label: "City",
        name: "city",
        placeholder: "Enter city",
        disabled: formConfig.city.disabled,
        required: true,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],

    [
      {
        type: FieldType.DATE,
        label: "Player date of birth",
        name: "dob",
        placeholder: "DD/MM/YYYY",
        disabled: formConfig.playerDateOfBirth.disabled,
        required: true,
        disablePast: false,
        disableFuture: true,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.SELECT,
        label: "Player type",
        name: "role",
        placeholder: "Select player type",
        disabled: formConfig.playerType.disabled,
        required: true,
        options: PLAYERTYPE,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.MULTISELECTLIVE,
        label: "Player Teams",
        name: "teams",
        placeholder: "Search for Teams",
        disabled: formConfig?.teams?.disabled,
        optionsFetcher: formConfig?.teams?.optionsFetcher,
        OptionsDisplayComp: formConfig?.teams?.OptionsDisplayComp,
        idParam: formConfig?.teams?.idParam,
        required: true,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.TEXT,
        label: "LinkedIn",
        name: "linkedin_handle",
        placeholder: "Enter linkedIn handle",
        disabled: formConfig.name.disabled,
        required: false,
        rules: {
          required: {
            value: false,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.TEXT,
        label: "Instagram",
        name: "instagram_handle",
        placeholder: "Enter instagram handle",
        disabled: formConfig.name.disabled,
        required: false,
        rules: {
          required: {
            value: false,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.TEXT,
        label: "facebook",
        name: "facebook_handle",
        placeholder: "Enter facebook handle",
        disabled: formConfig.name.disabled,
        required: false,
        rules: {
          required: {
            value: false,
            message: "This field is mandatory.",
          },
        },
      },
    ],
  ];
  return config;
};

export const rightSideColConfig = (formConfig: any, formName?: string) => {
  const config = [
    [
      {
        type: FieldType.FILE,
        label: "Upload Photo",
        name: "image_url",
        disabled: formConfig.uploadPhoto.disabled,
        required: true,
        formType: "Scorer",
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.SELECT,
        label: "Player bowling type",
        name: "bowling_type",
        placeholder: "Select player bowling type",
        disabled: formConfig.playerBowlingType.disabled,
        required: true,
        options: PLAYERBOWLINGTYPE,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.RADIO,
        label: "Player Batting Hand",
        name: "batting_hand",
        disabled: formConfig.playerBattingHand.disabled,
        required: true,
        options: PLAYERBATTINGHAND,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.RADIO,
        label: "Player Bowling Hand",
        name: "bowling_hand",
        disabled: formConfig.playerBowlingHand.disabled,
        required: true,
        options: PLAYERBOWLINGHAND,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.TEXT,
        label: "Idol",
        name: "idol",
        placeholder: "Enter idol",
        disabled: formConfig.name.disabled,
        required: false,
        rules: {
          required: {
            value: false,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.RADIO,
        label: "isActive",
        name: "is_active",
        disabled: formConfig.isActive.disabled,
        required: true,
        options: ISACTIVE,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.RADIO,
        label: "Gender",
        name: "gender",
        disabled: formConfig.gender.disabled,
        required: true,
        options: GENDER,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
  ];
  return config;
};
