import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { styled, Theme, CSSObject } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import useAuthHandlers from "../../../hooks/useAuthHandlers";

import { drawerConfig, logOutConfig } from "./drawerConfig";
import colors from "config/colorConfig";
import { sideDrawerWidth } from "utils/constants";

import styles from "./styles.module.css";
import LogoMini from "../../../assets/SideDrawer/pavillion-logo-mini.svg";
import Logo from "../../../assets/Pavilion.svg";

const drawerWidth = sideDrawerWidth;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.easeInOut,
    // duration: theme.transitions.duration.enteringScreen,
    duration: 400,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.easeInOut,
    duration: 400,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(6)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(7)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function SideDrawer(props: any) {
  const authHook = useAuthHandlers();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const handleDrawer = () => {
    setOpen((prev: Boolean) => !prev);
  };

  const menuItemStyle = {
    ".MuiTypography-root": {
      fontSize: "1.6rem",
      lineHeight: "2.4rem",
      color: colors.grayNinety,
    },
  };

  return (
    <div className={styles["container"]} >
      <CssBaseline />
      <Drawer
        variant="permanent"
        open={open}
        onMouseEnter={handleDrawer} onMouseLeave={handleDrawer}
      >
        <DrawerHeader sx={{ my: 3, justifyContent: "center" }}>
          <IconButton
            disableRipple
            // onClick={handleDrawer}
            onClick={()=>navigate("/")}
            sx={{ position: "relative" }}
          >
            <img
              src={Logo}
              width={250}
              alt="logo"
              style={{ display: open ? "block" : "none" }}
            />
            <img
              src={LogoMini}
              alt="logo"
              style={{ display: open ? "none" : "block" }}
            />
          </IconButton>
        </DrawerHeader>
        <List>
          {drawerConfig?.map((item: any) => {
            return (
              <ListItem
                key={item.id}
                disablePadding
                sx={{ display: "block" }}
                onClick={() => navigate(item.path)}
              >
                <ListItemButton
                  sx={{
                    minHeight: 50,
                    justifyContent: open ? "initial" : "center",
                    px: 2,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 2 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <img src={item.icon} alt="icon" />
                  </ListItemIcon>
                  <ListItemText
                    primary={item.label}
                    sx={{
                      opacity: open ? 1 : 0,
                      ...menuItemStyle,
                    }}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
        <List sx={{ marginTop: "auto" }}>
          <ListItem
            disablePadding
            sx={{ display: "block" }}
            onClick={() => authHook.handleLogout()}
          >
            <ListItemButton
              sx={{
                minHeight: 50,
                justifyContent: open ? "initial" : "center",
                px: 2,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2 : "auto",
                  justifyContent: "center",
                }}
              >
                <img src={logOutConfig.icon} alt="icon" />
              </ListItemIcon>
              <ListItemText
                primary={logOutConfig.label}
                sx={{ opacity: open ? 1 : 0, ...menuItemStyle }}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      <main>{props.children}</main>
    </div>
  );
}
