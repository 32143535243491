export const ISACTIVE = [
  {
    label: "True",
    value: 1,
  },
  {
    label: "False",
    value: 0,
  },
];

export const GENDER = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
  { label: "Other", value: "other" },
];

export const PLAYERTYPE = [
  { label: "Batter", value: "BATSMAN" },
  { label: "Bowler", value: "BOWLER" },
  { label: "All-Rounder", value: "ALL_ROUNDER" },
  { label: "Wicketkeeper", value: "WICKET_KEEPER" },
];

export const PLAYERBATTINGHAND = [
  { label: "Right Hand Batting", value: "right-hand-batting" },
  { label: "Left Hand Batting", value: "left-hand-batting" },
];

export const PLAYERBOWLINGHAND = [
  { label: "Right Arm", value: "right-arm" },
  { label: "Left Arm", value: "left-arm" },
];

export const PLAYERBOWLINGTYPE = [
  { label: "Fast", value: "fast" },
  { label: "Medium", value: "medium" },
  { label: "Slow", value: "slow" },
  { label: "Off Spin", value: "off-spin" },
  { label: "Leg Spin", value: "leg-spin" },
  { label: "Unorthodox Spin", value: "unorthodox-spin" },
];

export const PLAYER_ROLES = {
  BATSMAN: "BATSMAN",
  BOWLER: "BOWLER",
  ALL_ROUNDER: "ALL_ROUNDER",
  "ALL ROUNDER": "ALL ROUNDER",
  WICKET_KEEPER: "WICKET_KEEPER",
  "WICKET KEEPER": "WICKET KEEPER",
};

export const playerAttributesCaseMap: any = {
  battingHand: "batting_hand",
  bowlingHand: "bowling_hand",
  bowlingType: "bowling_type",
  cricketRole: "role",
  role: "role",
};

export const basicPlayerInfoKeys = [
  "name",
  "short_name",
  "gender",
  "image_url",
  "is_active",
  "dob",
  "mobile",
  "city",
  "facebook_handle",
  "instagram_handle",
  "linkedin_handle",
  "idol",
];
export const playerAttributeKeys = [
  "battingHand",
  "bowlingHand",
  "bowlingType",
  "footballRole",
  "cricketRole",
];
export const cricketAttributeKeys = [
  "battingHand",
  "bowlingHand",
  "bowlingType",
];
// export const footballAttributesKeys = ["footballRole"];

export const allowedRoles = [
  "BATSMAN",
  "BOWLER",
  "ALL_ROUNDER",
  "WICKET_KEEPER",
];
