import React, { useState, useEffect } from "react";
import styles from "./style.module.scss";

interface RadioSelectProps {
  inputKey: string;
  isRequired: boolean;
  label: string;
  presentValue: string;
  options: string[];
  isEditable?: boolean;
  isFullWidth?: boolean;
  onInputChange: any;
  name?: any;
}

const RadioSelect = ({
  inputKey,
  isRequired,
  label,
  options,
  presentValue,
  isEditable,
  isFullWidth,
  onInputChange,
  name,
}: RadioSelectProps) => {
  const [selectedValue, setSelectedValue] = useState(presentValue || "");

  useEffect(() => {
    // Update selectedValue when presentValue changes (e.g., during edit)
    setSelectedValue(presentValue || "");
  }, [presentValue]);

  const handleChange = (event: any) => {
    onInputChange(event.target.value); // Call the onInputChange function
  };

  return (
    <div
      className={styles["radio-group-container"]}
      style={{ width: isFullWidth ? "100%" : "" }}
    >
      <label>
        {label}
        {isRequired && <span style={{ color: "red" }}> *</span>}
      </label>
      <div className={styles["radio-options-wrapper"]}>
        {options.map((option, index) => (
          <div key={index} className={styles["radio-select-name"]}>
            <input
              type="radio"
              id={`${inputKey}-${index}`}
              name={name || inputKey}
              value={option}
              checked={selectedValue === option}
              onChange={handleChange}
              required={isRequired}
              disabled={!isEditable}
            />
            <label htmlFor={`${inputKey}-${index}`}>{option}</label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RadioSelect;
