import colors from "config/colorConfig";

export const TextFieldInputStyles = {
  ".MuiInputBase-input": {
    fontSize: "1.6rem",
    lineHeight: "2.4rem",
    fontWeight: "400",
    //   border: `0.1rem solid ${colors.grayForty}`,
    borderRadius: "0.8rem",
    boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  },
  ".MuiInputBase-root": {
    backgroundColor: colors.white,
    height: "4.1rem",
  },
  ".Mui-disabled": {
    "&.MuiInputBase-root": {
      backgroundColor: colors.grayTen,
    },
  },
};

export const SelectFieldStyles = {
  ".MuiSelect-select": {
    fontSize: "1.6rem",
    lineHeight: "2.4rem",
    fontWeight: "400",
    borderRadius: "0.8rem",
    boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
    backgroundColor: colors.white,
  },
  ".Mui-disabled": {
    "&.MuiSelect-select": {
      backgroundColor: colors.grayTen,
    },
  },
};

export const SelectFieldDropdownStyles = {
  "&.MuiMenuItem-root": {
    fontSize: "1.6rem",
  },
};

export const DateFieldStyles = {
  ".MuiInputBase-input": {
    fontSize: "1.6rem",
    lineHeight: "2.4rem",
    fontWeight: "400",
    //   border: `0.1rem solid ${colors.grayForty}`,
    borderRadius: "0.8rem",
  },
  ".MuiInputBase-root": {
    backgroundColor: colors.white,
    height: "4.1rem",
  },
  ".Mui-disabled": {
    "&.MuiInputBase-root": {
      backgroundColor: colors.grayTen,
    },
  },
};

export const DateFieldCalenderStyles = {
  ".MuiPickersCalendarHeader-label": {
    fontSize: "1.3rem",
  },
  ".MuiPickersCalendarHeader-switchViewIcon": {
    fontSize: "1.3rem",
  },
  ".MuiDayCalendar-weekDayLabel": {
    fontSize: "1.3rem",
  },
  ".MuiPickersDay-root": {
    fontSize: "1.3rem",
  },
  ".MuiPickersDay-today": {
    border: "none",
  },
  ".MuiPickersMonth-root": {
    fontSize: "1.3rem",
  },
  ".MuiPickersYear-yearButton": {
    fontSize: "1.3rem",
  },
  ".MuiMultiSectionDigitalClock-root": {
    fontSize: "1.3rem",
  },
  ".MuiMultiSectionDigitalClock-root::-webkit-scrollbar": {
    width: "0.7rem",
  },
  ".MuiMultiSectionDigitalClock-root::-webkit-scrollbar-track": {
    boxShadow: "inset 0 0 0.6rem rgba(0, 0, 0, 0.2)",
    borderRadius: "0.5rem",
  },
  ".MuiMultiSectionDigitalClock-root::-webkit-scrollbar-thumb": {
    borderRadius: "0.5rem",
    background: colors.grayThirty,
  },
  ".MuiButtonBase-root": {
    fontSize: "1.3rem",
  },
};

export const RadioButtonLabelStyles = {
  ".MuiFormControlLabel-label": {
    fontSize: "1.6rem",
    lineHeight: "2.4rem",
    fontWeight: "400",
    color: colors.graySixty,
  },
};

export const RadioButtonStyles = {
  ".MuiSvgIcon-root": {
    fontSize: 20,
  },
  "&.Mui-checked": {
    color: colors.grayNinety,
  },
  "&.Mui-disabled": {
    "&.Mui-checked": {
      color: colors.grayFifty,
    },
  },
};

export const FileUploadButton = {
  "&.MuiButtonBase-root": {
    border: "0.1rem solid",
    borderColor: colors.grayTwenty,
    fontSize: "1.2rem",
    lineHeight: "1.6rem",
    fontWeight: 600,
    borderRadius: "0.8rem",
    padding: "0.8rem 1.6rem",
    backgroundColor: colors.white,
    color: colors.grayNinety,
    height: "fit-content",
    alignSelf: "center",
    marginLeft: "4rem",
  },
  "&.Mui-disabled": {
    "&.MuiButtonBase-root": {
      color: colors.grayThirty,
    },
  },
};

export const SearchDropdownStyles = {
  ".MuiInputBase-input": {
    fontSize: "1.6rem",
    lineHeight: "2.4rem",
    fontWeight: "400",
    borderRadius: "0.8rem",
  },
  ".MuiInputBase-root": {
    backgroundColor: colors.white,
    height: "4.1rem",
    ".MuiAutocomplete-endAdornment": {
      "&.MuiSvgIcon-root": {
        height: "1rem",
        width: "1rem",
      },
    },
  },
  ".Mui-disabled": {
    "&.MuiInputBase-root": {
      backgroundColor: colors.grayTen,
    },
  }
};

export const MultiSearchDropdownStyles = {
  ".MuiInputBase-input": {
    fontSize: "1.6rem",
    lineHeight: "2.4rem",
    fontWeight: "400",
    borderRadius: "0.8rem",
  },
  ".MuiInputBase-root": {
    backgroundColor: colors.white,
    minHeight: "4.1rem",
    height:"100%",
    "&.MuiAutocomplete-endAdornment": {
      ".MuiSvgIcon-root": {
        height: "1rem",
        width: "1rem",
      },
    },

  },
  ".Mui-disabled": {
    "&.MuiInputBase-root": {
      backgroundColor: colors.grayTen,
    },
  },
  ".MuiButtonBase-root":{
    fontSize: "1.3rem",
    lineHeight: "2.2rem",
    fontWeight: 600,
  }
};

export const TimePickerStyles = {
  ".MuiMultiSectionDigitalClock-root": {
    fontSize: "1.3rem",
  },
  ".MuiButtonBase-root": {
    fontSize: "1.3rem",
  },
};
