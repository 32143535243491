import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { toast } from "react-toastify";

import HeaderOptionsButton from "components/Common Components/Buttons/HeaderOptionsButton";
import DataTable from "components/Common Components/DataTable/Index";
import EmptyList from "components/Common Components/EmptyList";

import { getGroundsListPaginated } from "services/grounds";
import { GroundListConfig } from "./listConfig";
import colors from "config/colorConfig";
import { GROUND_PAGE_SIZE } from "utils/constants";
import styles from "./styles.module.css";
import BackdropLoader from "components/Common Components/Loaders/LoaderBackdrop";

function GroundList() {
  const navigate = useNavigate();

  const [dataList, setDataList] = useState([]);
  const [totalRows, setTotalRows] = useState(10);
  const [emptyListFlag, setEmptyListFlag] = useState(false);
  const [loaderState, setLoaderState] = useState(false);

  const getGroundsList = async (reqData: any) => {
    setLoaderState(true);
    let result = await getGroundsListPaginated(reqData);
    if (result && !result?.isError) {
      const groundList = result?.data?.list;
      if (!groundList?.length) {
        setLoaderState(false);
        setEmptyListFlag(true);
        return;
      }
      setDataList(groundList);
      setTotalRows(result?.data?.pagination?.totalRows);
    } else {
      toast.error(result?.reason);
    }
    setLoaderState(false);
  };

  const handleRowClick = (groundData: any) => {
    const groundId = groundData?.row?.venueId;
    navigate(`/ground/view/${groundId}`);
  };

  const handleSortingAndPagination = (
    page: number,
    pageSize: number,
    sortKey: string,
    sortType: string
  ) => {
    const reqData: any = {};
    const sortOptions: any = {};

    if (sortKey) {
      sortOptions.key = sortKey;
      sortOptions.type = sortType.toUpperCase();

      reqData.sortby = sortOptions;
    }
    reqData.page = page;
    reqData.pageSize = pageSize;
    getGroundsList(reqData);
  };

  useEffect(() => {
    getGroundsList({});
  }, []);

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <div className={styles["form-heading"]}>Grounds &nbsp;</div>
        <div>
          <HeaderOptionsButton
            fontColor={colors.white}
            backgroundColor={colors.grayNinety}
            onClick={() => {
              navigate("/ground/create");
            }}
          >
            Add Ground +
          </HeaderOptionsButton>
        </div>
      </Box>
      {dataList?.length ? (
        <DataTable
          columns={GroundListConfig}
          data={dataList}
          totalRows={totalRows}
          pageSize={GROUND_PAGE_SIZE}
          onRowClick={handleRowClick}
          sortPaginationHandler={handleSortingAndPagination}
        />
      ) : (
        <></>
      )}
      {emptyListFlag ? <EmptyList message="No Ground Created!" /> : <></>}
      <BackdropLoader loaderState={loaderState} />
    </>
  );
}

export default GroundList;
