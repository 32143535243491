import React from "react";

import SideDrawer from "components/Common Components/SideDrawer/SideDrawer";
import CreateTeamForm from "components/Teams/CreateTeam/CreateTeamForm";

function CreateTeam() {
  return (
    <>
      <SideDrawer>
        <div style={{ padding: "3.2rem" }}>
          <CreateTeamForm />
        </div>
      </SideDrawer>
    </>
  );
}

export default CreateTeam;
