import {
  SPORTS,
  GROUND_AMENITIES,
  CRICKET_LIST,
  BADMINTON_LIST,
  FOOTBALL_LIST,
} from "./SportsConstants";

export const getAllSportsListMapped = () => {
  return SPORTS.map((sport) => {
    return {
      id: sport.value,
      label: sport.label,
    };
  });
};

export const getCricketListMapped = () => {
  return CRICKET_LIST.map((item) => {
    return {
      id: item.value,
      label: item.label,
    };
  });
};

export const getBadmintonListMapped = () => {
  return BADMINTON_LIST.map((item) => {
    return {
      id: item.value,
      label: item.label,
    };
  });
};

export const getFootballListMapped = () => {
  return FOOTBALL_LIST.map((item) => {
    return {
      id: item.value,
      label: item.label,
    };
  });
};

export const getAllAmenitiesListMapped = () => {
  return GROUND_AMENITIES.map((amenity) => {
    return {
      id: amenity.value,
      label: amenity.label,
    };
  });
};
