import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { toast } from "react-toastify";

import FieldMapper from "components/Common Components/Form Fields/FormUtils/FieldMapper";
import FormButton from "components/Common Components/Buttons/FormButton";
import EntityDropDown from "components/Common Components/Dropdowns/EntityDropdown";

import { getTeamsListMapped } from "components/Matches/helper";
import {
  editPlayer,
  formatGetPlayerData,
  formatPostPlayerData,
  getPlayerDetailsById,
} from "services/players";
import { 
  badmintonColConfig,
  cricketColConfig, 
  footballColConfig, 
  personalDetailsLeftColConfig, 
  personalDetailsMiddleColConfig, 
  personalDetailssRightColConfig, 
  sportsSectionConfig } from "../formConfig";
import { logFirebaseEvent } from "config/firebase";
import colors from "config/colorConfig";
import styles from "../styles.module.css";
import BackdropLoader from "components/Common Components/Loaders/LoaderBackdrop";
import { getAllStateListMapped, getCitiesListMapped } from "components/Common Components/Location/helper";
import { getAllSportsListMapped } from "components/Common Components/Sports/helper";

function EditPlayerForm() {
  const methods = useForm({
    criteriaMode: "firstError",
    mode: "onBlur",
    shouldFocusError: true,
  });

  const {
    setValue,
    handleSubmit,
    formState: { isDirty },
  } = methods;

  const navigate = useNavigate();
  const { state } = useLocation();
  const { playerId } = useParams();

  const [loaderState, setLoaderState] = useState(false);
  // const[allStates, setAllStates] = useState<any[]>([]);
  // const[playerData, setPlayerData] = useState<any>({});
  const [selectedState, setSelectedState] =  useState<string>('');
  const [selectedSports, setSelectedSports] = useState<string[]>([]);

  const onStateValueChange = useCallback((item: any) => {
    setSelectedState(item.id);
    setValue("city", {id: " ",label:" "});
  },[setSelectedState]);

  const onSportsValueChange = useCallback((values: any) => {
    setSelectedSports(values.map((val: any)=> val.id));
  },[setSelectedSports]);
  

  const fetchPlayerData = async () => {
    setLoaderState(true);
    if (playerId) {
      let result = await getPlayerDetailsById(playerId);
      if (result && !result?.isError) {
        let formattedData = formatGetPlayerData({
          ...result?.data?.playerDetails,
          teams: result?.data?.teams,
          playerAttributes: result?.data?.playerAttributes,
        });
        setSelectedSports(formattedData.playerSports || []);
        setSelectedState(formattedData.state.id);
        setPageData(formattedData);
      } else {
        toast.error(result?.reason);
      }
    } else {
      toast.error("There was some error! Please try again");
    }
    setLoaderState(false);
  };

  const setPageData = (data: any) => {
    for (const [key, value] of Object.entries(data)) {
      value && setValue(key, value);
    }
    setValue("is_active", data?.is_active);
    if(data?.playerSportsOptions){
      setValue('sports', data?.playerSportsOptions);
    }
    if(data?.playerSports && data?.playerSportsConfig){
      data.playerSports.forEach((sport: string )=>{
        data.playerSportsConfig[sport].attributes.forEach((attr:any)=>{
          setValue(attr.fieldName, attr.attributeValue)
        })
        setValue(`${sport.toLowerCase()}_teams`, data.playerSportsConfig[sport].teams)
      })
    }
  };
  const initializePageData = () => {
    if (!state) {
      fetchPlayerData();
    } else {
      const { playerData } = state;
      setSelectedSports(playerData?.playerSports || []);
      setSelectedState(playerData.state.id);
      setPageData(playerData);

    }
  };
  const formSubmitHandler = async (formData: any) => {
    setLoaderState(true);
    const age =
      new Date().getFullYear() - new Date(formData["dob"]).getFullYear();
    formData["age"] = age;
    formData["is_active"] = Number(formData["is_active"]);

    const formattedData = formatPostPlayerData(formData);
    const result = await editPlayer(formattedData);
    if (result && !result?.isError) {
      const playerId = result?.data?.player_id;
      logFirebaseEvent({
        event_name: "player_edit_save",
        player_id: playerId,
      });
      toast.success("Updated successfully!");
      navigate(-1);
    } else {
      toast.error(result?.reason);
    }
    setLoaderState(false);
  };
  useEffect(() => {
    initializePageData();
  }, []);

  const formConfig =  useMemo(() => {
    return {
      name: {
        disabled: false,
      },
      displayName: {
        disabled: false,
      },
      contactNumber: {
        disabled: false,
      },
      state: {
        disabled: false,
        optionsFetcher: getAllStateListMapped,
        OptionsDisplayComp: EntityDropDown,
        onValueChange: onStateValueChange
      },
      city: {
        disabled: false,
        optionsFetcher: getCitiesListMapped,
        OptionsDisplayComp: EntityDropDown,
        idParam: selectedState
      },
      playerDateOfBirth: {
        disabled: false,
      },
      uploadPhoto: {
        disabled: false,
      },
      isActive: {
        disabled: false,
      },
      gender: {
        disabled: false,
      },
      linkedin_handle: {
        disabled: false,
      },
      instagram_handle: {
        disabled: false,
      },
      facebook_handle: {
        disabled: false,
      },
      idol: {
        disabled: false,
      },
      sports: {
        disabled: false,
        optionsFetcher: getAllSportsListMapped,
        OptionsDisplayComp: EntityDropDown,
        populateOptions: true,
        onValueChange: onSportsValueChange,
      }
    };
  },[selectedState, onStateValueChange]);

  const cricketFormConfig = useMemo(() =>{
    return {
      playerTeam: {
        disabled: false,
        optionsFetcher: getTeamsListMapped,
        OptionsDisplayComp: EntityDropDown,
      },
      playerType: {
        disabled: false,
      },
      playerBattingHand: {
        disabled: false,
      },
      playerBowlingHand: {
        disabled: false,
      },
      playerBowlingType: {
        disabled: false,
      },
  }
  },[]);

  const footballFormConfig = useMemo(() =>{
    return {
      playerTeam: {
        disabled: false,
        optionsFetcher: getTeamsListMapped,
        OptionsDisplayComp: EntityDropDown,
      },
      playerType: {
        disabled: false,
      },
      playerPrimaryFoot: {
        disabled: false,
      }
  }
  },[]);

  const badmintonFormConfig = useMemo(() =>{
    return {
      playerTeam: {
        disabled: false,
        optionsFetcher: getTeamsListMapped,
        OptionsDisplayComp: EntityDropDown,
      },
      playerPrimaryHand: {
        disabled: false,
      }
  }
  },[]);

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <div className={styles["form-heading"]}>
          <Box display="flex" alignItems="center">
            <span> Edit Player</span>
          </Box>
        </div>
      </Box>

      <div className={styles["form-container"]}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(formSubmitHandler)}>
            <Grid container columnSpacing={6}>
            <Grid item xs={12} md={4}>
                <FieldMapper
                  fieldsConfig={personalDetailsLeftColConfig(formConfig, "create")}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <FieldMapper
                  fieldsConfig={personalDetailsMiddleColConfig(formConfig, "create")}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <FieldMapper
                  fieldsConfig={personalDetailssRightColConfig(formConfig, "create")}
                />
              </Grid>
            </Grid>

            <Divider sx={{ mt: 2 ,mb: 0}} />
            
            <Grid container columnSpacing={6}>
              <Grid item xs={12} md={8}>
                <FieldMapper
                  fieldsConfig={sportsSectionConfig(formConfig, "create")}
                />
              </Grid>
              {selectedSports.includes('CRICKET') && (
                <Grid item xs={12} md={8}>
                  <Typography sx={{ mt: 2, fontSize: 16, color: 'blue'}}>
                    1. CRICKET
                  </Typography>
                  <FieldMapper
                    fieldsConfig={cricketColConfig(cricketFormConfig, "create")}
                  />
                </Grid>
              )}
              {selectedSports.includes('FOOTBALL') && (
                <Grid item xs={12} md={8}>
                  <Typography sx={{ mt: 2, fontSize: 16, color: 'blue'}}>
                    2. FOOTBALL
                  </Typography>
                  <FieldMapper
                    fieldsConfig={footballColConfig(footballFormConfig, "create")}
                  />
                </Grid>
              )}
              {selectedSports.includes('BADMINTON') && (
                <Grid item xs={12} md={8}>
                  <Typography sx={{ mt: 2, fontSize: 16, color: 'blue'}}>
                    3. BADMINTON
                  </Typography>
                  <FieldMapper
                    fieldsConfig={badmintonColConfig(badmintonFormConfig, "create")}
                  />
                </Grid>
              )}
            </Grid>

            <Divider sx={{ my: 3 }} />

            <Grid container item columnGap={3}>
              <FormButton
                type="button"
                fontColor={colors.redSixty}
                backgroundColor={colors.white}
                borderColor={colors.grayThirty}
                onClick={() => navigate(-1)}
              >
                Discard
              </FormButton>

              <FormButton
                type="submit"
                fontColor={colors.white}
                backgroundColor={colors.grayNinety}
                disabledColor={colors.grayFifty}
                disabledBackground={colors.grayTwenty}
                disabled={!isDirty}
              >
                Save changes
              </FormButton>
            </Grid>
          </form>
        </FormProvider>
      </div>
      <BackdropLoader loaderState={loaderState} />
    </>
  );
}

export default EditPlayerForm;
