import React, { useState, useEffect, useRef } from "react";
import styles from "./style.module.scss";

interface Option {
  id: string;
  label: string;
}

interface SearchableMultiSelectDropdownProps {
  inputKey: string;
  isRequired: boolean;
  label: string;
  options: Option[];
  presentValue: string[]; // Store `id`s in selected values
  placeholder: string;
  isEditable: boolean;
  loading?: boolean;
  error?: string | null;
  isFullWidth?: boolean;
  // onInputChange: (inputKey: string, value: Option[]) => void;
  onInputChange: any;
}

const SearchableMultiSelectDropdown = ({
  inputKey,
  isRequired,
  label,
  options,
  presentValue,
  isEditable,
  placeholder,
  loading,
  error,
  isFullWidth,
  onInputChange,
}: SearchableMultiSelectDropdownProps) => {
  const [selectedIds, setSelectedIds] = useState<string[]>(
    Array.isArray(presentValue) ? presentValue : []
  );
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredOptions, setFilteredOptions] = useState<Option[]>(options);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setSelectedIds(presentValue || []);
  }, [presentValue]);

  // Update filtered options based on the search term
  useEffect(() => {
    if (searchTerm === "") {
      setFilteredOptions(options);
    } else {
      const filtered = options.filter((option) =>
        option.label.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredOptions(filtered);
    }
  }, [searchTerm, options]);

  const handleSelectOption = (option: Option) => {
    if (!isEditable) return;

    setSelectedIds((prev) => {
      const newSelected = prev.includes(option.id)
        ? prev.filter((id) => id !== option.id)
        : [...prev, option.id];

      onInputChange(newSelected); // Pass the updated state to parent
      return newSelected; // Update the state properly
    });
  };

  const handleRemoveOption = (optionId: string) => {
    if (!isEditable) return;

    setSelectedIds((prev) => {
      const updatedSelected = prev.filter((id) => id !== optionId);
      onInputChange(updatedSelected); // Pass updated state to parent
      return updatedSelected;
    });
  };

  const toggleDropdown = () => {
    if (isEditable) setIsOpen((prev) => !prev);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      searchInputRef.current?.focus();
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isOpen]);

  const getSelectedLabel = (id: string) => {
    const option = options.find((opt) => opt.id === id);
    return option ? option.label : "";
  };

  return (
    <div
      className={styles["input-container"]}
      ref={dropdownRef}
      style={{
        width: isFullWidth ? "100%" : "",
      }}
    >
      <div className={styles["label"]}>
        <label>
          {label}
          {isRequired && <span style={{ color: "red" }}> *</span>}
        </label>
      </div>
      <div className={styles.dropdown}>
        <div
          className={styles["dropdown-selected"]}
          onClick={toggleDropdown}
          style={{ background: !isEditable ? "#efefef" : "" }}
        >
          {selectedIds.length === 0 ? (
            <span className={styles.placeholder}>{placeholder}</span>
          ) : (
            selectedIds.map((id) => (
              <span
                key={id}
                className={styles["selected-option"]}
                onClick={(e) => {
                  e.stopPropagation();
                  handleRemoveOption(id);
                }}
              >
                {getSelectedLabel(id)} &times;
              </span>
            ))
          )}
        </div>

        {isOpen && (
          <div className={styles["dropdown-options-container"]}>
            {loading ? (
              <div className={styles["loading"]}>Loading...</div>
            ) : error ? (
              <div className={styles["error"]}>{error}</div>
            ) : (
              <>
                <input
                  ref={searchInputRef}
                  type="text"
                  className={styles["search-input"]}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder="Search..."
                />
                <div className={styles["dropdown-options"]}>
                  {filteredOptions.length > 0 ? (
                    filteredOptions.map((option) => (
                      <div
                        key={option.id}
                        className={`${styles["dropdown-option"]} ${
                          selectedIds.includes(option.id) ? styles.selected : ""
                        }`}
                        onClick={() => handleSelectOption(option)}
                      >
                        {option.label}
                      </div>
                    ))
                  ) : (
                    <div className={styles["no-options"]}>No options found</div>
                  )}
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchableMultiSelectDropdown;
