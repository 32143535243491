import React, { useState } from "react";
import { toast } from "react-toastify";

import LoginForm from "./Forms/PhoneForm/LoginForm";
import OtpForm from "./Forms/OtpForms/OtpForm";
import OtpExhausted from "./Forms/OtpForms/OtpExhausted";
import ErrorCommon from "pages/ErrorCommon";

import { sendOtp } from "services/login";
import { IAdminOtpLogin } from "interfaces/AuthInterfaces";

function IndexForm() {
  const [phone, setPhone] = useState("");
  const [activeState, setActiveState] = useState("login");
  const [responseData, setResponseData] = useState<IAdminOtpLogin>();

  const handleSendOtp = async (phoneN: string) => {
    const reqData = {
      phoneNumber: phoneN,
    };

    const result = await sendOtp(reqData);

    if (result && !result.isError) {
      setResponseData(result);
    } else {
      if (!result) setActiveState("error");
      toast.error(result?.reason);
    }
  };

  const setPhoneNumber = (val: string) => {
    val = "+91" + val;
    setPhone(val);
    handleSendOtp(val);
    setActiveState("otp");
  };

  const handleCurrentForm = () => {
    if (activeState === "login") return <LoginForm setPhone={setPhoneNumber} />;
    else if (activeState === "otp" && responseData)
      return (
        <OtpForm
          phone={phone}
          otpData={responseData}
          setFormState={setActiveState}
        />
      );
    else if (activeState === "otp-exhausted") return <OtpExhausted />;
    else if (activeState === "error") return <ErrorCommon />;
  };

  return <>{handleCurrentForm()}</>;
}

export default IndexForm;
