/*eslint-disable*/
import React from "react";
import { GridColDef } from "@mui/x-data-grid";
import {
  StyledBoolean,
  ValueWithCopy,
} from "components/Common Components/DataTable/DatatableConfig";

export const TeamListConfig: GridColDef[] = [
  {
    field: "name",
    headerName: "Team Name",
    flex: 1,
    disableColumnMenu: true,
    hideable: false,
  },
  {
    field: "team_id",
    headerName: "ID",
    flex: 2,
    disableColumnMenu: true,
    hideable: false,
    renderCell: (params: any) => {
      return ValueWithCopy(params.value);
    },
  },
  {
    field: "team_city",
    headerName: "Home City",
    flex: 1,
    disableColumnMenu: true,
    hideable: false,
  },
  {
    field: "is_active",
    headerName: "IS ACTIVE",
    sortable: true,
    flex: 0.5,
    disableColumnMenu: true,
    renderCell: (params: any) => {
      return StyledBoolean(params.value);
    },
  },
];
