import { HandleLogOut, getAccessToken } from "utils/Helper";

const error401Response = {
  isError: true,
  reason: "Your session has timed out! Please login again",
};

const POST = async (path: string, data: any, headers?: any) => {
  let response: any;
  let requestHeaders = new Headers();
  requestHeaders.append("Content-Type", "application/json");
  requestHeaders.append("Authorization", `Bearer ${getAccessToken()}`);

  try {
    // console.log("hitting the api with data: ", data); //------------------------------------->>
    response = await fetch(path, {
      method: "POST",
      headers: requestHeaders,
      body: JSON.stringify(data),
    });
    // console.log("hit complete with response: ", response); //------------------------------------->>
  } catch (error) {
    console.log(error);
    return;
  }
  if (response?.status === 401) {
    HandleLogOut();
    return error401Response;
  }
  let resultText = await response?.text();
  let result = resultText === "" ? {} : JSON.parse(resultText);
  // console.log("result: ", result); //------------------------------------->>
  return result;
};

const GET = async (path: string, params?: any) => {
  let response: any;
  let requestHeaders = new Headers();
  requestHeaders.append("Content-Type", "application/json");
  requestHeaders.append("Authorization", `Bearer ${getAccessToken()}`);

  const urlParams = new URLSearchParams(params);

  try {
    response = await fetch(path + "?" + urlParams, {
      method: "GET",
      headers: requestHeaders,
    });
  } catch (error) {
    console.log(error);
    return;
  }
  if (response?.status === 401) {
    HandleLogOut();
    return error401Response;
  }
  let resultText = await response.text();
  let result = resultText === "" ? {} : JSON.parse(resultText);
  return result;
};

const GET2 = async (path: string, params?: any) => {
  let response: any;
  let requestHeaders = new Headers();
  requestHeaders.append("Content-Type", "application/json");
  requestHeaders.append("Authorization", `Bearer ${getAccessToken()}`);

  try {
    response = await fetch(path, {
      method: "GET",
      headers: requestHeaders,
    });
  } catch (error) {
    console.log(error);
    return;
  }
  if (response?.status === 401) {
    HandleLogOut();
    return error401Response;
  }
  let resultText = await response.text();
  let result = resultText === "" ? {} : JSON.parse(resultText);
  return result;
};

const PUT = async (path: string, data: any, headers?: any) => {
  let response: any;
  let requestHeaders = new Headers();
  requestHeaders.append("Content-Type", "application/json");
  requestHeaders.append("Authorization", `Bearer ${getAccessToken()}`);

  try {
    response = await fetch(path, {
      method: "PUT",
      headers: requestHeaders,
      body: JSON.stringify(data),
    });
  } catch (error) {
    console.log(error);
    return;
  }
  if (response?.status === 401) {
    HandleLogOut();
    return error401Response;
  }
  let resultText = await response.text();
  let result = resultText === "" ? {} : JSON.parse(resultText);
  return result;
};

const PATCH = async (path: string, data: any, headers?: any) => {
  let response: any;
  let requestHeaders = new Headers();
  requestHeaders.append("Content-Type", "application/json");
  requestHeaders.append("Authorization", `Bearer ${getAccessToken()}`);

  try {
    response = await fetch(path, {
      method: "PATCH",
      headers: requestHeaders,
      body: JSON.stringify(data),
    });
  } catch (error) {
    console.log(error);
    return;
  }
  if (response?.status === 401) {
    HandleLogOut();
    return error401Response;
  }
  let resultText = await response.text();
  let result = resultText === "" ? {} : JSON.parse(resultText);
  return result;
};

export { POST, GET, GET2, PUT, PATCH };
