import { FieldType } from "components/Common Components/Form Fields/FieldAttributes";
import { GENDER, ISACTIVE } from "./ScorerConstants";

export const leftSideColConfig = (formConfig: any, formType?: string) => {
  const config = [
    [
      {
        type: FieldType.TEXT,
        label: "Full Name",
        name: "name",
        placeholder: "Enter name",
        disabled: formConfig.name.disabled,
        required: true,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.TEXT,
        label: "Email",
        name: "email",
        placeholder: "Enter email",
        disabled: formConfig.email.disabled,
        required: true,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
          pattern: {
            value: /^[a-z0-9]+@[a-z]+\.[a-z]{2,3}$/,
            message: "Please enter a valid email address!",
          },
        },
      },
    ],
    [
      {
        type: FieldType.TEXT,
        label: "Contact number",
        name: "phoneNumber",
        placeholder: "Enter contact number",
        disabled: formConfig.contactNumber.disabled,
        required: true,
        maxLength: 13,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
          pattern: {
            value: /^(\+91)[6-9]\d{9}$/gi,
            message: "Please enter a valid number along with country code. (+91 for India)",
          },
        },
      },
    ],
    [
      {
        type: FieldType.TEXT,
        label: "City",
        name: "city",
        placeholder: "Enter city",
        disabled: formConfig.city.disabled,
        required: true,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],

  ];
  return config;
};

export const rightSideColConfig = (formConfig: any, formName?: string) => {
  const config = [
    [
      {
        type: FieldType.FILE,
        label: "Upload Photo",
        name: "imageUrl",
        disabled: formConfig.uploadPhoto.disabled,
        required: true,
        formType: "Scorer",
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.RADIO,
        label: "isActive",
        name: "deleted",
        disabled: formConfig.isActive.disabled,
        required: true,
        options: ISACTIVE,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.RADIO,
        label: "Gender",
        name: "gender",
        disabled: formConfig.gender.disabled,
        required: true,
        options: GENDER,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
  ];
  return config;
};
