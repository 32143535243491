/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import styles from "./style.module.scss";
import { useNavigate } from "react-router-dom";

interface indexProps {
  formType: string;
  tournamentId: string | undefined;
}

const index = ({ formType, tournamentId }: indexProps) => {
  const navigate = useNavigate();

  const handleEditRedirection = () => {
    navigate(`/tournament-v3/edit/${tournamentId}`);
  };

  const handleMatchesRedirection = () => {
    navigate(`/matches/${tournamentId}`);
  };

  const headingMapper: any = {
    create: "Add Tournament",
    view: "View Tournament",
    edit: "Edit Tournament",
  };
  return (
    <div className={styles["page-heading-container"]}>
      <div className={styles["name"]}>{headingMapper[formType]}</div>

      <div className={styles["utility-button"]}>
        {formType === "view" && (
          <div
            className={styles["matches-button"]}
            onClick={handleMatchesRedirection}
          >
            Matches
          </div>
        )}

        {formType === "view" && (
          <div
            className={styles["edit-button"]}
            onClick={handleEditRedirection}
          >
            Edit
          </div>
        )}
      </div>
    </div>
  );
};

export default index;
