import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Divider } from "@mui/material";

import CancelTournamentModal from "../CancelTournamentModal";
import BasicModal from "components/Common Components/Modal/BasicModal";

import colors from "config/colorConfig";
import { logFirebaseEvent } from "config/firebase";
import { STATUS_LIST } from "utils/constants";
import MenuIcon from "assets/Menu.svg";

type Props = {
  selectedTournamentData: any;
};

export default function ViewTournamentMenu(props: Props) {
  const { selectedTournamentData } = props;
  const navigate = useNavigate();

  const menuButtonStyles = {
    borderRadius: "0.8rem",
    border: "0.1rem solid",
    borderColor: colors.grayThirty,
    backgroundColor: colors.white,
    padding: "1.2rem",
    width: "max-content",
    minWidth: "auto",
  };

  const kebabMenuStyles = {
    ".MuiMenuItem-root": {
      fontSize: "1.4rem",
      lineHeight: "2rem",
      fontWeight: 600,
    },
  };

  const menuList = [
    {
      label: "Edit Details",
      disabled:
        selectedTournamentData?.status === STATUS_LIST.CANCELLED ? true : false,
      color: colors.grayNinety,
      clickAction: () => {
        logFirebaseEvent({
          event_name: "tournament_edit",
          tournament_status: selectedTournamentData?.status,
          tournament_id: selectedTournamentData?.tournament_id,
        });
        navigate("/tournament/edit", {
          state: selectedTournamentData,
        });
      },
    },
    {
      label: "Cancel tournament",
      disabled:
        selectedTournamentData?.status === STATUS_LIST.SCHEDULED ||
        selectedTournamentData?.status === STATUS_LIST.ONGOING
          ? false
          : true,
      color: colors.redSixty,
      clickAction: () => setModalState(!modalState),
    },
  ];

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [modalState, setModalState] = useState(false);

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={menuButtonStyles}
        disabled={
          selectedTournamentData?.status === STATUS_LIST.CANCELLED
            ? true
            : false
        }
      >
        <img src={MenuIcon} alt="menu-icon" />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={kebabMenuStyles}
      >
        {menuList.map((item: any, key: number) => {
          return (
            <div key={key}>
              <MenuItem
                onClick={item.clickAction}
                disabled={item.disabled}
                sx={{ color: item.color }}
              >
                {item.label}
              </MenuItem>
              {key !== menuList.length - 1 ? <Divider /> : <></>}
            </div>
          );
        })}
      </Menu>
      <BasicModal modalState={modalState} handleModalState={setModalState}>
        <CancelTournamentModal
          modalState={modalState}
          handleModalState={setModalState}
          tournamentData={selectedTournamentData}
        />
      </BasicModal>
    </div>
  );
}
