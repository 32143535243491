import React from "react";

import SideDrawer from "components/Common Components/SideDrawer/SideDrawer";
import CreateTournamentForm from "components/TournamentsNew/CreateTournament/CreateTournamentForm";

interface CreateTournamentProps {
  formType: string;
  tournamentId?: string;
}

function CreateTournament({ formType, tournamentId }: CreateTournamentProps) {
  return (
    <>
      <SideDrawer>
        <div style={{ padding: "3.2rem" }}>
          <CreateTournamentForm
            formType={formType}
            tournamentId={tournamentId}
          />
        </div>
      </SideDrawer>
    </>
  );
}

export default CreateTournament;
