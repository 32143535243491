/* eslint-disable no-unused-vars */
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { Divider, Grid } from "@mui/material";
import { toast } from "react-toastify";

import FieldMapper from "components/Common Components/Form Fields/FormUtils/FieldMapper";
import FormButton from "components/Common Components/Buttons/FormButton";
import BasicModal from "components/Common Components/Modal/BasicModal";
import DiscardModal from "components/Common Components/Modal/DiscardModal";

import colors from "config/colorConfig";
import { leftSideColConfig, rightSideColConfig } from "../formConfig";
import { logFirebaseEvent } from "config/firebase";
import { createTeam } from "services/teams";
import styles from "../styles.module.css";
import BackdropLoader from "components/Common Components/Loaders/LoaderBackdrop";
import {
  getAllStateListMapped,
  getCitiesListMapped,
} from "components/Common Components/Location/helper";

import EntityDropDown from "components/Common Components/Dropdowns/EntityDropdown";

function CreateTeamForm() {
  const methods = useForm({
    criteriaMode: "firstError",
    mode: "onBlur",
    shouldFocusError: true,
  });
  const {
    setValue,
    handleSubmit,
    formState: { isValid, isDirty },
  } = methods;

  const navigate = useNavigate();

  const [modalState, setModalState] = useState(false);
  const [loaderState, setLoaderState] = useState(false);
  const [selectedState, setSelectedState] = useState<string>("");

  const onStateValueChange = useCallback(
    (item: any) => {
      setSelectedState(item.id);
      setValue("city", { id: " ", label: " " });
    },
    [setSelectedState]
  );

  const formSubmitHandler = async (formData: any) => {
    setLoaderState(true);
    formData["is_active"] = Number(formData["is_active"]);
    formData["city_id"] = formData["city_id"].id;
    delete formData["state"];
    delete formData["city"];

    const result = await createTeam(formData);
    console.log("create teams result: ", result);
    if (result && !result?.isError) {
      toast.success("Team created successfully!");
      navigate("/teams");
    } else {
      toast.error(result?.reason);
    }
    setLoaderState(false);
  };

  const formConfig = {
    teamName: {
      disabled: false,
    },
    teamDisplayName: {
      disabled: false,
    },
    tickerDisplayName: {
      disabled: false,
    },
    coachName: {
      disabled: false,
    },
    teamParent: {
      disabled: false,
    },
    uploadPhoto: {
      disabled: false,
    },
    isActive: {
      disabled: false,
    },
    facebook: {
      disabled: false,
    },
    instagram: {
      disabled: false,
    },
    linkedin: {
      disabled: false,
    },
    primaryColor: {
      disabled: false,
    },
    secondaryColor: {
      disabled: false,
    },
    state: {
      disabled: false,
      optionsFetcher: getAllStateListMapped,
      OptionsDisplayComp: EntityDropDown,
      onValueChange: onStateValueChange,
    },
    city: {
      disabled: false,
      optionsFetcher: getCitiesListMapped,
      OptionsDisplayComp: EntityDropDown,
      idParam: selectedState,
    },
  };

  return (
    <>
      <div className={styles["form-heading"]}>Add teams</div>

      <div className={styles["form-container"]}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(formSubmitHandler)}>
            <Grid container spacing={6}>
              <Grid item xs={12} md={6}>
                <FieldMapper
                  fieldsConfig={leftSideColConfig(formConfig, "create")}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FieldMapper
                  fieldsConfig={rightSideColConfig(formConfig, "create")}
                />
              </Grid>
            </Grid>

            <Divider sx={{ my: 3 }} />

            <Grid container item columnGap={3}>
              <FormButton
                type="button"
                fontColor={colors.redSixty}
                backgroundColor={colors.white}
                borderColor={colors.grayThirty}
                onClick={() =>
                  !isDirty ? navigate("/teams") : setModalState(!modalState)
                }
              >
                Discard
              </FormButton>

              <FormButton
                type="submit"
                fontColor={colors.white}
                backgroundColor={colors.grayNinety}
                disabledColor={colors.grayFifty}
                disabledBackground={colors.grayTwenty}
                disabled={!isValid}
              >
                Save team
              </FormButton>
            </Grid>
          </form>
        </FormProvider>
      </div>
      <BasicModal modalState={modalState} handleModalState={setModalState}>
        <DiscardModal
          modalState={modalState}
          handleModalState={setModalState}
        />
      </BasicModal>
      <BackdropLoader loaderState={loaderState} />
    </>
  );
}

export default CreateTeamForm;
