import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { toast } from "react-toastify";

import HeaderOptionsButton from "components/Common Components/Buttons/HeaderOptionsButton";
import DataTable from "components/Common Components/DataTable/Index";
import EmptyList from "components/Common Components/EmptyList";

import { createBulkPlayers, getPlayersListPaginated } from "services/players";
import { PlayerListConfig } from "./listConfig";
import { logFirebaseEvent } from "config/firebase";
import colors from "config/colorConfig";
import { PLAYER_PAGE_SIZE } from "utils/constants";
import styles from "./styles.module.css";
import { FieldType } from "components/Common Components/Form Fields/FieldAttributes";
import CSVDownloadComponent from "components/Common Components/CSVDownload/CSVDownloadComponent";
import CSVUploadComponent from "components/Common Components/CSVUpload/CSVUploadComponent";
import BackdropLoader from "components/Common Components/Loaders/LoaderBackdrop";

function PlayerList() {
  const navigate = useNavigate();

  const [dataList, setDataList] = useState([]);
  const [totalRows, setTotalRows] = useState(10);
  const [emptyListFlag, setEmptyListFlag] = useState(false);
  const [loaderState, setLoaderState] = useState(false);

  const [open, setOpen] = React.useState(false);
  const [downloadDialogOpen, setDownloadDialogOpen] = React.useState(false);
  const [csvLoader, setCSVLoader] = React.useState(false);
  const [csvDataList, setCSVDataList] = useState<{ [key: string]: string }[]>(
    []
  );
  const [myFiles, setMyFiles] = useState<File[]>([]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleDownloadDialogOpen = () => setDownloadDialogOpen(true);
  const handleDownloadDialogClose = () => setDownloadDialogOpen(false);

  const requiredColumns: any = {
    name: "name",
    short_name: "short_name",
    city: "city",
    dob: "dob",
    image_url: "image_url",
    is_active: "is_active",
    gender: "gender",
    mobile: "mobile",
    linkedin_handle: "linkedin_handle",
    facebook_handle: "facebook_handle",
    instagram_handle: "instagram_handle",
    idol: "idol",
  };

  const cricketRequiredColumns = [
    "cricket_cricketRole",
    "cricket_bowlingType",
    "cricket_battingHand",
    "cricket_bowlingHand",
    "cricket_teams",
  ];

  const footballRequiredColumns = [
    "football_footballRole",
    "football_primaryFoot",
    "football_teams",
  ];

  const badmintonRequiredColumns = [
    "badminton_primaryHand",
    "badminton_teams",
  ];
  
  const columns: any = {
    name: "name",
    short_name: "short_name",
    city: "city",
    city_id: "city_id",
    dob: "dob",
    image_url: "image_url",
    is_active: "is_active",
    gender: "gender",
    mobile: "mobile",
    linkedin_handle: "linkedin_handle",
    facebook_handle: "facebook_handle",
    instagram_handle: "instagram_handle",
    cricket_cricketRole: "cricket_cricketRole",
    cricket_bowlingType: "cricket_bowlingType",
    cricket_battingHand: "cricket_battingHand",
    cricket_bowlingHand: "cricket_bowlingHand",
    cricket_teams: "cricket_teams",
    football_footballRole: "football_footballRole",
    football_primaryFoot: "football_primaryFoot",
    football_teams: "football_teams",
    badminton_primaryHand: "badminton_primaryHand",
    badminton_teams: "badminton_teams",
  };

  const sampleRow: any = {
    name: "MSD",
    short_name: "MSD",
    city: "Gurugram",
    city_id: "123",
    dob: "09/03/1999",
    image_url: "https://img_url",
    is_active: 1,
    gender: "Male/Female/Other",
    mobile: "7589487485",
    linkedin_handle: "https://www.linkedin.com/in/msd",
    facebook_handle: "https://www.facebook.com/msd",
    instagram_handle: "https://www.instagram.com/msd",
    cricket_cricketRole:"BATSMAN/BOWLER/ALL_ROUNDER/WICKET_KEEPER",
    cricket_bowlingType: "fast/medium/slow/off-spin/leg-spin/unorthodox-spin",
    cricket_battingHand: "right-hand-batting/left-hand-batting",
    cricket_bowlingHand: "right-arm/left-arm",
    cricket_teams: "tb841803-bd83-4e9e-914f-2f11f62dd201",
    football_footballRole: "ATTACKER/MID_FIELDER/DEFENDER/GOALKEEPER",
    football_primaryFoot: "left-foot/right-foot",
    football_teams: "tb841803-bd83-4e9e-914f-2f11f62dd202",
    badminton_primaryHand: "left-hand/right-hand",
    badminton_teams: "tb841803-bd83-4e9e-914f-2f11f62dd230",
  };

  const getPlayersList = async (reqData: any) => {
    setLoaderState(true);
    let result = await getPlayersListPaginated(reqData);
    if (result && !result?.isError) {
      const playerList = result?.data?.list;
      if (!playerList?.length) {
        setLoaderState(false);
        setEmptyListFlag(true);
        return;
      }
      setDataList(playerList);
      setTotalRows(result?.data?.pagination?.totalRows);
    } else {
      toast.error(result?.reason);
    }
    setLoaderState(false);
  };

  const handleCSVDataList = async () => {
    if (csvDataList.length > 0) {
      const emptySportRows:any[] = [];
      csvDataList.forEach((row, index)=>{
        const cricketEmptyCols = cricketRequiredColumns.filter(
          (col) => row[col] === "" 
        );
        const footballEmptyCols = footballRequiredColumns.filter(
          (col) => row[col] === "" 
        );
        const badmintonEmptyCols = badmintonRequiredColumns.filter(
          (col) => row[col] === "" 
        );

        if(cricketEmptyCols.length && footballEmptyCols.length && badmintonEmptyCols.length){
          console.log("incomplete row found..",cricketEmptyCols,footballEmptyCols,badmintonEmptyCols)
          emptySportRows.push([true,index]);
        }
      });
      const incompleteRows = emptySportRows.filter((row)=> row[0] === true)
      if(incompleteRows.length){
        const rowIds = incompleteRows.map((row)=> +row[1] + 1).join(", ")
        const erroMsg = `please fill atleast one sport in row(s) ${rowIds}`;
        toast.error(erroMsg);
        return
      }

      try {
        setCSVLoader(true);
        const rejectedIndices = await createBulkPlayers(csvDataList);
        setCSVLoader(false);
        handleClose();
        toast.success("Successfully created!");
        if (rejectedIndices.length > 0) {
          toast.error(
            `Failed to create players at rows: ${rejectedIndices.join(", ")}`
          );
        }
      } catch (error: any) {
        toast.error(error);
      }
    }
  };

  const handleRowClick = (playerData: any) => {
    const playerId = playerData?.row?.player_id;
    logFirebaseEvent({
      event_name: "player_view",
      player_id: playerId,
    });
    navigate(`/player/view/${playerId}`);
  };

  const handleSortingAndPagination = (
    page: number,
    pageSize: number,
    sortKey: string,
    sortType: string
  ) => {
    const reqData: any = {};
    const sortOptions: any = {};

    if (sortKey) {
      sortOptions.key = sortKey;
      sortOptions.type = sortType.toUpperCase();

      reqData.sortby = sortOptions;
    }
    reqData.page = page;
    reqData.pageSize = pageSize;

    getPlayersList(reqData);
  };

  useEffect(() => {
    logFirebaseEvent({ event_name: "player_home" });
    getPlayersList({});
  }, []);

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <div className={styles["form-heading"]}>Players &nbsp;</div>
        <div>
          <HeaderOptionsButton
            fontColor={colors.white}
            backgroundColor={colors.grayNinety}
            onClick={() => {
              logFirebaseEvent({ event_name: "player_add" });
              navigate("/player/create");
            }}
          >
            Add Player +
          </HeaderOptionsButton>
          <HeaderOptionsButton
            fontColor={colors.white}
            backgroundColor={colors.grayNinety}
            onClick={handleOpen}
            data-testid="upload-tournament-btn"
          >
            Bulk Upload
          </HeaderOptionsButton>
          <HeaderOptionsButton
            fontColor={colors.white}
            backgroundColor={colors.grayNinety}
            onClick={handleDownloadDialogOpen}
            data-testid="upload-tournament-btn"
          >
            Bulk Download
          </HeaderOptionsButton>
        </div>
      </Box>
      {dataList?.length ? (
        <DataTable
          columns={PlayerListConfig}
          data={dataList}
          totalRows={totalRows}
          pageSize={PLAYER_PAGE_SIZE}
          onRowClick={handleRowClick}
          sortPaginationHandler={handleSortingAndPagination}
        />
      ) : (
        <></>
      )}
      {emptyListFlag ? <EmptyList message="No Player Created!" /> : <></>}
      <CSVUploadComponent
        open={open}
        handleClose={handleClose}
        csvLoader={csvLoader}
        setCSVDataList={setCSVDataList}
        requiredColumns={requiredColumns}
        handleCSVDataList={handleCSVDataList}
        setMyFiles={setMyFiles}
        myFiles={myFiles}
        integerColumnFields={["is_active"]}
        // canConvertToCamelCase={true}
        columns={columns}
        sampleRow={sampleRow}
        arrayColumns={["teams"]}
      />
      <CSVDownloadComponent
        open={downloadDialogOpen}
        handleDownloadDialogClose={handleDownloadDialogClose}
        fieldsMapperConfig={[
          {
            type: FieldType.NUMBER,
            label: "Total records",
            name: "limit",
            required: true,
            rules: {
              required: {
                value: true,
                message: "This field is mandatory.",
              },
            },
          },
          {
            type: FieldType.NUMBER,
            label: "Start from",
            name: "offset",
            required: true,
            rules: {
              required: {
                value: true,
                message: "This field is mandatory.",
              },
            },
          },
        ]}
        fileName={"player_list.csv"}
        getPaginatedAPI={getPlayersListPaginated}
      />
      <BackdropLoader loaderState={loaderState} />
    </>
  );
}

export default PlayerList;
