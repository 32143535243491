/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { toast } from "react-toastify";

import DataTable from "components/Common Components/DataTable/Index";
import HeaderOptionsButton from "components/Common Components/Buttons/HeaderOptionsButton";
import EmptyList from "components/Common Components/EmptyList";

import { MatchListConfig } from "./listConfig";
import colors from "config/colorConfig";
import { MATCH_PAGE_SIZE } from "utils/constants";
import { getMatchesListPaginated, getMatchesListNtpc } from "services/matches";
import styles from "./styles.module.css";
import BackdropLoader from "components/Common Components/Loaders/LoaderBackdrop";

function MatchList() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { tournamentId, sportsCode } = useParams();

  const [dataList, setDataList] = useState([]);
  const [totalRows, setTotalRows] = useState(10);
  const [emptyListFlag, setEmptyListFlag] = useState(false);
  const [loaderState, setLoaderState] = useState(false);

  const getMatchesList = async (reqData: any) => {
    setLoaderState(true);
    let result = await getMatchesListNtpc(sportsCode, "NTPC");
    if (result && !result?.isError) {
      const matchesList = result?.data?.matches;
      if (!matchesList?.length) {
        setEmptyListFlag(true);
        setLoaderState(false);
        return;
      }
      setDataList(matchesList);
      setTotalRows(result?.data?.pagination?.totalRows);
    } else {
      toast.error(result?.reason);
    }
    setLoaderState(false);
  };

  const handleRowClick = (matchData: any) => {
    const matchId = matchData?.row?.matchId;
    navigate(`/ntpc/view/${matchId}`);
  };

  const handleSortingAndPagination = (
    page: number,
    pageSize: number,
    sortKey: string,
    sortType: string
  ) => {
    let data: any = {};
    let sortOptions: any = {};

    if (sortKey) {
      sortOptions.key = sortKey;
      sortOptions.type = sortType.toUpperCase();

      data.sortby = sortOptions;
    }
    data.page = page;
    data.pageSize = pageSize;
    data.filter = {
      tournament_id: tournamentId,
    };
    getMatchesList(data);
  };

  useEffect(() => {
    const reqData = {
      page: 1,
      pageSize: MATCH_PAGE_SIZE,
      sortby: {
        key: "match_type",
        type: "ASC",
      },
      filter: {
        tournament_id: tournamentId,
      },
    };
    getMatchesList(reqData);
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <div
          className={styles["form-heading"]}
          data-testid="match-index-heading"
        >
          NTPC Matches &nbsp;
          <span className={styles["tournament_name"]}>
            {`(${state?.tournament_name})`}
          </span>
        </div>
        <div>
          <HeaderOptionsButton
            fontColor={colors.white}
            backgroundColor={colors.grayNinety}
            onClick={() => {
              navigate("/ntpc/create/");
            }}
          >
            Add Match +
          </HeaderOptionsButton>
        </div>
      </Box>
      {dataList?.length > 0 ? (
        <DataTable
          columns={MatchListConfig}
          data={dataList}
          totalRows={totalRows}
          pageSize={MATCH_PAGE_SIZE}
          onRowClick={handleRowClick}
          sortPaginationHandler={handleSortingAndPagination}
        />
      ) : (
        <></>
      )}

      {emptyListFlag ? <EmptyList message="No Matches Created!" /> : <></>}

      <BackdropLoader loaderState={loaderState} />
    </>
  );
}

export default MatchList;
