import React from "react";

import SideDrawer from "components/Common Components/SideDrawer/SideDrawer";
import CreateTournamentForm from "components/Tournaments/CreateTournament/CreateTournamentForm";

function CreateTournament() {
  return (
    <>
      <SideDrawer>
        <div style={{ padding: "3.2rem" }}>
          <CreateTournamentForm />
        </div>
      </SideDrawer>
    </>
  );
}

export default CreateTournament;
