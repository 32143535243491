import React from "react";
import { Box } from "@mui/material";

import SideDrawer from "components/Common Components/SideDrawer/SideDrawer";
import ErrorImage from "assets/404.svg";

function Error404() {
  return (
    <>
      <SideDrawer>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <img src={ErrorImage} alt="error" width="30%" />
          {/* <div style={{ fontSize: "13rem", fontWeight: "600" }}>404</div> */}

          <div style={{ fontSize: "5rem" }}>Page Not Found!</div>
        </Box>
      </SideDrawer>
    </>
  );
}

export default Error404;
