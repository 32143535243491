import React from "react";

import SideDrawer from "components/Common Components/SideDrawer/SideDrawer";
import EditPlayerForm from "components/Players/EditPlayer/EditPlayerForm";

function EditPlayer() {
  return (
    <>
      <SideDrawer>
        <div style={{ padding: "3.2rem" }}>
          <EditPlayerForm />
        </div>
      </SideDrawer>
    </>
  );
}

export default EditPlayer;
