import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { toast } from "react-toastify";

import DataTable from "components/Common Components/DataTable/Index";
import EmptyList from "components/Common Components/EmptyList";

import { getMatchListByScorerId } from "services/scorers";
import { MatchListConfig } from "./listConfig";
import { MATCH_PAGE_SIZE } from "utils/constants";
import styles from "./styles.module.css";
import BackdropLoader from "components/Common Components/Loaders/LoaderBackdrop";

function MatchListByScorer() {
  const navigate = useNavigate();
  const { scorerId } = useParams();

  const [dataList, setDataList] = useState([]);
  const [totalRows, setTotalRows] = useState(10);
  const [emptyListFlag, setEmptyListFlag] = useState(false);
  const [loaderState, setLoaderState] = useState(false)

  const getMatchesList = async (reqData: any) => {
    setLoaderState(true)
    if (scorerId) {
      const result = await getMatchListByScorerId(scorerId, reqData);
      if (result && !result?.isError) {
        const matchesList = result?.data?.list;
        if (!matchesList?.length) {
          setLoaderState(false)
          setEmptyListFlag(true);
          return;
        }
        setDataList(matchesList);
        setTotalRows(result?.data?.pagination?.totalRows);
      } else {
        toast.error(result?.reason);
      }
    } else {
      toast.error("There was some error! Please try again");
    }
    setLoaderState(false)
  };

  const handleRowClick = (matchData: any) => {
    const matchId = matchData?.row?.match_id;
    navigate(`/match/view/${matchId}`);
  };

  const handleSortingAndPagination = (
    page: number,
    pageSize: number,
    sortKey: string,
    sortType: string
  ) => {
    let data: any = {};
    let sortOptions: any = {};

    if (sortKey) {
      sortOptions.key = sortKey;
      sortOptions.type = sortType.toUpperCase();

      data.sortby = sortOptions;
    }
    data.page = page;
    data.pageSize = pageSize;
    getMatchesList(data);
  };

  useEffect(() => {
    const reqData = {
      page: 1,
      pageSize: MATCH_PAGE_SIZE,
      sortby: {
        key: "match_type",
        type: "ASC",
      },
    };
    getMatchesList(reqData);
  }, []);
  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <div
          className={styles["form-heading"]}
          data-testid="match-index-heading"
        >
          Matches &nbsp;
        </div>
      </Box>
      {dataList?.length > 0 ? (
        <DataTable
          columns={MatchListConfig}
          data={dataList}
          totalRows={totalRows}
          pageSize={MATCH_PAGE_SIZE}
          onRowClick={handleRowClick}
          sortPaginationHandler={handleSortingAndPagination}
        />
      ) : (
        <></>
      )}
      {emptyListFlag ? <EmptyList message="No matches for this scorer!" /> : <></>}
      <BackdropLoader loaderState={loaderState} />
    </>
  );
}

export default MatchListByScorer;
