import React, {useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { Box, Divider, Grid } from "@mui/material";
import { toast } from "react-toastify";

import FieldMapper from "components/Common Components/Form Fields/FormUtils/FieldMapper";
import FormButton from "components/Common Components/Buttons/FormButton";

import { leftSideColConfig, rightSideColConfig } from "../formConfig";
import { logFirebaseEvent } from "config/firebase";
import colors from "config/colorConfig";
import {
  getScorerDetailsById,
  formatGetScorerData,
  editScorer,
} from "services/scorers";
import styles from "../styles.module.css";
import BackdropLoader from "components/Common Components/Loaders/LoaderBackdrop";

function EditScorerForm() {
  const methods = useForm({
    criteriaMode: "firstError",
    mode: "onBlur",
    shouldFocusError: true,
  });

  const {
    setValue,
    handleSubmit,
    formState: { isDirty },
  } = methods;

  const navigate = useNavigate();
  const { state } = useLocation();
  const { scorerId } = useParams();

  const [loaderState, setLoaderState] = useState(false)

  const fetchScorerData = async () => {
    setLoaderState(true)
    if (scorerId) {
      let result = await getScorerDetailsById(scorerId);
      if (result && !result?.isError && result?.data.length > 0) {
        let formattedData = formatGetScorerData(result?.data[0]);
        setPageData(formattedData);
      } else {
        toast.error(result?.reason);
      }
    } else {
      toast.error("There was some error! Please try again");
    }
    setLoaderState(false)
  };

  const setPageData = (data: any) => {
    for (const [key, value] of Object.entries(data)) {
      value && setValue(key, value);
    }
    setValue("deleted", data?.deleted);
  };
  const initializePageData = () => {
    if (!state) {
      fetchScorerData();
    } else {
      const { scorerData } = state;
      setPageData(scorerData);
    }
  };
  const formSubmitHandler = async (formData: any) => {
    setLoaderState(true)
    formData["deleted"] = Number(formData["deleted"]);
    const result = await editScorer(formData);
    if (result && !result?.isError) {
      const scorerId = result?.data?.scorerId;
      logFirebaseEvent({
        event_name: "scorer_edit_save",
        scorer_id: scorerId,
      });
      toast.success("Updated successfully!");
      navigate(-1);
    } else {
      toast.error(result?.reason);
    }
    setLoaderState(false)
  };
  useEffect(() => {
    initializePageData();
  }, []);

  const formConfig = {
    name: {
      disabled: false,
    },
    email: {
      disabled: false,
    },
    contactNumber: {
      disabled: false,
    },
    city: {
      disabled: false,
    },
    gender: {
      disabled: false,
    },
    uploadPhoto: {
      disabled: false,
    },
    isActive: {
      disabled: false,
    },
  };
  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <div className={styles["form-heading"]}>
          <Box display="flex" alignItems="center">
            <span> Edit Scorer</span>
          </Box>
        </div>
      </Box>

      <div className={styles["form-container"]}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(formSubmitHandler)}>
            <Grid container columnSpacing={6}>
              <Grid item xs={12} md={6}>
                <FieldMapper
                  fieldsConfig={leftSideColConfig(formConfig, "view")}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FieldMapper
                  fieldsConfig={rightSideColConfig(formConfig, "view")}
                />
              </Grid>
            </Grid>

            <Divider sx={{ my: 3 }} />

            <Grid container item columnGap={3}>
              <FormButton
                type="button"
                fontColor={colors.redSixty}
                backgroundColor={colors.white}
                borderColor={colors.grayThirty}
                onClick={() => navigate(-1)}
              >
                Discard
              </FormButton>

              <FormButton
                type="submit"
                fontColor={colors.white}
                backgroundColor={colors.grayNinety}
                disabledColor={colors.grayFifty}
                disabledBackground={colors.grayTwenty}
                disabled={!isDirty}
              >
                Save changes
              </FormButton>
            </Grid>
          </form>
        </FormProvider>
      </div>
      <BackdropLoader loaderState={loaderState} />
    </>
  );
}

export default EditScorerForm;
