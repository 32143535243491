// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getAdminId } from "utils/Helper";

let firebaseConfig = {};
//TODO - change when production config is used
if (process.env.REACT_APP_ENV === "production") {
  firebaseConfig = {
    apiKey: "AIzaSyCYnET4TEOc2NqIu098cNueaPGmvSr0H_c",
    authDomain: "http://pavilion-admin-stage.firebaseapp.com/",
    projectId: "ppavilion-admin-stage",
    storageBucket: "http://pavilion-admin-stage.appspot.com/",
    messagingSenderId: "704663028417",
    appId: "1:704663028417:web:2ce28d4a0ee7b879690a40",
    measurementId: "G-FRH5H3FBJB",
  };
} else {
  firebaseConfig = {
    apiKey: "AIzaSyCYnET4TEOc2NqIu098cNueaPGmvSr0H_c",
    authDomain: "http://pavilion-admin-stage.firebaseapp.com/",
    projectId: "ppavilion-admin-stage",
    storageBucket: "http://pavilion-admin-stage.appspot.com/",
    messagingSenderId: "704663028417",
    appId: "1:704663028417:web:2ce28d4a0ee7b879690a40",
    measurementId: "G-FRH5H3FBJB",
  };
}

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Analytics and get a reference to the service
const analytics = getAnalytics(app);

interface firebaseEventProps {
  event_name: string;
  event_key?: string;
  match_id?: string | number;
  tournament_id?: string | number;
  match_status?: string;
  tournament_status?: string;
  source_page?: string | number;
  player_id?: string | number;
  team_id?: string;
  scorer_id?: string;
}

export const logFirebaseEvent = ({
  event_name,
  event_key,
  tournament_id,
  tournament_status,
  match_id,
  match_status,
  player_id,
  team_id,
  scorer_id,
}: firebaseEventProps) => {
  const bundle: any = {
    event_key: event_name,
    event_timestamp: Date.now(),
    event_admin_id: getAdminId(),
  };

  if (event_key) bundle.event_key = event_key;
  if (tournament_id) bundle.event_tournament_id = tournament_id;
  if (tournament_status) bundle.event_tournament_status = tournament_status;
  if (match_id) bundle.event_match_id = match_id;
  if (match_status) bundle.event_match_status = match_status;
  if (team_id) bundle.event_team_id = team_id;
  if (player_id) bundle.event_player_id = player_id;
  if (scorer_id) bundle.event_scorer_id = scorer_id;

  console.warn({ event_name, bundle, logEvent });
  logEvent(analytics, event_name, bundle); // initializeAnalytics
};
