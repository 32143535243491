/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { toast } from "react-toastify";

import FieldMapper from "components/Common Components/Form Fields/FormUtils/FieldMapper";
import FormButton from "components/Common Components/Buttons/FormButton";

import {
  leftSideColConfig,
  rightSideColConfig,
  sportsSectionConfig,
  cricketColConfig,
  badmintonColConfig,
  footballColConfig,
} from "../formConfig";

import colors from "config/colorConfig";

import {
  getGroundDetailsById,
  formatGetGroundData,
  formatGroundData,
  editGround,
} from "services/grounds";

import styles from "../styles.module.css";

import BackdropLoader from "components/Common Components/Loaders/LoaderBackdrop";

import {
  getAllSportsListMapped,
  getAllAmenitiesListMapped,
  getCricketListMapped,
  getBadmintonListMapped,
  getFootballListMapped,
} from "components/Common Components/Sports/helper";

import {
  getAllStateListMapped,
  getCitiesListMapped,
} from "components/Common Components/Location/helper";

import EntityDropDown from "components/Common Components/Dropdowns/EntityDropdown";

function EditGroundForm() {
  const methods = useForm({
    criteriaMode: "firstError",
    mode: "onBlur",
    shouldFocusError: true,
  });

  const {
    setValue,
    handleSubmit,
    formState: { isDirty },
  } = methods;

  // const watchSports = watch("sports");
  // const listSportAvailable = watchSports?.map((sport: any) => sport?.value);

  const navigate = useNavigate();
  const { groundId } = useParams();

  const [loaderState, setLoaderState] = useState(false);

  const [selectedState, setSelectedState] = useState<string>("");
  const [selectedSports, setSelectedSports] = useState<string[]>([]);

  const [selectedAmenities, setSelectedAmenities] = useState<string[]>([]);
  const [selectedCricket, setSelectedCricket] = useState<string[]>([]);
  const [selectedBadminton, setSelectedBadminton] = useState<string[]>([]);
  const [selectedFootball, setSelectedFootball] = useState<string[]>([]);

  const onStateValueChange = useCallback(
    (item: any) => {
      setSelectedState(item.id);
      setValue("city", { id: " ", label: " " });
    },
    [setSelectedState]
  );

  const onAmenitiesValueChange = useCallback(
    (values: any) => {
      setSelectedAmenities(values.map((val: any) => val.id));
    },
    [setSelectedAmenities]
  );

  const onCricketValueChange = useCallback(
    (values: any) => {
      setSelectedCricket(values.map((val: any) => val.id));
    },
    [setSelectedCricket]
  );

  const onBadmintonValueChange = useCallback(
    (values: any) => {
      setSelectedBadminton(values.map((val: any) => val.id));
    },
    [setSelectedBadminton]
  );

  const onFootballValueChange = useCallback(
    (values: any) => {
      setSelectedFootball(values.map((val: any) => val.id));
    },
    [setSelectedFootball]
  );

  const onSportsValueChange = (values: any) => {
    setSelectedSports(values.map((val: any) => (val.id ? val.id : val.value)));
  };

  const fetchGroundData = async () => {
    setLoaderState(true);
    if (groundId) {
      let result = await getGroundDetailsById(groundId);
      if (result && !result?.isError) {
        let formattedData = formatGetGroundData(result?.data);
        setPageData(formattedData);
      } else {
        toast.error(result?.reason);
      }
    } else {
      toast.error("There was some error! Please try again");
    }
    setLoaderState(false);
  };

  const setPageData = (data: any) => {
    data?.sports?.forEach((sport: any, index: number) => {
      setSelectedSports((prev) => [...prev, sport.value]);
    });

    for (const [key, value] of Object.entries(data)) {
      value && setValue(key, value);
    }
    setSelectedState(data?.stateId);
    setValue("state", { id: data?.stateId, label: data?.state });
    setValue("city_id", { id: data?.cityId, label: data?.city });
    setValue("isActive", data?.isActive);
  };

  const initializePageData = () => {
    fetchGroundData();
  };

  const formSubmitHandler = async (formData: any) => {
    setLoaderState(true);
    const formattedGroundData = formatGroundData(formData);
    formattedGroundData.venueDetails["venueId"] = groundId;

    const result = await editGround(formattedGroundData);
    if (result && !result?.isError) {
      toast.success("Updated successfully!");
      navigate(-1);
    } else {
      toast.error(result?.reason);
    }
    setLoaderState(false);
  };

  useEffect(() => {
    initializePageData();
  }, []);

  const formConfig = {
    uploadPhoto: {
      disabled: false,
    },
    groundName: {
      disabled: false,
    },
    groundAddress: {
      disabled: false,
    },
    googleMapsLink: {
      disabled: false,
    },
    groundOwner: {
      disabled: false,
    },
    groundContact: {
      disabled: false,
    },
    groundSports: {
      disabled: false,
    },
    cricketPitchType: {
      disabled: false,
    },
    footballPitchType: {
      disabled: false,
    },
    badmintonCourtType: {
      disabled: false,
    },
    groundImages: {
      disabled: false,
    },
    isActive: {
      disabled: false,
    },
    state: {
      disabled: false,
      optionsFetcher: getAllStateListMapped,
      OptionsDisplayComp: EntityDropDown,
      onValueChange: onStateValueChange,
    },
    city: {
      disabled: false,
      optionsFetcher: getCitiesListMapped,
      OptionsDisplayComp: EntityDropDown,
      idParam: selectedState,
    },
    amenities: {
      disabled: false,
      optionsFetcher: getAllAmenitiesListMapped,
      OptionsDisplayComp: EntityDropDown,
      populateOptions: true,
      onValueChange: onAmenitiesValueChange,
    },
    sports: {
      disabled: false,
      optionsFetcher: getAllSportsListMapped,
      OptionsDisplayComp: EntityDropDown,
      populateOptions: true,
      onValueChange: onSportsValueChange,
    },
  };

  const cricketFormConfig = {
    sportsCricket: {
      disabled: false,
      optionsFetcher: getCricketListMapped,
      OptionsDisplayComp: EntityDropDown,
      populateOptions: true,
      onValueChange: onCricketValueChange,
    },
  };

  const badmintonFormConfig = {
    sportsBadminton: {
      disabled: false,
      optionsFetcher: getBadmintonListMapped,
      OptionsDisplayComp: EntityDropDown,
      populateOptions: true,
      onValueChange: onBadmintonValueChange,
    },
  };

  const footballFormConfig = {
    sportsFootball: {
      disabled: false,
      optionsFetcher: getFootballListMapped,
      OptionsDisplayComp: EntityDropDown,
      populateOptions: true,
      onValueChange: onFootballValueChange,
    },
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <div className={styles["form-heading"]}>
          <Box display="flex" alignItems="center">
            <span> Edit Ground</span>
          </Box>
        </div>
      </Box>

      <div className={styles["form-container"]}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(formSubmitHandler)}>
            <Grid container columnSpacing={6}>
              <Grid item xs={12} md={6}>
                <FieldMapper
                  fieldsConfig={leftSideColConfig(formConfig, "create")}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FieldMapper
                  fieldsConfig={rightSideColConfig(formConfig, "create")}
                />
              </Grid>
            </Grid>

            <Divider sx={{ my: 3 }} />

            <Grid container columnSpacing={6}>
              <Grid item xs={12} md={8}>
                <FieldMapper
                  fieldsConfig={sportsSectionConfig(formConfig, "create")}
                />
              </Grid>
              {selectedSports.includes("CRICKET") && (
                <Grid item xs={12} md={8}>
                  <Typography sx={{ mt: 1, fontSize: 16, color: "blue" }}>
                    1. CRICKET
                  </Typography>
                  <FieldMapper
                    fieldsConfig={cricketColConfig(cricketFormConfig, "create")}
                  />
                </Grid>
              )}
              {selectedSports.includes("FOOTBALL") && (
                <Grid item xs={12} md={8}>
                  <Typography sx={{ mt: 2, fontSize: 16, color: "blue" }}>
                    2. FOOTBALL
                  </Typography>
                  <FieldMapper
                    fieldsConfig={footballColConfig(
                      footballFormConfig,
                      "create"
                    )}
                  />
                </Grid>
              )}
              {selectedSports.includes("BADMINTON") && (
                <Grid item xs={12} md={8}>
                  <Typography sx={{ mt: 2, fontSize: 16, color: "blue" }}>
                    3. BADMINTON
                  </Typography>
                  <FieldMapper
                    fieldsConfig={badmintonColConfig(
                      badmintonFormConfig,
                      "create"
                    )}
                  />
                </Grid>
              )}
            </Grid>

            <Divider sx={{ mt: 2, mb: 0 }} />

            <Grid container item columnGap={3}>
              <FormButton
                type="button"
                fontColor={colors.redSixty}
                backgroundColor={colors.white}
                borderColor={colors.grayThirty}
                onClick={() => navigate(-1)}
              >
                Discard
              </FormButton>

              <FormButton
                type="submit"
                fontColor={colors.white}
                backgroundColor={colors.grayNinety}
                disabledColor={colors.grayFifty}
                disabledBackground={colors.grayTwenty}
                disabled={!isDirty}
              >
                Save changes
              </FormButton>
            </Grid>
          </form>
        </FormProvider>
      </div>
      <BackdropLoader loaderState={loaderState} />
    </>
  );
}

export default EditGroundForm;
