/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// components
// import SportSelection from "./SportSelection";
import TournamentInputs from "./TournamentInputs";
import BackdropLoader from "components/Common Components/Loaders/LoaderBackdrop";

// services
// import { data } from "./config";
// import { viewData } from "./viewConfig";
// import { editData } from "./editConfig";

import {
  getTournamentConfig,
  getTournamentConfigById,
} from "services/tournamentsNew";

interface TournamentConfig {
  tournamentId?: string;
}

interface CreateTournamentFormProps {
  formType: string;
  tournamentId?: string;
}

function CreateTournamentForm({ formType }: CreateTournamentFormProps) {
  const { id: tournamentId } = useParams();
  const [selectedSport, setSelectedSport] = useState<string | null>(null);
  const [configData, setConfigData] = useState<any>();

  const [loading, setLoading] = useState(false);

  const getTournementConfigDetails = async ({
    tournamentId,
  }: TournamentConfig) => {
    setLoading(true);
    if (tournamentId) {
      const result = await getTournamentConfigById(tournamentId);
      setConfigData(result?.data?.data);
      setLoading(false);
    } else {
      setLoading(true);
      const result = await getTournamentConfig();
      setConfigData(result?.data?.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getTournementConfigDetails({ tournamentId });
  }, [formType, tournamentId, selectedSport]);

  // if (formType === "create" && !selectedSport) {
  //   return (
  //     <SportSelection
  //       selectedSport={selectedSport}
  //       setSelectedSports={setSelectedSport}
  //     />
  //   );
  // }

  if (loading || !configData) {
    return <BackdropLoader loaderState={loading} />;
  }

  return (
    <TournamentInputs
      // data={
      //   formType === "create" ? data : formType === "view" ? viewData : editData
      // }
      data={configData}
      formType={formType}
      tournamentId={tournamentId}
      selectedSport={selectedSport}
    />
  );
}

export default CreateTournamentForm;
