import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles/createPalette" {
  interface Palette {
    gray10?: Palette["primary"];
  }
  interface PaletteOptions {
    gray10?: PaletteOptions["primary"];
  }
  interface Palette {
    gray30?: Palette["primary"];
  }
  interface PaletteOptions {
    gray30?: PaletteOptions["primary"];
  }
  interface Palette {
    gray50?: Palette["primary"];
  }
  interface PaletteOptions {
    gray50?: PaletteOptions["primary"];
  }
  interface Palette {
    gray60?: Palette["primary"];
  }
  interface PaletteOptions {
    gray60?: PaletteOptions["primary"];
  }
  interface Palette {
    gray70?: Palette["primary"];
  }
  interface PaletteOptions {
    gray70?: PaletteOptions["primary"];
  }
  interface Palette {
    gray90?: Palette["primary"];
  }
  interface PaletteOptions {
    gray90?: PaletteOptions["primary"];
  }
}

const theme = createTheme({
  palette: {
    gray10: {
      main: "#F5F5F5",
    },
    gray30: {
      main: "#E3E3E3",
    },
    gray50: {
      main: "#B0B0B0",
    },
    gray60: {
      main: "#757575",
    },
    gray70: {
      main: "#545454",
    },
    gray90: {
      main: "#262626",
    },
  },
  typography: {
    fontSize: 10,
    fontFamily:"WorkSans"
  },
});

export default theme;
