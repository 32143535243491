import { FieldType } from "components/Common Components/Form Fields/FieldAttributes";
import { ISACTIVE } from "./GroundConstants";

export const leftSideColConfig = (formConfig: any, formType?: string) => {
  const config = [
    //
    [
      {
        type: FieldType.TEXT,
        label: "Ground Name",
        name: "name",
        placeholder: "Enter ground name",
        disabled: formConfig.groundName.disabled,
        required: true,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],

    [
      {
        type: FieldType.TEXT,
        label: "Ground Address",
        name: "address",
        placeholder: "Enter ground address",
        disabled: formConfig.groundAddress.disabled,
        required: true,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],

    [
      {
        type: FieldType.TEXT,
        label: "Google Maps Link",
        name: "mapUrl",
        placeholder: "Enter ground address",
        disabled: formConfig.googleMapsLink.disabled,
        required: false,
        rules: {
          required: {
            value: false,
            message: "This field is mandatory.",
          },
        },
      },
    ],

    [
      {
        type: formType !== "view" ? FieldType.AUTOCOMPLETE : FieldType.TEXT,
        label: "State",
        name: "state",
        placeholder: "Enter State",
        disabled: formConfig?.state?.disabled || false,
        optionsFetcher: formConfig?.state?.optionsFetcher,
        OptionsDisplayComp: formConfig?.state?.OptionsDisplayComp,
        onValueChange: formConfig?.state?.onValueChange,
        required: true,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: formType !== "view" ? FieldType.AUTOCOMPLETELIVE : FieldType.TEXT,
        label: "City",
        name: "city_id",
        placeholder: "Enter city",
        disabled: formConfig.city.disabled,
        optionsFetcher: formConfig?.city?.optionsFetcher,
        OptionsDisplayComp: formConfig?.city?.OptionsDisplayComp,
        idParam: formConfig?.city?.idParam,
        required: true,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
  ];
  return config;
};

export const rightSideColConfig = (formConfig: any, formName?: string) => {
  const config = [
    [
      {
        type: FieldType.FILE,
        label: "Upload Photo",
        name: "imageUrl",
        disabled: formConfig.uploadPhoto.disabled,
        required: false,
        formType: "Scorer",
        rules: {
          required: {
            value: false,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.TEXT,
        label: "Owner Name",
        name: "ownerName",
        placeholder: "Enter Owner Name",
        disabled: formConfig.groundOwner.disabled,
        required: true,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.TEXT,
        label: "Owner Contact",
        name: "ownerContact",
        placeholder: "Enter Owner Contact",
        disabled: formConfig.groundContact.disabled,
        required: true,
        maxLength: 13,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
          pattern: {
            value: /^(\+91)[6-9]\d{9}$/gi,
            message:
              "Please enter a valid number along with country code. (+91 for India)",
          },
        },
      },
    ],
    [
      {
        type: FieldType.MULTISELECTLIVE,
        label: "Select Ground Amenities",
        name: "groundAmenities",
        placeholder: "select ground amenities",
        disabled: formConfig?.amenities?.disabled,
        optionsFetcher: formConfig?.amenities?.optionsFetcher,
        OptionsDisplayComp: formConfig?.amenities?.OptionsDisplayComp,
        populateOptions: formConfig?.amenities?.populateOptions || false,
        onValueChange: formConfig?.amenities?.onValueChange,
        required: true,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.RADIO,
        label: "isActive",
        name: "isActive",
        disabled: formConfig.isActive.disabled,
        required: true,
        options: ISACTIVE,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
  ];
  return config;
};

export const sportsSectionConfig = (formConfig: any, formName?: string) => {
  const config = [
    [
      {
        type: FieldType.MULTISELECTLIVE,
        label: "Select sports",
        name: "sports",
        placeholder: "select sports",
        disabled: formConfig?.sports?.disabled,
        optionsFetcher: formConfig?.sports?.optionsFetcher,
        OptionsDisplayComp: formConfig?.sports?.OptionsDisplayComp,
        populateOptions: formConfig?.sports?.populateOptions || false,
        onValueChange: formConfig?.sports?.onValueChange,
        required: true,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
  ];

  return config;
};

export const cricketColConfig = (formConfig: any, formName?: string) => {
  const config = [
    [
      {
        type: FieldType.MULTISELECTLIVE,
        label: "Select cricket pitches",
        name: "cricketList",
        placeholder: "select cricket pitches",
        disabled: formConfig?.sportsCricket?.disabled,
        optionsFetcher: formConfig?.sportsCricket?.optionsFetcher,
        OptionsDisplayComp: formConfig?.sportsCricket?.OptionsDisplayComp,
        populateOptions: formConfig?.sportsCricket?.populateOptions || false,
        onValueChange: formConfig?.sportsCricket?.onValueChange,
        required: true,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
  ];

  return config;
};

export const badmintonColConfig = (formConfig: any, formName?: string) => {
  const config = [
    [
      {
        type: FieldType.MULTISELECTLIVE,
        label: "Select badminton pitches",
        name: "badmintonList",
        placeholder: "select badminton pitches",
        disabled: formConfig?.sportsBadminton?.disabled,
        optionsFetcher: formConfig?.sportsBadminton?.optionsFetcher,
        OptionsDisplayComp: formConfig?.sportsBadminton?.OptionsDisplayComp,
        populateOptions: formConfig?.sportsBadminton?.populateOptions || false,
        onValueChange: formConfig?.sportsBadminton?.onValueChange,
        required: true,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
  ];

  return config;
};

export const footballColConfig = (formConfig: any, formName?: string) => {
  const config = [
    [
      {
        type: FieldType.MULTISELECTLIVE,
        label: "Select football pitches",
        name: "footballList",
        placeholder: "select football pitches",
        disabled: formConfig?.sportsFootball?.disabled,
        optionsFetcher: formConfig?.sportsFootball?.optionsFetcher,
        OptionsDisplayComp: formConfig?.sportsFootball?.OptionsDisplayComp,
        populateOptions: formConfig?.sportsFootball?.populateOptions || false,
        onValueChange: formConfig?.sportsFootball?.onValueChange,
        required: true,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
  ];

  return config;
};
