import React from "react";

import SideDrawer from "components/Common Components/SideDrawer/SideDrawer";
import GroundList from "components/Grounds/Index/GroundList";

function ScorerIndex() {
  return (
    <>
      <SideDrawer>
        <div style={{ padding: "3.2rem" }}>
          <GroundList />
        </div>
      </SideDrawer>
    </>
  );
}

export default ScorerIndex;
