import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  outline: 0,
  borderRadius: "1.2rem",
  padding: "3.2rem 6.4rem",
};

type modalProps = {
  children: React.ReactNode;
  modalState: boolean;
  handleModalState: Function;
};

export default function BasicModal({
  children,
  modalState,
  handleModalState,
}: modalProps) {
  return (
    <>
      <Modal
        open={modalState}
        onClose={() => handleModalState(!modalState)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>{children}</Box>
      </Modal>
    </>
  );
}
