/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";

import { RenderField } from "../RenderField";
import BackdropLoader from "components/Common Components/Loaders/LoaderBackdrop";

import { getVenueListPaginated } from "services/tournamentsNew";
import styles from "./styles.module.scss";

import FormHeader from "./FormHeader";

// services
import { createTournament } from "services/tournamentsNew";
import { useNavigate } from "react-router-dom";

interface TournamentInputsProps {
  data: any;
  formType: string;
  tournamentId?: string;
  selectedSport?: string | null;
}

const TournamentInputs = ({
  data,
  formType,
  tournamentId,
  selectedSport,
}: TournamentInputsProps) => {
  const navigate = useNavigate();
  const [dropdownOptions, setDropdownOptions] = useState<{ venue: any[] }>({
    venue: [],
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const [pageLoading, setPageLoading] = useState(false);

  const {
    control,
    handleSubmit,
    unregister,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...data?.tournamentPageConfig?.reduce((acc: any, field: any) => {
        acc[field.inputKey] = field.widgetAttributes?.details.value || ""; // Use optional chaining
        return acc;
      }, {}),
      sportAttributes:
        data?.sportAttributes?.reduce((acc: any, field: any) => {
          acc[field.inputKey] = field.widgetAttributes?.details.value || ""; // Use optional chaining
          return acc;
        }, {}) || {}, // Fallback to an empty object if undefined
    },
    mode: "onBlur",
  });

  useEffect(() => {
    fetchOptions("venue");
  }, []);

  const fetchOptions = async (inputKey: string) => {
    if (inputKey === "venue") {
      try {
        setLoading(true);
        setError(null);

        const reqData = { page: 1, pageSize: 50 };
        const result = await getVenueListPaginated(reqData);

        if (result && !result.isError) {
          const venueList = result.data.list.map((venue: any) => ({
            label: venue.name,
            id: venue.venueId,
          }));
          setDropdownOptions((prev) => ({ ...prev, venue: venueList }));
        } else {
          setError("No venues found");
        }
      } catch (error) {
        console.error("Error fetching venues:", error);
        setError("No venues found");
      } finally {
        setLoading(false);
      }
    }
  };

  const createNewTournament = async (formData: any) => {
    setPageLoading(true);
    let result = await createTournament(formData);
    if (result && !result.isError) {
      navigate("/tournament-new");
      toast.success("Successfully created!");
    } else {
      toast.error("Tournament creation failed");
    }
    setPageLoading(false);
  };

  const onSubmit = (formData: any) => {
    if (formType === "create") {
      createNewTournament(formData);
    } else {
      formData.tournamentId = tournamentId;
      createNewTournament(formData);
    }
  };

  const handleDiscard = () => {
    reset(
      data.tournamentPageConfig.reduce(
        (acc: any, field: any) => {
          acc[field.inputKey] = "";
          return acc;
        },
        { sportAttributes: {} }
      )
    );
  };

  const handleEditDiscard = () => {
    window.location.href = `/tournament-new/view/${tournamentId}`;
  };

  if (pageLoading) {
    return <BackdropLoader loaderState={pageLoading} />;
  }

  return (
    <>
      <FormHeader
        formType={formType}
        tournamentId={tournamentId}
        status={data?.tournamentDetails?.status}
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles["form-container"]}>
          <div className={styles["form-fields-wrapper"]}>
            <div className={styles["common-fields"]}>
              {data.tournamentPageConfig.map((field: any) => (
                <Controller
                  key={field.inputKey}
                  name={field.inputKey}
                  defaultValue={field.widgetAttributes.details.value}
                  control={control}
                  rules={{
                    required: field.widgetAttributes.isMandatory
                      ? "This field is required"
                      : false,
                  }}
                  render={({ field: controllerField }) => (
                    <>
                      {RenderField(
                        field,
                        data,
                        controllerField.value,
                        dropdownOptions,
                        controllerField.onChange,
                        control,
                        unregister,
                        formType
                      )}
                      {errors[field.inputKey] && (
                        <span className={styles["error"]}>
                          {errors[field.inputKey]?.message as string}
                        </span>
                      )}
                    </>
                  )}
                />
              ))}
            </div>

            <div className={styles["sports-specific-fields"]}>
              <div className={styles["divider"]}></div>
              <div className={styles["sports-specific-heading"]}>
                {selectedSport || "Indoor Cricket"}
              </div>
              {data.sportAttributes.map((field: any) => (
                <Controller
                  key={field.inputKey}
                  name={`sportAttributes.${field.inputKey}`}
                  control={control}
                  rules={{
                    required: field.widgetAttributes.isMandatory
                      ? "This field is required"
                      : false,
                  }}
                  render={({ field: controllerField }) => (
                    <>
                      {RenderField(
                        field,
                        data,
                        controllerField.value,
                        dropdownOptions,
                        controllerField.onChange,
                        control,
                        unregister,
                        formType
                      )}
                      {errors[field.inputKey] && (
                        <span className={styles["error"]}>
                          {errors[field.inputKey]?.message as string}
                        </span>
                      )}
                    </>
                  )}
                />
              ))}
            </div>
          </div>

          {formType !== "view" && (
            <div className={styles["button-container"]}>
              <button
                type="button"
                onClick={
                  formType === "create" ? handleDiscard : handleEditDiscard
                }
                className={styles["discard-button"]}
              >
                Discard
              </button>
              <button type="submit" className={styles["submit-button"]}>
                Save Tournament
              </button>
            </div>
          )}
        </div>
      </form>
    </>
  );
};

export default TournamentInputs;
