export const ISACTIVE = [
  {
    label: "True",
    value: 1,
  },
  {
    label: "False",
    value: 0,
  },
];
