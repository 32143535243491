/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { SketchPicker } from "react-color";
import TextField from "@mui/material/TextField";
import styles from "./styles.module.css";
import { TextFieldInputStyles } from "./FormUtils/FormStyles";

export const FormColorPicker = (props: any) => {
  const { name, label, disabled, rules, required } = props;
  console.log("recieved props: ", props);
  const { control } = useFormContext();
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={"#cecece"}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          <label className={styles["form-label"]}>
            {label ? label : <>&nbsp;</>}
            {required ? (
              <span className={styles["required-symbol"]}>*</span>
            ) : (
              <></>
            )}
          </label>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                padding: "5px",
                background: "#fff",
                borderRadius: "1px",
                boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
                display: "inline-block",
                cursor: "pointer",
                marginRight: "10px",
                width: "60px",
                height: "40px",
              }}
              onClick={handleClick}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "2px",
                  background: value,
                }}
              />
            </div>
            <TextField
              size="small"
              onChange={(e) => onChange(e.target.value)}
              value={value}
              fullWidth
              variant="outlined"
              disabled={disabled}
              sx={TextFieldInputStyles}
              inputProps={{ maxLength: 7, style: { fontFamily: "monospace" } }}
            />
          </div>
          {displayColorPicker && !disabled ? (
            <div
              style={{
                position: "absolute",
                zIndex: 2,
              }}
            >
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                }}
                onClick={handleClose}
              />
              <SketchPicker
                color={value}
                onChange={(color) => onChange(color.hex)}
                disableAlpha
              />
            </div>
          ) : null}
          <div className={styles["error-msg"]}>
            {error ? error.message : null}
          </div>
        </>
      )}
    />
  );
};
