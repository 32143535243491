import React from "react";
import { useNavigate } from "react-router";
import { Box } from "@mui/material";
import { toast } from "react-toastify";

import FormButton from "components/Common Components/Buttons/FormButton";
import { cancelTournament } from "services/touranments";
import colors from "config/colorConfig";
import { logFirebaseEvent } from "config/firebase";
import CancelIcon from "assets/CancelIcon.svg";

type Props = {
  modalState: boolean;
  handleModalState: Function;
  tournamentData: any;
};

function CancelTournamentModal({
  modalState,
  handleModalState,
  tournamentData,
}: Props) {
  const navigate = useNavigate();

  const handleCancelTournament = async () => {
    let result = await cancelTournament(tournamentData?.tournament_id);
    if (result && !result?.isError) {
      handleModalState(false);
      navigate("/tournament");
      toast.success("Tournament cancelled!");
      logFirebaseEvent({
        event_name: "tournament_cancel",
        tournament_status: tournamentData?.status,
      });
    } else {
      toast.error(result?.reason);
      handleModalState(false);
    }
  };
  return (
    <>
      <Box textAlign="center">
        <img src={CancelIcon} alt="icon" />
        <p style={{ fontSize: "2rem" }}>
          Are you sure you want to cancel this tournament?
        </p>

        <Box display="flex" justifyContent="center" gap={2}>
          <FormButton
            type="button"
            fontColor={colors.grayNinety}
            backgroundColor={colors.white}
            borderColor={colors.grayThirty}
            onClick={() => handleModalState(false)}
          >
            I changed my mind, Go back
          </FormButton>

          <FormButton
            type="button"
            fontColor={colors.white}
            backgroundColor={colors.redFifty}
            onClick={() => handleCancelTournament()}
          >
            Yes, cancel this tournament
          </FormButton>
        </Box>
      </Box>
    </>
  );
}

export default CancelTournamentModal;
