import React from "react";
import { GridColDef } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import dayjs from "dayjs";
import {
  StyledStatusChip,
  StyledBoolean,
  ValueWithCopy,
} from "components/Common Components/DataTable/DatatableConfig";
import colors from "config/colorConfig";

export const MatchListConfig: GridColDef[] = [
  {
    field: "teamAName",
    headerName: "TEAM A",
    flex: 1,
    disableColumnMenu: true,
    hideable: false,
    sortable: false,
    renderCell: (params: any) => {
      const teamName = params?.row?.teamAName;
      const teamLogoUrl = params?.row?.teamAImage;
      return (
        <>
          <img
            src={teamLogoUrl}
            style={{ width: "18%" }}
            alt="team-logo"
            loading="lazy"
          />
          &nbsp;
          <span>{teamName}</span>
        </>
      );
    },
  },
  {
    field: "teamBName",
    headerName: "TEAM B",
    flex: 1,
    disableColumnMenu: true,
    hideable: false,
    sortable: false,
    renderCell: (params: any) => {
      const teamName = params?.row?.teamBName;
      const teamLogoUrl = params?.row?.teamBImage;
      return (
        <>
          <img
            src={teamLogoUrl}
            style={{ width: "18%" }}
            alt="team-logo"
            loading="lazy"
          />
          &nbsp;
          <span>{teamName}</span>
        </>
      );
    },
  },
  {
    field: "matchId",
    headerName: "ID",
    flex: 2.5,
    disableColumnMenu: true,
    hideable: false,
    renderCell: (params: any) => {
      return ValueWithCopy(params.value);
    },
  },

  {
    field: "start_time",
    headerName: "STARTS ON",
    disableColumnMenu: true,
    flex: 1,
    hideable: true,
    renderCell: (params: any) => {
      return (
        <Box display="flex" flexDirection="column">
          <div>{dayjs(params.value).format("DD MMM, YYYY")}</div>
          <div style={{ fontSize: "1.4rem", color: colors.graySixty }}>
            {dayjs(params.value).format("HH:mm")}
          </div>
        </Box>
      );
    },
  },
  {
    field: "sportsCode",
    headerName: "SPORT",
    sortable: true,
    flex: 1,
    disableColumnMenu: true,
  },

  {
    field: "status",
    headerName: "STATUS",
    sortable: true,
    flex: 1,
    disableColumnMenu: true,
    renderCell: (params: any) => {
      return StyledStatusChip(params.value);
    },
  },
  {
    field: "is_active",
    headerName: "IS ACTIVE",
    sortable: true,
    flex: 1,
    disableColumnMenu: true,
    renderCell: (params: any) => {
      return StyledBoolean(params.value);
    },
  },
];
