import { useNavigate } from "react-router-dom";
import { getAccessToken, getRefreshToken } from "../utils/Helper";

function useAuthHandlers() {
  const navigate = useNavigate();
  const checkIsLoggedIn = () => {
    try {
      if (typeof window !== "undefined") {
        const accessToken = getAccessToken();
        const refreshToken = getRefreshToken();

        if (!!accessToken && !!refreshToken) return true;
      }
    } catch (err) {
      console.log(err);
    }

    return false;
  };

  const handleLogout = () => {
    if (typeof window !== "undefined") {
      let storageL = localStorage;

      storageL.removeItem("access_token");
      storageL.removeItem("refresh_token");
      storageL.removeItem("user_details");
      navigate("/login");
    } else {
      console.log(
        "There was an issue while loggin out! Please try again after sometime"
      );
    }
  };

  return { checkIsLoggedIn, handleLogout };
}

export default useAuthHandlers;
