/* eslint-disable no-unused-vars */
import { ENV, apiEndpoints, baseUrl } from "config/apiEndpoints";
import { GET, POST, PUT } from "services";
import { GROUND_PAGE_SIZE } from "utils/constants";
import {
  GROUND_AMENITIES,
  SPORTS,
  CRICKET_LIST,
  BADMINTON_LIST,
  FOOTBALL_LIST,
} from "components/Common Components/Sports/SportsConstants";

export const getGroundsListPaginated = async (reqData: any) => {
  if (!Object.keys(reqData).length) {
    reqData = {
      page: 1,
      pageSize: GROUND_PAGE_SIZE,
    };
  }
  let result = await POST(
    baseUrl[ENV] + apiEndpoints.groundsPaginatedList,
    reqData
  );
  return result;
};

export const formatGroundData = (groundData: any) => {
  const venueDetails: any = {};
  const venueAttributes: any = [];

  venueDetails["type"] = "ground";
  venueDetails["name"] = groundData?.name;
  venueDetails["address"] = groundData?.address;
  venueDetails["cityId"] = groundData?.city_id.id;
  venueDetails["ownerName"] = groundData?.ownerName;
  venueDetails["ownerContact"] = groundData?.ownerContact;
  venueDetails["imageUrl"] = groundData?.imageUrl;
  venueDetails["location"] = groundData?.mapUrl;
  venueDetails["isActive"] = Number(groundData?.isActive);

  // sports enabled?
  groundData?.sports?.forEach((sport: any) => {
    venueAttributes.push({
      attributeName: "sportsEnabled",
      attributeValue: "1",
      sportsCode: sport.id ? sport.id : sport.value,
    });
  });

  // cricket pitch types
  groundData?.cricketList?.forEach((pitch: any) => {
    venueAttributes.push({
      attributeName: "pitchType",
      attributeValue: pitch.id ? pitch.id : pitch.value,
      sportsCode: "CRICKET",
    });
  });

  // badminton court types
  groundData?.badmintonList?.forEach((court: any) => {
    venueAttributes.push({
      attributeName: "courtType",
      attributeValue: court.id ? court.id : court.value,
      sportsCode: "BADMINTON",
    });
  });

  // football field types
  groundData?.footballList?.forEach((field: any) => {
    venueAttributes.push({
      attributeName: "fieldType",
      attributeValue: field.id ? field.id : field.value,
      sportsCode: "FOOTBALL",
    });
  });

  // amenities available
  groundData?.groundAmenities?.forEach((amenity: any) => {
    venueAttributes.push({
      attributeName: "amenities",
      attributeValue: amenity.id ? amenity.id : amenity.value,
    });
  });

  return { venueDetails, venueAttributes };
};

export const createGround = async (groundData: any) => {
  const result = await POST(
    baseUrl[ENV] + apiEndpoints.createGround,
    groundData
  );
  return result;
};

export const getGroundDetailsById = async (venueId: string) => {
  const params = { venueId: venueId };
  const result = await GET(baseUrl[ENV] + apiEndpoints.getGroundById, params);
  return result;
};

export const formatGetGroundData = (groundData: any) => {
  const formattedData: any = {};
  formattedData["name"] = groundData?.venueDetails?.name;
  formattedData["address"] = groundData?.venueDetails?.address;
  formattedData["city"] = groundData?.venueDetails?.city;
  formattedData["state"] = groundData?.venueDetails?.state;
  formattedData["ownerName"] = groundData?.venueDetails?.ownerName;
  formattedData["ownerContact"] = groundData?.venueDetails?.ownerContact;
  formattedData["imageUrl"] = groundData?.venueDetails?.imageUrl;
  formattedData["mapUrl"] = groundData?.venueDetails?.location;
  formattedData["isActive"] = groundData?.venueDetails?.isActive;
  formattedData["cityId"] = groundData?.venueDetails?.cityId;
  formattedData["stateId"] = groundData?.venueDetails?.stateId;

  // sports enabled?
  const sportsList: any = [];
  groundData?.venueAttributes?.forEach((attr: any) => {
    if (attr.attributeName === "sportsEnabled") {
      SPORTS.forEach((sport) => {
        if (sport.value === attr.sportsCode) {
          // sportsList.push(sport);
          sportsList.push({ ...sport, id: attr.sportsCode });
        }
      });
    }
  });
  formattedData["sports"] = sportsList;

  // cricket pitch types
  const cricketList: any = [];
  groundData?.venueAttributes?.forEach((attr: any) => {
    if (attr.attributeName === "pitchType" && attr.sportsCode === "CRICKET") {
      CRICKET_LIST.forEach((pitch) => {
        if (pitch.value === attr.attributeValue) {
          // cricketList.push(pitch);
          cricketList.push({ ...pitch, id: attr.attributeValue });
        }
      });
    }
  });
  formattedData["cricketList"] = cricketList;

  // badminton court types
  const badmintonList: any = [];
  groundData?.venueAttributes?.forEach((attr: any) => {
    if (attr.attributeName === "courtType" && attr.sportsCode === "BADMINTON") {
      BADMINTON_LIST.forEach((court) => {
        if (court.value === attr.attributeValue) {
          // badmintonList.push(court);
          badmintonList.push({ ...court, id: attr.attributeValue });
        }
      });
    }
  });
  formattedData["badmintonList"] = badmintonList;

  // football field types
  const footballList: any = [];
  groundData?.venueAttributes?.forEach((attr: any) => {
    if (attr.attributeName === "fieldType" && attr.sportsCode === "FOOTBALL") {
      FOOTBALL_LIST.forEach((field) => {
        if (field.value === attr.attributeValue) {
          // footballList.push(field);
          footballList.push({ ...field, id: attr.attributeValue });
        }
      });
    }
  });
  formattedData["footballList"] = footballList;

  // amenities available
  const groundAmenities: any = [];
  groundData?.venueAttributes?.forEach((attr: any) => {
    if (attr.attributeName === "amenities") {
      GROUND_AMENITIES.forEach((amenity) => {
        if (amenity.value === attr.attributeValue) {
          // groundAmenities.push(amenity);
          groundAmenities.push({ ...amenity, id: attr.attributeValue });
        }
      });
    }
  });
  formattedData["groundAmenities"] = groundAmenities;

  return formattedData;
};

export const editGround = async (groundData: any) => {
  const result = await PUT(baseUrl[ENV] + apiEndpoints.editGround, groundData);
  return result;
};
