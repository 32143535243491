import React from "react";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  fontColor: string;
  backgroundColor: string;
  borderColor?: string;
  disabledColor?: string;
  disabledBackground?:string
}

function FormButton({
  children,
  fontColor,
  backgroundColor,
  borderColor,
  disabledColor,
  disabledBackground,
  ...attributes
}: Props) {
  const buttonStyles = {
    color: fontColor,
    backgroundColor: backgroundColor,
    border: borderColor ? `0.1rem solid ${borderColor}` : "none",
    borderRadius: "1.2rem",
    padding: "1.4rem 4rem",
    fontWeight: 600,
    fontSize: "1.6rem",
    lineHeight: "2.4rem",
    cursor: "pointer"
  };

  const disabledStyles = {
    color: disabledColor ? disabledColor : "",
    backgroundColor: disabledBackground ? disabledBackground : "",
    border: borderColor ? `0.1rem solid ${borderColor}` : "none",
    borderRadius: "1.2rem",
    padding: "1.4rem 4rem",
    fontWeight: 600,
    fontSize: "1.6rem",
    lineHeight: "2.4rem",
  }

  return (
    <button {...attributes} style={attributes.disabled ? disabledStyles : buttonStyles}>
      {children}
    </button>
  );
}

export default FormButton;
