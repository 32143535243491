import { fetchAllStates, fetchCitiesInState } from "services/location";

export const getAllStateListMapped = async () => {
    let result = await fetchAllStates();
    if (result && !result?.isError) {
      return result?.data?.map((state: any) => {
        return {
          id: state?.stateId,
          label: state?.name,
        };
      });
    }
    return [];
};

export const getCitiesListMapped = async (searchVal: string, stateId: string) => {
let result = await fetchCitiesInState(searchVal, stateId);
if (result && !result?.isError) {
    return result?.data?.map((city: any) => {
        return {
            id: city?.cityId,
            label: city?.name,
            stateId: city?.stateId,
        };
    });
}
return [];
};
