import React from "react";
import colors from "config/colorConfig";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  fontColor: string;
  backgroundColor: string;
  borderColor?: string;
}

function HeaderOptionsButton({
  children,
  fontColor,
  backgroundColor,
  borderColor,
  ...attributes
}: Props) {
  const buttonStyles = {
    color: !attributes.disabled ? fontColor : colors.grayFifty,
    backgroundColor: backgroundColor,
    border: borderColor ? `0.1rem solid ${borderColor}` : "none",
    borderRadius: "1.2rem",
    padding: "1.2rem 2.4rem",
    fontWeight: 600,
    fontSize: "1.4rem",
    lineHeight: "2rem",
    margin: '0.5em',
    cursor: !attributes.disabled ? "pointer" : "default",
  };

  return (
    <button {...attributes} style={buttonStyles}>
      {children}
    </button>
  );
}

export default HeaderOptionsButton;
