export const MATCHTYPE = [
  { label: "League", value: "league match" },
  { label: "Knock-Out", value: "knock-out match" },
  { label: "Quarter-Final", value: "quarter-final" },
  { label: "Semi-Final", value: "semi-final" },
  { label: "Final", value: "final" },
];

export const NUMBEROFOVERS = [
  { label: "5 Overs", value: 5 },
  { label: "6 Overs", value: 6 },
  { label: "7 Overs", value: 7 },
  { label: "8 Overs", value: 8 },
  { label: "9 Overs", value: 9 },
  { label: "10 Overs", value: 10 },
  { label: "11 Overs", value: 11 },
  { label: "12 Overs", value: 12 },
  { label: "13 Overs", value: 13 },
  { label: "14 Overs", value: 14 },
  { label: "15 Overs", value: 15 },
  { label: "20 Overs", value: 20 },
  { label: "50 Overs", value: 50 },
];

export const SPORTS = [
  {
    label: "Cricket",
    value: "CRICKET",
  },
  {
    label: "Football",
    value: "FOOTBALL",
  },
  {
    label: "Badminton",
    value: "BADMINTON",
  },
  {
    label:"Indoor Cricket",
    value: "INDOOR_CRICKET",
  }
];

export const ISACTIVE = [
  {
    label: "True",
    value: 1,
  },
  {
    label: "False",
    value: 0,
  },
];

export const LINEUPSIZE = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
  { label: "6", value: 6 },
  { label: "7", value: 7 },
  { label: "8", value: 8 },
  { label: "9", value: 9 },
  { label: "10", value: 10 },
  { label: "11", value: 11 },
  { label: "12", value: 12 },
  { label: "13", value: 13 },
  { label: "14", value: 14 },
  { label: "15", value: 15 },
];

export const MAXOVERSPERBOWLER = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
  { label: "6", value: 6 },
  { label: "7", value: 7 },
  { label: "8", value: 8 },
  { label: "9", value: 9 },
  { label: "10", value: 10 },
  { label: "11", value: 11 },
  { label: "12", value: 12 },
  { label: "13", value: 13 },
  { label: "14", value: 14 },
  { label: "15", value: 15 },
];

export const MATCHCATEGORY = [
  { label: "Mens", value: "MENS" },
  { label: "Womens", value: "WOMENS" },
  { label: "Mixed", value: "MIXED" },
];
