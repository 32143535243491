/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  Autocomplete,
  Box,
  FormControl,
  TextField,
  autocompleteClasses,
} from "@mui/material";
import { debounce } from "@mui/material/utils";

import {
  MultiSearchDropdownStyles,
  SelectFieldStyles,
} from "components/Common Components/Form Fields/FormUtils/FormStyles";

import EntityDropDown from "components/Common Components/Dropdowns/EntityDropdown";

import styles from "./style.module.scss";
import Icon from "assets/Search.svg";

const IconPop = () => {
  return <img src={Icon} style={{ marginTop: "0.3rem" }} />;
};

const FormMultiselectLive = ({
  name, //done
  label, //done
  placeholder, //done
  isEditable, //done
  isRequired, //done
  presentValue, //done
  optionsFetcher, // done
  OptionsDisplayComp = EntityDropDown, // component to display options
  populateOptions, //done
  onInputChange, // done
  isFullWidth,
  idParam,
}: any) => {
  const [data, setData] = useState<any>([]);
  const [inputVal, setInputVal] = useState("");

  const fetchOptions = React.useMemo(
    () =>
      debounce(async (input) => {
        if (optionsFetcher) {
          let result = await optionsFetcher(input);
          if (result) setData(result);
        } else setData([]);
      }, 400),
    []
  );

  useEffect(() => {
    if (populateOptions) fetchOptions("");
    if (inputVal.length >= 3) fetchOptions(inputVal);
  }, [inputVal, fetchOptions]);

  return (
    <FormControl
      size="small"
      className={styles["multi-select-live-container"]}
      style={{
        width: isFullWidth ? "100%" : "",
        marginBottom: "20px",
      }}
    >
      <label className={styles["form-label"]}>
        {label}{" "}
        {isRequired && <span className={styles["required-symbol"]}>*</span>}
      </label>

      <Autocomplete
        multiple
        filterOptions={(x) => x}
        includeInputInList
        disableCloseOnSelect
        clearOnBlur={false}
        filterSelectedOptions
        popupIcon={<IconPop />}
        sx={{
          [`& .${autocompleteClasses.popupIndicator}`]: { transform: "none" },
        }}
        options={data || []}
        disabled={!isEditable}
        onChange={(_, values) => onInputChange(values)}
        getOptionLabel={(option) => option?.label || ""}
        isOptionEqualToValue={(option, value) =>
          option?.[idParam] === value?.[idParam]
        }
        value={presentValue || []}
        renderInput={(params) => (
          <TextField
            className={styles["input"]}
            {...params}
            sx={MultiSearchDropdownStyles}
            placeholder={placeholder}
            size="small"
            onChange={(e) => setInputVal(e.target.value)}
          />
        )}
        renderOption={(props, option) =>
          OptionsDisplayComp ? (
            <OptionsDisplayComp data={option} {...props} />
          ) : (
            <Box component="li" {...props} sx={{ fontSize: "1.4rem" }}>
              {option?.label}
            </Box>
          )
        }
      />
    </FormControl>
  );
};

export default FormMultiselectLive;
