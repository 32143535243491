import React, { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import {
  DateFieldCalenderStyles,
  DateFieldStyles,
} from "./FormUtils/FormStyles";
import styles from "./styles.module.css";

export const FormInputDate = (props: any) => {
  const { name, label, disabled, rules, required, disablePast, disableFuture } =
    props;
  const { control } = useFormContext();

  const [open, setOpen] = useState(false);

  return (
    <>
      <label className={styles["form-label"]}>
        {label}{" "}
        {required ? (
          <span className={styles["required-symbol"]}>*</span>
        ) : (
          <></>
        )}
      </label>

      <LocalizationProvider dateAdapter={AdapterDateFns} data-testid={name}>
        <Controller
          name={name}
          control={control}
          defaultValue={null}
          rules={rules}
          render={({
            field: { onChange, onBlur, value },
            fieldState: { error },
          }) => (
            <>
              <DatePicker
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                value={value}
                onChange={onChange}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    error: !!error,
                    name: name,
                    onClick: () => setOpen(true),
                    onBlur: onBlur,
                    inputProps: { "data-testid": name },
                  },
                  layout: { sx: DateFieldCalenderStyles },
                }}
                sx={DateFieldStyles}
                disabled={disabled}
                format="dd/MM/yyyy"
                disablePast={disablePast !== undefined ? disablePast : true}
                disableFuture={
                  disableFuture !== undefined ? disableFuture : false
                }
              />
              <div className={styles["error-msg"]}>
                {error ? error.message : null}
              </div>
            </>
          )}
        />
      </LocalizationProvider>
    </>
  );
};
