import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { Divider, Grid } from "@mui/material";
import { toast } from "react-toastify";

import FieldMapper from "components/Common Components/Form Fields/FormUtils/FieldMapper";
import FormButton from "components/Common Components/Buttons/FormButton";
import BasicModal from "components/Common Components/Modal/BasicModal";
import DiscardModal from "components/Common Components/Modal/DiscardModal";

import { leftSideColConfig, rightSideColConfig } from "../formConfig";
import { createScorer } from "services/scorers";
import { logFirebaseEvent } from "config/firebase";
import colors from "config/colorConfig";
import styles from "../styles.module.css";
import BackdropLoader from "components/Common Components/Loaders/LoaderBackdrop";

function CreateScorerForm() {
  const navigate = useNavigate();

  const methods = useForm({
    criteriaMode: "firstError",
    mode: "onBlur",
    shouldFocusError: true,
  });

  const {
    handleSubmit,
    formState: { isValid, isDirty },
  } = methods;

  const [modalState, setModalState] = useState(false);
  const [loaderState, setLoaderState] = useState(false)

  const formSubmitHandler = async (formData: any) => {
    setLoaderState(true)
    formData["deleted"] = Number(formData["deleted"]);

    const result = await createScorer(formData);
    if (result && !result?.isError) {
      const scorerId = result?.data?.scorerId;
      logFirebaseEvent({
        event_name: "scorer_add_save",
        scorer_id: scorerId,
      });
      toast.success("Scorer created successfully!");
      navigate("/scorers");
    } else {
      toast.error(result?.reason);
    }
    setLoaderState(false)
  };

  const formConfig = {
    name: {
      disabled: false,
    },
    email: {
      disabled: false,
    },
    contactNumber: {
      disabled: false,
    },
    city: {
      disabled: false,
    },
    gender: {
      disabled: false,
    },
    uploadPhoto: {
      disabled: false,
    },
    isActive: {
      disabled: false,
    },
  };

  return (
    <>
      <div className={styles["form-heading"]}>Add scorer</div>

      <div className={styles["form-container"]}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(formSubmitHandler)}>
            <Grid container columnSpacing={6}>
              <Grid item xs={12} md={6}>
                <FieldMapper
                  fieldsConfig={leftSideColConfig(formConfig, "create")}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FieldMapper
                  fieldsConfig={rightSideColConfig(formConfig, "create")}
                />
              </Grid>
            </Grid>

            <Divider sx={{ my: 3 }} />

            <Grid container item columnGap={3}>
              <FormButton
                type="button"
                fontColor={colors.redSixty}
                backgroundColor={colors.white}
                borderColor={colors.grayThirty}
                onClick={() =>
                  !isDirty
                    ? navigate("/tournament")
                    : setModalState(!modalState)
                }
              >
                Discard
              </FormButton>

              <FormButton
                type="submit"
                fontColor={colors.white}
                backgroundColor={colors.grayNinety}
                disabledColor={colors.grayFifty}
                disabledBackground={colors.grayTwenty}
                disabled={!isValid}
              >
                Save team
              </FormButton>
            </Grid>
          </form>
        </FormProvider>
      </div>
      <BasicModal modalState={modalState} handleModalState={setModalState}>
        <DiscardModal
          modalState={modalState}
          handleModalState={setModalState}
        />
      </BasicModal>
      <BackdropLoader loaderState={loaderState} />
    </>
  );
}

export default CreateScorerForm;
