import { ENV, apiEndpoints, baseUrl } from "config/apiEndpoints";
import { POST } from "services";

export const sendOtp = async (reqData: any) => {
  const result = await POST(baseUrl[ENV] + apiEndpoints.adminLogin, reqData);
  return result;
};

export const verifyOtp = async (reqData: any) => {
  const result = await POST(
    baseUrl[ENV] + apiEndpoints.adminVerifyOtp,
    reqData
  );
  return result;
};

export const resendOtp = async (reqData: any) => {
  const result = await POST(
    baseUrl[ENV] + apiEndpoints.adminResendOtp,
    reqData
  );
  return result;
};
