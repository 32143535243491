import React, { useState, useEffect } from "react";
import styles from "./style.module.scss";

interface SingleSelectDropdownProps {
  inputKey: string;
  isRequired: boolean;
  label: string;
  placeholder: string;
  presentValue: string;
  options: string[];
  isEditable?: boolean;
  onInputChange: any;
  isFullWidth?: boolean;
}

const SingleSelectDropdown = ({
  inputKey,
  isRequired,
  label,
  placeholder,
  presentValue,
  options,
  isEditable,
  onInputChange,
  isFullWidth,
}: SingleSelectDropdownProps) => {
  const [selectedValue, setSelectedValue] = useState(presentValue || "");

  useEffect(() => {
    // Update the selected value when presentValue changes (e.g., when editing)
    setSelectedValue(presentValue || "");
  }, [presentValue]);

  // const handleChange = (event: any) => {
  //   const { value } = event.target;
  //   setSelectedValue(value);
  //   onInputChange(inputKey, value);
  // };

  const handleChange = (event: any) => {
    onInputChange(event.target.value); // Call the onInputChange function
  };

  return (
    <div className={styles["single-select-dd-container"]}>
      <label htmlFor={inputKey}>
        {label}
        {isRequired && <span style={{ color: "red" }}> *</span>}
      </label>
      <select
        id={inputKey}
        value={selectedValue}
        required={isRequired}
        onChange={handleChange}
        disabled={!isEditable}
        style={{
          color: selectedValue ? "#000" : "#696969",
          backgroundColor: !isEditable ? "#efefef" : "",
        }}
      >
        <option value="" disabled>
          {placeholder}
        </option>
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SingleSelectDropdown;
