import React from "react";
import { Box, List, ListItem } from "@mui/material";

import SideDrawer from "../components/Common Components/SideDrawer/SideDrawer";
import colors from "../config/colorConfig";
import locale from "./locale/Home.json";
import { getAdminName } from "utils/Helper";

const Heading = () => {
  const content = locale["en"];
  return (
    <>
      <div
        style={{
          fontSize: "4.8rem",
          fontWeight: "600",
          lineHeight: "4.4rem",
          letterSpacing: "-0.048rem",
          color: colors.grayNinety,
          paddingTop: "5rem",
          textAlign: "center",
        }}
      >
        Hey {getAdminName()}👋🏻Welcome to Pavilion Dashboard
      </div>
      <div
        style={{
          fontSize: "2.4rem",
          fontWeight: "400",
          lineHeight: "3.2rem",
          color: colors.graySeventy,
          textAlign: "center",
        }}
      >
        <p>{content.HOME_SUBHEADING}</p>
      </div>
    </>
  );
};

const CenterBox = () => {
  const content = locale["en"];
  const homePointers = content.HOME_POINTERS;
  return (
    <Box display="flex" justifyContent="center">
      <div
        style={{
          fontSize: "2.4rem",
          fontWeight: "400",
          lineHeight: "3.2rem",
          color: colors.grayEighty,
          backgroundColor: colors.white,
          width: "fit-content",
          padding: "0 2.4rem",
          borderRadius: "1.2rem",
          marginTop: "6rem",
        }}
      >
        <List sx={{ listStyle: "decimal", pl: 4 }}>
          {homePointers.map((item, idx) => {
            return (
              <ListItem
                sx={{ display: "list-item", py: 3, whiteSpace: "pre-line" }}
                key={idx}
              >
                {item}
              </ListItem>
            );
          })}
        </List>
      </div>
    </Box>
  );
};

function Home() {
  return (
    <SideDrawer>
      <div
        style={{
          backgroundColor: colors.grayTen,
          minHeight: "100vh",
        }}
      >
        <Heading />
        <CenterBox />
      </div>
    </SideDrawer>
  );
}

export default Home;
