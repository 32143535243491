import React, { useState, useEffect, useCallback } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Box, Divider, Grid } from "@mui/material";
import { toast } from "react-toastify";

import FieldMapper from "components/Common Components/Form Fields/FormUtils/FieldMapper";
import FormButton from "components/Common Components/Buttons/FormButton";

import { leftSideColConfig, rightSideColConfig } from "../formConfig";
import colors from "config/colorConfig";
import {
  editTeam,
  formatGetTeamData,
  getTeamDetailsById,
} from "services/teams";
import styles from "../styles.module.css";
import BackdropLoader from "components/Common Components/Loaders/LoaderBackdrop";

import {
  getAllStateListMapped,
  getCitiesListMapped,
} from "components/Common Components/Location/helper";

import EntityDropDown from "components/Common Components/Dropdowns/EntityDropdown";

function EditTeamForm() {
  const methods = useForm({
    criteriaMode: "firstError",
    mode: "onBlur",
    shouldFocusError: true,
  });
  const {
    handleSubmit,
    setValue,
    // watch,
    formState: { isDirty },
  } = methods;

  const navigate = useNavigate();
  const { state } = useLocation();
  const { teamId } = useParams();

  const [loaderState, setLoaderState] = useState(false);

  const fetchTeamData = async () => {
    setLoaderState(true);
    if (teamId) {
      let result = await getTeamDetailsById(teamId);
      if (result && !result?.isError && result?.data.length > 0) {
        let formattedData = formatGetTeamData(result?.data[0]);
        setPageData(formattedData);
      } else {
        toast.error(result?.reason);
      }
    } else {
      toast.error("There was some error! Please try again");
    }
    setLoaderState(false);
  };

  const setPageData = (data: any) => {
    for (const [key, value] of Object.entries(data)) {
      value && setValue(key, value);
    }
    setSelectedState(data?.state_id);
    setValue("is_active", data?.is_active);
    setValue("state", { id: data?.state_id, label: data?.state });
    setValue("city_id", { id: data?.city_id, label: data?.city });
  };

  const initializePageData = () => {
    if (!state) {
      fetchTeamData();
    } else {
      const { teamData } = state;
      setPageData(teamData);
    }
  };

  const [selectedState, setSelectedState] = useState<string>("");

  const onStateValueChange = useCallback(
    (item: any) => {
      setSelectedState(item.id);
      setValue("city", { id: " ", label: " " });
    },
    [setSelectedState]
  );

  // useEffect(() => {
  //   if (watch("state")) {
  //     setSelectedState(watch("state").id);
  //   }
  // }, [watch("state")]);

  const formSubmitHandler = async (formData: any) => {
    setLoaderState(true);
    formData["is_active"] = Number(formData["is_active"]);

    delete formData["state_id"];
    delete formData["city"];
    delete formData["state"];
    formData["city_id"] = formData["city_id"].id;

    const result = await editTeam(formData);
    if (result && !result?.isError) {
      toast.success("Updated successfully!");
      navigate(-1);
    } else {
      toast.error(result?.reason);
    }
    setLoaderState(false);
  };

  useEffect(() => {
    initializePageData();
  }, []);

  const formConfig = {
    teamName: {
      disabled: false,
    },
    teamDisplayName: {
      disabled: false,
    },
    tickerDisplayName: {
      disabled: false,
    },
    coachName: {
      disabled: false,
    },
    teamParent: {
      disabled: false,
    },
    uploadPhoto: {
      disabled: false,
    },
    homeCity: {
      disabled: false,
    },
    isActive: {
      disabled: false,
    },
    facebook: {
      disabled: false,
    },
    instagram: {
      disabled: false,
    },
    linkedin: {
      disabled: false,
    },
    primaryColor: {
      disabled: false,
    },
    secondaryColor: {
      disabled: false,
    },
    state: {
      disabled: false,
      optionsFetcher: getAllStateListMapped,
      OptionsDisplayComp: EntityDropDown,
      onValueChange: onStateValueChange,
    },
    city: {
      disabled: false,
      optionsFetcher: getCitiesListMapped,
      OptionsDisplayComp: EntityDropDown,
      idParam: selectedState,
    },
  };

  return (
    <>
      <div className={styles["form-heading"]}>
        <Box display="flex" alignItems="center">
          <span> Edit team</span> &nbsp;
        </Box>
      </div>

      <div className={styles["form-container"]}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(formSubmitHandler)}>
            <Grid container spacing={6}>
              <Grid item xs={12} md={6}>
                <FieldMapper fieldsConfig={leftSideColConfig(formConfig)} />
              </Grid>

              <Grid item xs={12} md={6}>
                <FieldMapper fieldsConfig={rightSideColConfig(formConfig)} />
              </Grid>
            </Grid>

            <Divider sx={{ my: 3 }} />

            <Grid container item columnGap={3}>
              <FormButton
                type="button"
                fontColor={colors.redSixty}
                backgroundColor={colors.white}
                borderColor={colors.grayThirty}
                onClick={() => navigate(-1)}
              >
                Discard
              </FormButton>

              <FormButton
                type="submit"
                fontColor={colors.white}
                backgroundColor={colors.grayNinety}
                disabledColor={colors.grayFifty}
                disabledBackground={colors.grayTwenty}
                disabled={!isDirty}
              >
                Save changes
              </FormButton>
            </Grid>
          </form>
        </FormProvider>
      </div>
      <BackdropLoader loaderState={loaderState} />
    </>
  );
}

export default EditTeamForm;
