/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";

// components
import FormHeading from "./FormHeading";
import AllSteps from "./AllSteps";
import BackdropLoader from "components/Common Components/Loaders/LoaderBackdrop";

// styles
import styles from "./style.module.scss";
import { useParams } from "react-router-dom";

// services
import {
  getTournamentConfig,
  getTournamentConfigById,
} from "services/tournamentsV3";

interface CreateTournamentFormProps {
  formType: string;
}

interface TournamentConfig {
  tournamentId?: string;
}

const index = ({ formType }: CreateTournamentFormProps) => {
  const { id: tournamentId } = useParams();

  const [configData, setConfigData] = useState<any>();
  const [selectedSport, setSelectedSport] = useState<string | null>("IC"); // use this when api calling has to be different for different sports
  const [loading, setLoading] = useState(false);

  const getTournementConfigDetails = async ({
    tournamentId,
  }: TournamentConfig) => {
    setLoading(true);
    if (tournamentId) {
      const result = await getTournamentConfigById(tournamentId);
      setConfigData(result?.data?.steps);
      setLoading(false);
    } else {
      setLoading(true);
      const result = await getTournamentConfig();
      setConfigData(result?.data?.steps);
    }
    setLoading(false);
  };

  useEffect(() => {
    getTournementConfigDetails({ tournamentId });
  }, [formType, tournamentId, selectedSport]);

  if (loading || !configData) {
    return <BackdropLoader loaderState={loading} />;
  }

  return (
    <div className={styles["main-form-container"]}>
      <FormHeading formType={formType} tournamentId={tournamentId} />
      <AllSteps
        config={configData}
        formType={formType}
        tournamentId={tournamentId}
      />
    </div>
  );
};

export default index;
