export const FORMATS = [
  {
    label: "International",
    value: "International",
  },
  {
    label: "Domestic",
    value: "Domestic",
  },
  {
    label: "Hyperlocal",
    value: "Hyperlocal",
  },
];

export const DISPLAY_FORMAT = [
  {
    label: "F5I (International - 5 overs)",
    value: "5 overs",
  },
  {
    label: "T10I (International - 10 overs)",
    value: "10 overs",
  },
  {
    label: "F15I (International - 15 overs)",
    value: "15 overs",
  },
  {
    label: "T20I (International - 20 overs)",
    value: "20 overs",
  },
  {
    label: "ODI (International - 50 overs)",
    value: "50 overs",
  },
  {
    label: "F5D (Domestic - 5 overs)",
    value: "5 overs",
  },
  {
    label: "T10D (Domestic - 10 overs)",
    value: "10 overs",
  },
  {
    label: "F15D (Domestic - 15 overs)",
    value: "15 overs",
  },
  {
    label: "T20D (Domestic - 20 overs)",
    value: "20 overs",
  },
  {
    label: "List A (Domestic - 50 overs)",
    value: "50 overs",
  },
  {
    label: "F5H (Hyperlocal - 5 overs)",
    value: "5 overs",
  },
  {
    label: "T10H (Hyperlocal - 10 overs)",
    value: "10 overs",
  },
  {
    label: "F15H (Hyperlocal - 15 overs)",
    value: "15 overs",
  },
  {
    label: "T20H (Hyperlocal - 20 overs)",
    value: "20 overs",
  },
  {
    label: "50 Overs (Hyperlocal - 50 overs)",
    value: "50 Overs",
  },
];

export const TYPES = [
  {
    label: "Knockout",
    value: "Knockout",
  },
  {
    label: "League",
    value: "League",
  },
  {
    label: "League + Knockout",
    value: "League + Knockout",
  },
];

export const SPORTS = [
  {
    label: "Cricket",
    value: "CRICKET",
  },
  {
    label: "Football",
    value: "FOOTBALL",
  },
];

export const ISACTIVE = [
  {
    label: "True",
    value: 1,
  },
  {
    label: "False",
    value: 0,
  },
];

export const ISSUBSTITUTIONALLOWED = [
  {
    label: "True",
    value: 1,
  },
  {
    label: "False",
    value: 0,
  },
];

export const BALLTYPES = [
  { label: "Leather Ball", value: "Leather Ball" },
  { label: "Tennis Ball", value: "Tennis Ball" },
];
