import React from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";

import FormButton from "components/Common Components/Buttons/FormButton";

import colors from "config/colorConfig";
import CancelIcon from "assets/CancelIcon.svg";

type Props = {
  modalState: boolean;
  handleModalState: Function;
};

function DiscardModal({ modalState, handleModalState }: Props) {
  const navigate = useNavigate();
  return (
    <>
      <Box textAlign="center">
        <img src={CancelIcon} alt="icon" />
        <p style={{ fontSize: "2rem" }}>
          Are you sure you want to discard all changes?
        </p>

        <Box display="flex" justifyContent="center" gap={2}>
          <FormButton
            type="button"
            fontColor={colors.grayNinety}
            backgroundColor={colors.white}
            borderColor={colors.grayThirty}
            onClick={() => handleModalState(false)}
          >
            I changed my mind, Go back
          </FormButton>

          <FormButton
            type="button"
            fontColor={colors.white}
            backgroundColor={colors.redFifty}
            onClick={() => {
              handleModalState(false);
              navigate(-1);
            }}
          >
            Yes, discard all changes
          </FormButton>
        </Box>
      </Box>
    </>
  );
}

export default DiscardModal;
