import React from "react";
import { GridColDef } from "@mui/x-data-grid";
import { ValueWithCopy } from "components/Common Components/DataTable/DatatableConfig";
import { PLAYER_ROLES } from "../PlayerConstants";

const displayPlayerRole = (role:string) => {
  if(role === PLAYER_ROLES.WICKET_KEEPER) return <>{PLAYER_ROLES["WICKET KEEPER"]}</>
  if(role === PLAYER_ROLES.ALL_ROUNDER) return <>{PLAYER_ROLES["ALL ROUNDER"]}</>
  else return <>{role}</>
}

export const PlayerListConfig: GridColDef[] = [
  {
    field: "name",
    headerName: "Player Name",
    flex: 1,
    disableColumnMenu: true,
    hideable: false,
  },
  {
    field: "player_id",
    headerName: "ID",
    flex: 2,
    disableColumnMenu: true,
    hideable: false,
    renderCell: (params: any) => {
      return ValueWithCopy(params.value);
    },
  },
  {
    field: "age",
    headerName: "AGE",
    sortable: true,
    flex: 1,
    disableColumnMenu: true,
  },
  {
    field: "role",
    headerName: "Type",
    sortable: true,
    flex: 1,
    disableColumnMenu: true,
    renderCell:(params:any) => {
      return displayPlayerRole(params.value)
    }
  },
  {
    field: "city",
    headerName: "City",
    flex: 1,
    disableColumnMenu: true,
    hideable: false,
  },
  {
    field: "gender",
    headerName: "Gender",
    flex: 1,
    disableColumnMenu: true,
    hideable: false,
    renderCell: (params: any) => {
      return <>{params.value?.toUpperCase()}</>;
    },
  },
];
