import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormControl, MenuItem, Select } from "@mui/material";

import {
  SelectFieldDropdownStyles,
  SelectFieldStyles,
} from "./FormUtils/FormStyles";
import styles from "./styles.module.css";

type singleOption = {
  label: string;
  value: any;
};

export const FormInputDropdown = (props: any) => {
  const {
    name,
    label,
    rules,
    options,
    placeholder,
    disabled,
    required,
    defaultValue,
  } = props;
  const { control } = useFormContext();
  const inputProps: any = {"data-testid":name};

  const generateSingleOptions = () => {
    return options?.map((option: singleOption) => {
      return (
        <MenuItem
          key={option.value}
          value={option.value}
          sx={SelectFieldDropdownStyles}
        >
          {option.label}
        </MenuItem>
      );
    });
  };
  return (
    <>
      <FormControl size="small" fullWidth>
        <label className={styles["form-label"]}>
          {label}{" "}
          {required ? (
            <span className={styles["required-symbol"]}>*</span>
          ) : (
            <></>
          )}
        </label>
        <Controller
          control={control}
          name={name}
          defaultValue={defaultValue ? defaultValue : ""}
          rules={rules}
          render={({
            field: { onChange, onBlur, value },
            fieldState: { error },
          }) => (
            <>
              <Select
                onChange={onChange}
                value={value}
                disabled={disabled}
                onBlur={onBlur}
                displayEmpty
                sx={SelectFieldStyles}
                error={!!error}
                // data-testid={name}
                inputProps={inputProps}
                renderValue={(selected) => {
                  if (!selected || !options) {
                    return <span>{placeholder}</span>;
                  } else {
                    let selectedOption = options?.find(
                      (item: any) => item.value === selected
                    );
                    return selectedOption?.label;
                  }
                }}
              >
                {generateSingleOptions()}
              </Select>
              <div className={styles["error-msg"]}>
                {error ? error.message : null}
              </div>
            </>
          )}
        />
      </FormControl>
    </>
  );
};
