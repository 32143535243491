import { ENV, apiEndpoints, baseUrl } from "config/apiEndpoints";
import { GET, POST } from "services";
import { BULK_UPLOAD_CHUNK_SIZE, TEAM_PAGE_SIZE } from "utils/constants";

export const getTeamsListPaginated = async (reqData: any) => {
  if (!Object.keys(reqData).length) {
    reqData = {
      page: 1,
      pageSize: TEAM_PAGE_SIZE,
    };
  }

  const result = await POST(
    baseUrl[ENV] + apiEndpoints.teamsPaginatedList,
    reqData
  );
  return result;
};

export const getTeamDetailsById = async (teamId: string) => {
  const params = { teamId: teamId };
  const result = await GET(baseUrl[ENV] + apiEndpoints.getTeamById, params);
  return result;
};

export const createTeam = async (teamData: any) => {
  const result = await POST(baseUrl[ENV] + apiEndpoints.createTeam, teamData);
  return result;
};

export const editTeam = async (teamData: any) => {
  const result = await POST(baseUrl[ENV] + apiEndpoints.createTeam, teamData);
  return result;
};

export const getPlayersByTeam = async (reqData: any) => {
  const result = await POST(
    baseUrl[ENV] + apiEndpoints.getPlayersbyTeam,
    reqData
  );
  return result;
};

export const formatGetTeamData = (teamData: any) => {
  const extraDataKeys = [
    "createdAt",
    "updatedAt",
    "description",
    "id",
    "tournament_id",
  ];

  for (let key of extraDataKeys) {
    delete teamData[key];
  }

  return teamData;
};

export const createBulkTeams = async (data: any) => {
  const chunks = [];
  for (let i = 0; i < data.length; i += BULK_UPLOAD_CHUNK_SIZE) {
    chunks.push(data.slice(i, i + BULK_UPLOAD_CHUNK_SIZE));
  }

  for (const chunk of chunks) {
    try {
      await POST(
        baseUrl[ENV] + apiEndpoints.createBulkTeams, chunk
      );
    } catch(err) {
      console.error("Error sending chunk:", err);
      throw err;
    }
  }
};