import React from 'react'

import SideDrawer from "components/Common Components/SideDrawer/SideDrawer";
import MatchListByScorer from 'components/Scorer/ScorerMatchList/ScorerMatchList';

function ScorerMatchList() {
  return (
    <>
    <SideDrawer>
      <div style={{ padding: "3.2rem" }}>
        <MatchListByScorer/>
      </div>
    </SideDrawer>
  </>
  )
}

export default ScorerMatchList