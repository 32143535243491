import { ENV, apiEndpoints, baseUrl } from "config/apiEndpoints";
import { GET } from "services";

export const fetchAllStates = async () => {
    const result = await GET(baseUrl[ENV] + apiEndpoints.getAllStates);
    return result;
}

export const fetchCitiesInState = async (searchString: string, stateId: string) => {
    const params = {
        name: searchString,
        stateId: stateId
    }
    const result = await GET(baseUrl[ENV] + apiEndpoints.getCitiesInState, params);
    return result;
}