import React from "react";

import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import {
  DataGrid,
  gridClasses,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";

import colors from "config/colorConfig";
import styles from "./styles.module.css";
import CopyIcon from "assets/Copy.svg";

export const CustomDataTable = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: colors.white,
  },
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: colors.grayTen,
  },
  "& .MuiDataGrid-row:hover": {
    cursor: "pointer",
  },
  "& .MuiPaginationItem-root": {
    // borderRadius: 50,
    fontSize: "1.2rem",
  },
  "& .MuiTablePagination-selectLabel": {
    fontSize: "1.2rem",
  },
  "& .MuiInputBase-root": {
    fontSize: "1.2rem",
  },
  "& .MuiTablePagination-displayedRows": {
    fontSize: "1.2rem",
  },
  // "& .MuiList-root": {
  //   ".MuiMenuItem-root": {
  //     fontSize: "1.2rem",
  //   },
  // },
  ".MuiTablePagination-actions": {
    "& .MuiButtonBase-root": {
      "& .MuiSvgIcon-root": {
        width: "2.2rem",
        height: "2.2rem",
      },
    },
  },
}));

export function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      variant="outlined"
      page={page + 1}
      count={pageCount}
      // @ts-expect-error
      renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
      onChange={(event: React.ChangeEvent<unknown>, value: number) =>
        apiRef.current.setPage(value - 1)
      }
    />
  );
}

/* eslint-disable-next-line */
export const StyledBoolean = (value: number) => {
  if (value === 0) return <span style={{ color: colors.redForty }}>False</span>;
  else return <span style={{ color: colors.graySeventy }}>True</span>;
};

export const StyledStatusChip = (value: string) => {
  return <span className={`${styles["chip"]} ${styles[value]}`}>{value}</span>;
};

export const ValueWithCopy = (value: string) => {
  return (
    <div>
      <span>{value}</span>
      &nbsp;
      <span
        style={{ cursor: "pointer" }}
        onClick={(e: any) => {
          e.stopPropagation();
          navigator?.clipboard?.writeText(value);
        }}
      >
        <img src={CopyIcon} alt="copy-icon" />
      </span>
    </div>
  );
};
