// CSVUploadComponent.tsx
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import {
  CircularProgress,
  Box,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import FormButton from "components/Common Components/Buttons/FormButton";
import colors from "config/colorConfig";
import { toast } from "react-toastify";

import styles from "./styles.module.css";

interface CSVUploadComponentProps {
  open: boolean;
  handleClose: () => void;
  csvLoader: boolean;
  setCSVDataList: (data: { [key: string]: any }[]) => void;
  requiredColumns: { [key: string]: any };
  handleCSVDataList: () => void;
  setMyFiles: React.Dispatch<React.SetStateAction<File[]>>;
  myFiles: File[];
  integerColumnFields?: any[];
  canConvertToCamelCase?: boolean;
  columns?: { [key: string]: any };
  sampleRow?: { [key: string]: any };
  arrayColumns?: any[];
}

const CSVUploadComponent: React.FC<CSVUploadComponentProps> = ({
  open,
  handleClose,
  csvLoader,
  setCSVDataList,
  requiredColumns,
  handleCSVDataList,
  setMyFiles,
  myFiles,
  integerColumnFields = [],
  canConvertToCamelCase = false,
  columns = requiredColumns,
  sampleRow = {},
  arrayColumns = [],
}) => {
  const onDrop = useCallback((acceptedFiles: File[]) => {
    setMyFiles([...myFiles, ...acceptedFiles]);
    handleCSVUpload(acceptedFiles);
    // eslint-disable-next-line
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "text/csv": [".csv"] },
    maxFiles: 1,
    multiple: false,
    maxSize: 1000000,
  });

  const parseCSV = (csvData: string): string[][] => {
    return csvData.split("\n").map((row) => row.split(","));
  };

  const generateSampleCSV = () => {
    const headerRow = Object.keys(columns).map((column) => {
      if (requiredColumns[column]) {
        return column;
      } else {
        return column;
      }
    });

    const mappedRow = Object.keys(columns).map((column: any) => {
      return sampleRow[column];
    });

    const sampleCSVContent = [headerRow.join(","), mappedRow.join(",")].join(
      "\n"
    );

    const blob = new Blob([sampleCSVContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "sample.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const handleCSVUpload = (files: any) => {
    const data = new FormData();
    data.append("file", files[0]);

    const reader = new FileReader();

    const requiredColumnKeys: any = Object.keys(requiredColumns);

    reader.onload = (event) => {
      if (event.target && event.target.result) {
        const csvData = event.target.result as string;
        const parsedData = parseCSV(csvData);

        let columns = parsedData[0];
        const rows = parsedData.slice(1);

        columns = columns.map((column) => column.trim());

        const missingColumns = requiredColumnKeys.filter(
          (key: any) => !columns.includes(key)
        );

        if (missingColumns.length > 0) {
          removeFile();
          toast.error(`Missing required columns: ${missingColumns.join(", ")}`);
          return;
        }

        const rowDataArray = rows.map((row) => {
          const rowData: { [key: string]: any } = {};
          columns.forEach((column, index) => {
            const modifiedColumn = column.trim();
            if (integerColumnFields.includes(modifiedColumn)) {
              rowData[
                canConvertToCamelCase
                  ? toCamelCase(modifiedColumn)
                  : modifiedColumn
              ] = parseInt(row[index]) || 0;
            } else if (arrayColumns.includes(modifiedColumn)) {
              rowData[
                canConvertToCamelCase
                  ? toCamelCase(modifiedColumn)
                  : modifiedColumn
              ] = row[index].trim().split(",");
            } else {
              rowData[
                canConvertToCamelCase
                  ? toCamelCase(modifiedColumn)
                  : modifiedColumn
              ] = row[index];
            }
          });
          return rowData;
        });
        setCSVDataList(rowDataArray);
      }
    };

    if (files[0] instanceof Blob || files[0] instanceof File) {
      reader.readAsText(files[0]);
    } else {
      console.error("Invalid file type. Please provide a valid Blob or File.");
    }
  };

  const toCamelCase = (inputString: string) => {
    return inputString.replace(/[-_](.)/g, function (match, group1) {
      return group1.toUpperCase();
    });
  };

  const removeFile = () => {
    setMyFiles([]);
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogContent>
          <div className={styles["drop-container"]}>
            <div {...getRootProps()} className={styles["drag-drop-area"]}>
              <input {...getInputProps()} />
              {isDragActive ? (
                <p>Drop the CSV file here...</p>
              ) : (
                <p>
                  Drag &apos;n&apos; drop a CSV file here, or click to select
                  one
                </p>
              )}
            </div>
            {myFiles.length > 0 && (
              <div className={styles["selected-drop-file"]}>
                <p>Selected file: {myFiles[0].name}</p>
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          {csvLoader && (
            <Box sx={{ display: "flex" }}>
              <CircularProgress />
            </Box>
          )}

          <FormButton
            fontColor={colors.white}
            backgroundColor={colors.grayNinety}
            disabledColor={colors.grayFifty}
            disabledBackground={colors.grayTwenty}
            onClick={generateSampleCSV}
          >
            Download mock csv
          </FormButton>

          <FormButton
            fontColor={colors.white}
            backgroundColor={colors.redSixty}
            disabledColor={colors.grayFifty}
            disabledBackground={colors.grayTwenty}
            onClick={handleClose}
          >
            Cancel
          </FormButton>

          <FormButton
            fontColor={colors.white}
            backgroundColor={colors.greenFifty}
            disabledColor={colors.grayFifty}
            disabledBackground={colors.grayTwenty}
            onClick={handleCSVDataList}
          >
            Submit
          </FormButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CSVUploadComponent;
