import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { Box, Divider, Grid } from "@mui/material";
import { toast } from "react-toastify";

import FieldMapper from "components/Common Components/Form Fields/FormUtils/FieldMapper";
import { StyledStatusChip } from "components/Common Components/DataTable/DatatableConfig";
import FormButton from "components/Common Components/Buttons/FormButton";
import PlayerOfSeriesDropdown from "./PlayerOfSeriesDropdown";
import EntityDropdown from "../../Common Components/Dropdowns/EntityDropdown";

import {
  editTournamentDetails,
  getPlayerListMapped,
  getTournamentTeams,
} from "services/touranments";
// import { getTeamsListMappedByTournament } from "components/Matches/helper";
import { leftSideColConfig, rightSideColConfig } from "../formConfig";
import colors from "config/colorConfig";
import { logFirebaseEvent } from "config/firebase";
import { STATUS_LIST } from "utils/constants";
import styles from "../styles.module.css";
import BackdropLoader from "components/Common Components/Loaders/LoaderBackdrop";

function EditTournamentForm() {
  const methods = useForm({
    criteriaMode: "firstError",
    mode: "onBlur",
    shouldFocusError: true,
  });
  const {
    handleSubmit,
    setValue,
    setError,
    getValues,
    formState: { isDirty },
  } = methods;

  const navigate = useNavigate();
  const { state } = useLocation();

  const [tournamentStatus, setTournamentStatus] = useState("");
  const [leftColConfig, setLeftColConfig] = useState<any>();
  const [rightColConfig, setRightColConfig] = useState<any>();
  const [loaderState, setLoaderState] = useState(false)

  const setTournamentConfig = () => {
    if (tournamentStatus === STATUS_LIST.SCHEDULED) {
      setLeftColConfig(leftSideColConfig(formConfigUpcoming, "edit"));
      setRightColConfig(rightSideColConfig(formConfigUpcoming, "edit"));
    } else if (tournamentStatus === STATUS_LIST.ONGOING) {
      setLeftColConfig(leftSideColConfig(formConfigOngoing, "edit"));
      setRightColConfig(rightSideColConfig(formConfigOngoing, "edit"));
    } else if (
      tournamentStatus === STATUS_LIST.COMPLETED ||
      tournamentStatus === STATUS_LIST.RESULT
    ) {
      setLeftColConfig(leftSideColConfig(formConfigCompleted, "edit"));
      setRightColConfig(rightSideColConfig(formConfigCompleted, "edit"));
    }
  };

  const setTournamentData = (data: any) => {
    for (const [key, value] of Object.entries(data)) {
      value && setValue(key, value);
    }
    setValue("is_active", data?.is_active);
  };

  const handleValidations = () => {
    if (
      new Date(getValues("end_date")).getTime() <
      new Date(getValues("start_date")).getTime()
    ) {
      setError(
        "end_date",
        {
          type: "custom",
          message: "End date cannot be earlier than start date!",
        },
        { shouldFocus: true }
      );
      return false;
    }
    return true;
  };

  const formSubmitHandler = async (data: any) => {
    if (!handleValidations()) return;

    setLoaderState(true)
    let result = await editTournamentDetails(data);
    if (result && !result?.isError) {
      toast.success("Successful!");
      logFirebaseEvent({
        event_name: "tournament_edit_save",
        tournament_status: tournamentStatus,
        tournament_id: state?.tournament_id,
      });
      navigate(-1);
    } else {
      toast.error(result?.reason);
    }
    setLoaderState(false)
  };

  useEffect(() => {
    if (!state) navigate("/tournament");
    setTournamentData(state);
    setTournamentStatus(state?.status);
  }, []);

  useEffect(() => {
    setTournamentConfig();
  }, [tournamentStatus]);

  const formConfigUpcoming = {
    tName: {
      disabled: false,
    },
    startDate: {
      disabled: false,
    },
    endDate: {
      disabled: false,
    },
    format: {
      disabled: false,
    },
    isActive: {
      disabled: false,
    },
    type: {
      disabled: false,
    },
    ballType: {
      disabled: false,
    },
    playerOfSeries: {
      disabled: true,
      idParam: state?.tournament_id,
    },
    uploadPhoto: {
      disabled: false,
    },
    displayFormat: {
      disabled: false,
    },
    hostCity: {
      disabled: false,
    },
    sport: {
      disabled: false,
    },
    winningTeamID: {
      disabled: true,
      idParam: state?.tournament_id,
    },
    isSubstitutionAllowed: {
      disabled: false,
    },
  };

  const formConfigOngoing = {
    tName: {
      disabled: true,
    },
    startDate: {
      disabled: true,
    },
    endDate: {
      disabled: false,
    },
    format: {
      disabled: true,
    },
    isActive: {
      disabled: false,
    },
    type: {
      disabled: false,
    },
    ballType: {
      disabled: true,
    },
    playerOfSeries: {
      disabled: true,
      idParam: state?.tournament_id,
    },
    uploadPhoto: {
      disabled: false,
    },
    displayFormat: {
      disabled: true,
    },
    hostCity: {
      disabled: false,
    },
    sport: {
      disabled: false,
    },
    winningTeamID: {
      disabled: true,
      idParam: state?.tournament_id,
    },
    isSubstitutionAllowed: {
      disabled: false,
    },
  };

  const formConfigCompleted = {
    tName: {
      disabled: true,
    },
    startDate: {
      disabled: true,
    },
    endDate: {
      disabled: true,
    },
    format: {
      disabled: true,
    },
    isActive: {
      disabled: true,
    },
    type: {
      disabled: true,
    },
    ballType: {
      disabled: true,
    },
    playerOfSeries: {
      disabled: false,
      idParam: state?.tournament_id,
      optionsFetcher: getPlayerListMapped,
      OptionsDisplayComp: PlayerOfSeriesDropdown,
    },
    uploadPhoto: {
      disabled: true,
    },
    displayFormat: {
      disabled: true,
    },
    hostCity: {
      disabled: true,
    },
    sport: {
      disabled: true,
    },
    winningTeamID: {
      disabled: false,
      idParam: state?.tournament_id,
      optionsFetcher: getTournamentTeams,
      OptionsDisplayComp: EntityDropdown,
    },
    isSubstitutionAllowed: {
      disabled: true,
    },
  };

  return (
    <>
      <div className={styles["form-heading"]}>
        <Box display="flex" alignItems="center">
          <span> Edit tournament</span> &nbsp;
          {tournamentStatus && (
            <span>{StyledStatusChip(tournamentStatus)}</span>
          )}
        </Box>

        {state && (
          <div className={styles["tournament-id"]}>
            ID - #{state?.tournament_id}
          </div>
        )}
      </div>

      <div className={styles["form-container"]}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(formSubmitHandler)}>
            <Grid container spacing={6}>
              <Grid item xs={12} md={6}>
                {leftColConfig ? (
                  <FieldMapper fieldsConfig={leftColConfig} />
                ) : (
                  <></>
                )}
              </Grid>

              <Grid item xs={12} md={6}>
                {rightColConfig ? (
                  <FieldMapper fieldsConfig={rightColConfig} />
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>

            <Divider sx={{ my: 3 }} />

            <Grid container item columnGap={3}>
              <FormButton
                type="button"
                fontColor={colors.redSixty}
                backgroundColor={colors.white}
                borderColor={colors.grayThirty}
                onClick={() => navigate(-1)}
              >
                Discard
              </FormButton>

              <FormButton
                type="submit"
                fontColor={colors.white}
                backgroundColor={colors.grayNinety}
                disabledColor={colors.grayFifty}
                disabledBackground={colors.grayTwenty}
                disabled={!isDirty}
              >
                Save changes
              </FormButton>
            </Grid>
          </form>
        </FormProvider>
      </div>
      <BackdropLoader loaderState={loaderState} />
    </>
  );
}

export default EditTournamentForm;
