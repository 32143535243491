import React from "react";
import { Box } from "@mui/material";

import colors from "config/colorConfig";

function PlayerOfSeriesDropdown(props: any) {
  return (
    <Box
      component="li"
      display="flex"
      sx={{
        "& > img": {
          mr: 2,
          flexShrink: 0,
          width: "4rem",
          borderRadius: "1rem",
        },
        fontSize: "1.4rem",
      }}
      {...props}
    >
      <img loading="lazy" width="20" height="20" src={props?.data?.image} alt="" />
      <div
        style={{
          color: colors.grayNinety,
          fontWeight: 600,
          fontSize: "1.6rem",
        }}
      >
        {props?.data?.label} &nbsp;
        <span style={{ fontWeight: 400 }}>{`(${props?.data?.shortName})`}</span>
        <div
          style={{
            color: colors.graySixty,
            fontWeight: 400,
            fontSize: "1.4rem",
          }}
        >
          ID - {props?.data?.id}
        </div>
      </div>
    </Box>
  );
}

export default PlayerOfSeriesDropdown;
