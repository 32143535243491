import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

import {
  RadioButtonLabelStyles,
  RadioButtonStyles,
} from "./FormUtils/FormStyles";

import styles from "./styles.module.css";

type singleOption = {
  label: string;
  value: any;
};

export const FormInputRadio = (props: any) => {
  const { name, label, rules, options, required, disabled } = props;
  const { control } = useFormContext();
  const generateRadioOptions = () => {
    return options?.map((singleOption: singleOption) => (
      <FormControlLabel
        value={singleOption.value}
        label={singleOption.label}
        key={singleOption.label}
        sx={RadioButtonLabelStyles}
        disabled={disabled}
        control={<Radio sx={RadioButtonStyles} />}
      />
    ));
  };
  return (
    <Box>
      <FormControl>
        <label className={styles["form-label"]}>
          {label}{" "}
          {required ? (
            <span className={styles["required-symbol"]}>*</span>
          ) : (
            <></>
          )}
        </label>
        <Controller
          name={name}
          control={control}
          defaultValue={""}
          rules={rules}
          render={({
            field: { onChange, value },
            fieldState: { error },
            formState,
          }) => (
            <>
              <RadioGroup
                value={value}
                row
                onChange={onChange}
                sx={{ ml: 2 }}
                data-testid={name}
              >
                {generateRadioOptions()}
              </RadioGroup>
              <div className={styles["error-msg"]}>
                {error?.message ? error.message : null}
              </div>
            </>
          )}
        />
      </FormControl>
    </Box>
  );
};
