export const ISACTIVE = [
  {
    label: "True",
    value: 0,
  },
  {
    label: "False",
    value: 1,
  },
];

export const GENDER = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
  { label: "Other", value: "other" },
];


