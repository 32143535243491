import { GET, GET2, PATCH, POST, PUT } from "services";
import { ENV, apiEndpoints, baseUrl } from "config/apiEndpoints";
import { BULK_UPLOAD_CHUNK_SIZE } from "utils/constants";

export const getMatchesListPaginated = async (data: any) => {
  let result = await POST(
    baseUrl[ENV] + apiEndpoints.matchesPaginatedList,
    data
  );
  return result;
};

export const getMatchDetailsById = async (matchId: string) => {
  const params = { matchId: matchId };
  let result = await GET(baseUrl[ENV] + apiEndpoints.getMatchById, params);
  return result;
};

export const getTeamListByTournament = async (tournamentId: string) => {
  let result = await GET(
    baseUrl[ENV] + apiEndpoints.getTeamsByTournament + "/" + tournamentId
  );
  return result;
};

export const getAllScorers = async () => {
  let result = await GET(baseUrl[ENV] + apiEndpoints.getAllScorers);
  return result;
};

export const createMatch = async (matchData: any) => {
  let result = await POST(baseUrl[ENV] + apiEndpoints.createMatch, matchData);
  return result;
};

export const editMatch = async (matchData: any) => {
  let result = await PUT(baseUrl[ENV] + apiEndpoints.createMatch, matchData);
  return result;
};

export const updateMatchStatus = async (matchId: string, newStatus: string) => {
  let result = await PATCH(
    baseUrl[ENV] +
      apiEndpoints.updateMatchStatus +
      "/" +
      matchId +
      "/" +
      newStatus,
    {}
  );
  return result;
};

export const getTeamsListByName = async (searchValue: string) => {
  const params = { name: searchValue };
  let result = await GET(baseUrl[ENV] + apiEndpoints.getTeamsByName, params);
  return result;
};

export const createBulkMatches = async (data: any) => {
  const chunks = [];
  for (let i = 0; i < data.length; i += BULK_UPLOAD_CHUNK_SIZE) {
    chunks.push(data.slice(i, i + BULK_UPLOAD_CHUNK_SIZE));
  }

  for (const chunk of chunks) {
    try {
      await POST(baseUrl[ENV] + apiEndpoints.createBulkMatches, chunk);
    } catch (err) {
      console.error("Error sending chunk:", err);
      throw err;
    }
  }
};

// ---------------------------- NTPC ----------------------------

export const createNtpcMatch = async (matchData: any) => {
  let result = await POST(
    baseUrl[ENV] + apiEndpoints.createNtpcMatch,
    matchData
  );
  return result;
};

export const getMatchesListNtpc = async (sportsCode: any, entityName: any) => {
  let result = await GET2(
    baseUrl[ENV] +
      apiEndpoints.getNtpcMatchesList +
      `?sportsCode=${sportsCode}&entityName=${entityName}`
  );
  return result;
};

export const getNtpcMatchDetailsById = async (matchId: string) => {
  let result = await GET2(
    baseUrl[ENV] + apiEndpoints.getNtpcMatchDetailsById + `?matchId=${matchId}`
  );
  return result;
};

export const editNtpcMatch = async (matchData: any) => {
  let result = await POST(baseUrl[ENV] + apiEndpoints.editNtpcMatch, matchData);
  return result;
};
