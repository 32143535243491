import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import OTPInput from "react-otp-input";

import { resendOtp, verifyOtp } from "services/login";
import { setAccessToken, setRefreshToken, setUserDetails } from "utils/Helper";
import { IAdminOtpLogin } from "interfaces/AuthInterfaces";
import locale from "../../locale/locale.json";
import styles from "./styles.module.css";

type OtpFormProps = {
  phone: string;
  setFormState: Function;
  otpData: IAdminOtpLogin;
};

function OtpForm(props: OtpFormProps) {
  const { phone, otpData, setFormState } = props;

  const navigate = useNavigate();

  const content = locale["en"];

  const verifyOtpErrors = {
    WRONG_OTP: "Wrong OTP!!",
    OTP_EXPIRED: "OTP Request Expired!!",
    OTP_TRIES_EXHAUSTED: "OTP Attempt Exhausted!!",
  };

  const [otp, setOtp] = useState("");
  const [wrongOtpFlag, setWrongOtpFlag] = useState(false);
  const [timerOverFlag, setTimerOverFlag] = useState(false);
  const [resendTimerFlag, setResendTimerFlag] = useState(true);

  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(otpData.data.otpResendTimer);
  const resendLimit = useRef(otpData.data.otpResendLimit);
  const resendTimer = useRef(otpData.data.otpResendTimer);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds((seconds) => seconds - 1);
      }
      if (seconds === 1) {
        if (minutes === 0) {
          setTimerOverFlag(true);
          clearInterval(interval);
          if (resendLimit.current > 0) setResendTimerFlag(false);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
          if (timerOverFlag) setTimerOverFlag(!timerOverFlag);
        }
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [seconds]);

  const resetOtpTimer = () => {
    setSeconds(resendTimer.current);
    setTimerOverFlag(false);
  };

  const handleOtpValidation = () => {
    if (otp && otp.length === otpData.data.otpLength) return true;
    return false;
  };

  const handleSuccessLogin = (loginInfo: any) => {
    let userData = {
      ...loginInfo?.data?.user_details,
      user_id: loginInfo?.data?.user_id,
    };
    if (typeof window !== "undefined") {
      setAccessToken(loginInfo?.data?.access_token);
      setRefreshToken(loginInfo?.data?.refresh_token);
      setUserDetails(JSON.stringify(userData));
      navigate("/");
    }
  };

  const handleVerifyOtp = async (e: any) => {
    e.preventDefault();
    const reqData = {
      requestId: otpData.data.requestId,
      phoneNumber: phone,
      otp: otp,
    };
    const result = await verifyOtp(reqData);
    if (result?.isError) {
      if (result.reason === verifyOtpErrors.WRONG_OTP) {
        setWrongOtpFlag(true);
        setOtp("");
      }
      // else if (result.reason === verifyOtpErrors.OTP_EXPIRED) {
      //   console.log("OTP expired! Please generate a new one")}
      else if (result.reason === verifyOtpErrors.OTP_TRIES_EXHAUSTED) {
        setFormState("otp-exhausted");
      }
    } else {
      handleSuccessLogin(result);
    }
  };

  const handleResendOtp = async () => {
    if (resendLimit.current > 0) {
      const reqData = {
        requestId: otpData.data.requestId,
      };

      const result = await resendOtp(reqData);
      if (result && !result.isError)
        resendLimit.current = resendLimit.current - 1;
      else {
        toast.error(result?.reason);
      }

      setResendTimerFlag(true);
      resetOtpTimer();
    }
    return;
  };

  return (
    <div className={styles["form-wrapper"]}>
      <form onSubmit={(e: any) => handleVerifyOtp(e)}>
        <div className={styles["form-content"]}>
          <div className={styles["form-title"]}>{content.VERIFY_HEADING}</div>
          <div style={{ position: "relative" }}>
            <div className={styles["form-label"]}>
              {content.VERIFY_TEXT1}
              <br />
              {props.phone}
            </div>

            <OTPInput
              value={otp}
              onChange={setOtp}
              numInputs={otpData.data.otpLength}
              inputType="tel"
              renderInput={(props) => (
                <input {...props} aria-label="Otp value" />
              )}
              shouldAutoFocus
              containerStyle={styles["otp-container"]}
              inputStyle={styles["otp-input"]}
            />
            {!timerOverFlag ? (
              <div
                className={`${styles["otp-timer"]} ${
                  timerOverFlag ? styles["timer-over"] : ""
                }`}
                data-testid="otp-timer"
              >
                {minutes < 10 ? `0${minutes}` : minutes}:
                {seconds < 10 ? `0${seconds}` : seconds}
              </div>
            ) : (
              <></>
            )}

            {wrongOtpFlag ? (
              <div className={styles["otp-error"]}>
                <span
                  className={styles["error-red"]}
                  data-testid="otp-error-msg"
                >
                  Wrong OTP!
                </span>
              </div>
            ) : (
              <></>
            )}
          </div>
          <button
            type="submit"
            disabled={!handleOtpValidation()}
            className={styles["submit-btn"]}
            data-testid="otp-submit-btn"
          >
            Verify OTP
          </button>
          <button
            type="button"
            disabled={resendTimerFlag}
            className={styles["otp-btn"]}
            onClick={() => handleResendOtp()}
            data-testid="otp-resend-btn"
          >
            Resend OTP
          </button>
        </div>
      </form>
    </div>
  );
}

export default OtpForm;
