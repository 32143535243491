/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import styles from "./style.module.scss";
import { toast } from "react-toastify";

// services
import { uploadImagetoS3 } from "services/tournamentsNew";

import imgUpload from "assets/img-upload.svg";

interface ImageUploadProps {
  inputKey: string;
  isRequired: boolean;
  label: string;
  presentValue: string; // Initially empty or a URL if replacing
  placeholder: string;
  isEditable?: boolean;
  isFullWidth?: boolean;
  onInputChange: any;
}

const ImageUpload = ({
  inputKey,
  isRequired,
  label,
  presentValue,
  placeholder,
  isEditable,
  isFullWidth,
  onInputChange,
}: ImageUploadProps) => {
  const [image, setImage] = useState<string | null>(presentValue || null);
  const fileInputRef = useRef<HTMLInputElement>(null); // Reference to the file input
  const [copySuccess, setCopySuccess] = useState("");

  useEffect(() => {
    setImage(presentValue || null);
  }, [presentValue]);

  // Trigger the file input when clicking on "Replace Image" button
  const handleReplaceClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleCopyLink = () => {
    if (image) {
      navigator.clipboard.writeText(image).then(
        () => {
          setCopySuccess("Link copied to clipboard!");
        },
        () => {
          setCopySuccess("Failed to copy link.");
        }
      );
    }
  };
  const handleImageUpload = async (file: File) => {
    try {
      let result = await uploadImagetoS3(file);

      if (result && !result.isError) {
        const imageUrl = result?.data?.image_url;
        setImage(imageUrl); // Set the uploaded image URL
        onInputChange(imageUrl); // Send image URL to the parent
        toast.success("Successfully Uploaded!");
      } else {
        throw new Error("Upload failed");
      }
    } catch (error) {
      toast.error("There was some error uploading! Please try again.");
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      handleImageUpload(file); // Upload to S3 and update state
    }
  };

  return (
    <div
      className={styles["image-upload-container"]}
      style={{ width: isFullWidth ? "100%" : "" }}
    >
      <div className={styles["label"]}>
        <label>
          {label}
          {isRequired && <span style={{ color: "red" }}> *</span>}
        </label>
      </div>

      {image ? (
        <div className={styles["image-preview"]}>
          <img
            src={image}
            alt="Uploaded"
            className={styles["uploaded-image"]}
          />
          <button
            type="button"
            className={styles["replace-button"]}
            onClick={handleReplaceClick}
            disabled={!isEditable}
          >
            Replace
          </button>

          <button
            type="button"
            className={styles["copy-button"]}
            onClick={handleCopyLink}
          >
            Copy Link
          </button>
        </div>
      ) : (
        <button
          type="button"
          className={styles["upload-button"]}
          onClick={handleReplaceClick}
          disabled={!isEditable}
        >
          <img src={imgUpload} alt="" />
          Upload your image
        </button>
      )}

      {/* Hidden file input */}
      <input
        ref={fileInputRef}
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        onChange={handleFileChange}
        disabled={!isEditable}
      />
    </div>
  );
};

export default ImageUpload;
