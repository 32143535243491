/* eslint-disable */
import React, { createContext, useState } from "react";
import { ThemeProvider } from "@mui/material/styles";

import AppRoutes from "./Routes/Routes";
import useResponsive from "hooks/useResponsive";
import ScreenSizeError from "pages/ScreenSizeError";
import CustomToast from "components/Common Components/Toast/CustomToast";
import ErrorBoundary from "pages/ErrorHandler";

import theme from "./styles/styles";
import "./App.css";

export const LocaleContext = createContext("");

function App() {
  const screenSizeFlag = useResponsive();
  const [locale, setLocale] = useState("en");

  if (!screenSizeFlag) return <ScreenSizeError />;
  else
    return (
      <ErrorBoundary>
        <ThemeProvider theme={theme}>
          <LocaleContext.Provider value={locale}>
            <AppRoutes />
            <CustomToast />
          </LocaleContext.Provider>
        </ThemeProvider>
      </ErrorBoundary>
    );
}
export default App;
