export const SPORTS = [
  {
    label: "Cricket",
    value: "CRICKET",
    attributes: ["cricketRole", "battingHand", "bowlingHand", "bowlingType"],
  },
  {
    label: "Football",
    value: "FOOTBALL",
    attributes: ["footballRole", "primaryFoot"],
  },
  {
    label: "Badminton",
    value: "BADMINTON",
    attributes: ["primaryHand"],
  },
];

export const CRICKET_LIST = [
  {
    label: "TURF",
    value: "turf",
  },
  {
    label: "MATTING",
    value: "matting",
  },
  {
    label: "CEMENT",
    value: "cement",
  },
  {
    label: "MUD",
    value: "mud",
  },
];

export const BADMINTON_LIST = [
  {
    label: "SYNTHETIC",
    value: "synthetic",
  },
  {
    label: "MUD",
    value: "mud",
  },
  {
    label: "CONCRETE",
    value: "concrete",
  },
];

export const FOOTBALL_LIST = [
  {
    label: "ASTRO TURF",
    value: "astroTurf",
  },
  {
    label: "GRASS",
    value: "grass",
  },
];

export const GROUND_AMENITIES = [
  {
    label: "Dugout",
    value: "dugout",
  },
  {
    label: "Showers",
    value: "showers",
  },
  {
    label: "Lockers",
    value: "lockers",
  },
  {
    label: "Gym",
    value: "gym",
  },
  {
    label: "Washrooms",
    value: "washrooms",
  },
  {
    label: "Water dispenser",
    value: "waterDispenser",
  },
  {
    label: "Wi-Fi",
    value: "wifi",
  },
  {
    label: "Match Streaming Capability",
    value: "matchStreamingCapability",
  },
  {
    label: "Rental Equipment",
    value: "rentalEquipment",
  },
  {
    label: "Seating Area",
    value: "seatingArea",
  },
  {
    label: "Umpires",
    value: "umpires",
  },
  {
    label: "Scorers",
    value: "scorers",
  },
  {
    label: "Floodlights",
    value: "floodlights",
  },
  {
    label: "Practice Nets",
    value: "practiceNets",
  },
  {
    label: "Cafeteria",
    value: "cafeteria",
  },
  {
    label: "Sight Screen",
    value: "sightScreen",
  },
  {
    label: "Balls/Shuttles",
    value: "ballsShuttles",
  },
  {
    label: "Bowling Machine",
    value: "bowlingMachine",
  },
  {
    label: "Parking",
    value: "parking",
  },
];

export const CRICKET_ATTRIBUTES = {
  PLAYERTYPE: [
    { label: "Batter", value: "BATSMAN" },
    { label: "Bowler", value: "BOWLER" },
    { label: "All-Rounder", value: "ALL_ROUNDER" },
    { label: "Wicketkeeper", value: "WICKET_KEEPER" },
  ],
  PLAYERBATTINGHAND: [
    { label: "Right Hand Batting", value: "right-hand-batting" },
    { label: "Left Hand Batting", value: "left-hand-batting" },
  ],
  PLAYERBOWLINGHAND: [
    { label: "Right Arm", value: "right-arm" },
    { label: "Left Arm", value: "left-arm" },
  ],
  PLAYERBOWLINGTYPE: [
    { label: "Fast", value: "fast" },
    { label: "Medium", value: "medium" },
    { label: "Slow", value: "slow" },
    { label: "Off Spin", value: "off-spin" },
    { label: "Leg Spin", value: "leg-spin" },
    { label: "Unorthodox Spin", value: "unorthodox-spin" },
  ],
};

export const FOOTBALL_ATTRIBUTES = {
  PLAYERTYPE: [
    { label: "Attacker", value: "ATTACKER" },
    { label: "Mid-fielder", value: "MID_FIELDER" },
    { label: "Defender", value: "DEFENDER" },
    { label: "GoalKeeper", value: "GOALKEEPER" },
  ],
  PLAYERPRIMARYFOOT: [
    { label: "Left Foot", value: "left-foot" },
    { label: "Right Foot", value: "right-foot" },
  ],
};

export const BADMINTON_ATTRIBUTES = {
  PLAYERPRIMARYHAND: [
    { label: "Left Hand", value: "left-hand" },
    { label: "Right Hand", value: "right-hand" },
  ],
};
