import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { Box, Grid } from "@mui/material";
import { toast } from "react-toastify";

import FieldMapper from "components/Common Components/Form Fields/FormUtils/FieldMapper";
import HeaderOptionsButton from "components/Common Components/Buttons/HeaderOptionsButton";
import ViewScorerMenu from "./KebabMenu";

import { leftSideColConfig, rightSideColConfig } from "../formConfig";
import { logFirebaseEvent } from "config/firebase";
import colors from "config/colorConfig";
import { getScorerDetailsById, formatGetScorerData } from "services/scorers";
import styles from "../styles.module.css";
import BackdropLoader from "components/Common Components/Loaders/LoaderBackdrop";

function ViewScorerForm() {
  const methods = useForm({
    criteriaMode: "firstError",
    mode: "onBlur",
    shouldFocusError: true,
  });

  const { setValue } = methods;

  const navigate = useNavigate();
  const { scorerId } = useParams();

  const [formData, setFormData] = useState<any>();
  const [loaderState, setLoaderState] = useState(false)

  const initializePageData = async () => {
    setLoaderState(true)
    if (scorerId) {
      let result = await getScorerDetailsById(scorerId);
      if (result && !result?.isError && result?.data.length > 0) {
        let formattedData = formatGetScorerData(result?.data[0]);
        setScorerData(formattedData);
        setFormData(formattedData);
      } else {
        toast.error(result?.reason);
      }
    } else {
      toast.error("There was some error! Please try again");
    }
    setLoaderState(false)
  };

  const setScorerData = (data: any) => {
    for (const [key, value] of Object.entries(data)) {
      value && setValue(key, value);
    }
    setValue("deleted", data?.deleted);
  };

  useEffect(() => {
    initializePageData();
  }, []);

  const formConfig = {
    name: {
      disabled: true,
    },
    email: {
      disabled: true,
    },
    contactNumber: {
      disabled: true,
    },
    city: {
      disabled: true,
    },
    gender: {
      disabled: true,
    },
    uploadPhoto: {
      disabled: true,
    },
    isActive: {
      disabled: true,
    },
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <div className={styles["form-heading"]}>
          <Box display="flex" alignItems="center">
            <span> View Scorer</span>
          </Box>
          {scorerId && (
            <div className={styles["scorer-id"]}>ID - #{scorerId}</div>
          )}
        </div>

        <div className={styles["header-options"]}>
          <HeaderOptionsButton
            fontColor={colors.grayNinety}
            backgroundColor={colors.white}
            borderColor={colors.grayThirty}
            onClick={() => {
              logFirebaseEvent({
                event_name: "scorer_edit",
                scorer_id: scorerId,
              });
              navigate("/scorer/edit/" + scorerId, {
                state: { scorerData: formData },
              });
            }}
          >
            Edit details
          </HeaderOptionsButton>
          <ViewScorerMenu selectedScorerData={formData} />
        </div>
      </Box>

      <div className={styles["form-container"]}>
        <FormProvider {...methods}>
          <form>
            <Grid container columnSpacing={6}>
              <Grid item xs={12} md={6}>
                <FieldMapper
                  fieldsConfig={leftSideColConfig(formConfig, "view")}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FieldMapper
                  fieldsConfig={rightSideColConfig(formConfig, "view")}
                />
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </div>
      <BackdropLoader loaderState={loaderState} />
    </>
  );
}

export default ViewScorerForm;
