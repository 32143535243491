import React from "react";
import { Box } from "@mui/material";

import Error from "assets/screens.svg"

function ScreenSizeError() {
  return (
    <>
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <img src={Error} alt="error" width="70%" />
      <div
        style={{
          fontSize: "2.2rem",
          fontWeight: "600",
          marginTop: "2rem",
          textAlign:"center"
        }}
      >
        We only support laptop or larger screens.
        <br />
        Sorry for the inconvenience!
      </div>
    </Box>
  </>
  );
}

export default ScreenSizeError;
