import { FieldType } from "components/Common Components/Form Fields/FieldAttributes";
import {
  ISACTIVE,
  SPORTS,
  SPORTSTYPE,
  MATCHTYPENTPC,
  MATCHSTATUSNTPC,
} from "./MatchConstants";

export const leftSideColConfig = (formConfig: any, formType?: string) => {
  const config = [
    [
      {
        type: FieldType.TEXT,
        label: "Team A name",
        name: "teamAName",
        placeholder: "Team A name",
        disabled: false,
        required: true,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.TEXT,
        label: "Team B name",
        name: "teamBName",
        placeholder: "Team B name",
        disabled: false,
        required: true,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.RADIO,
        label: "isActive",
        name: "isActive",
        disabled: false,
        required: true,
        options: ISACTIVE,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.RADIO,
        label: "Select Sport",
        name: "sportsCode",
        disabled: false,
        required: true,
        options: SPORTS,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.SELECT,
        label: "Sport Type",
        name: "sportsType",
        placeholder: "Enter sport type",
        disabled: false,
        required: true,
        options: SPORTSTYPE,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.SELECT,
        label: "Match Type",
        name: "matchType",
        placeholder: "Enter match type",
        disabled: false,
        required: true,
        options: MATCHTYPENTPC,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.TEXT,
        label: "Match Number",
        name: "matchNumber",
        placeholder: "Enter Match Number",
        disabled: false,
        required: true,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.TEXT,
        label: "Match Sequence Number",
        name: "sequenceNumber",
        placeholder: "Enter Sequence Number",
        disabled: false,
        required: true,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.TEXT,
        label: "Match Name",
        name: "matchName",
        placeholder: "Enter Match Name",
        disabled: false,
        required: true,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
  ];
  return config;
};

export const rightSideColConfig = (formConfig: any, formName?: string) => {
  const config = [
    [
      {
        type: FieldType.TIME,
        label: "Start Time",
        name: "start_time",
        placeholder: "DD/MM/YYYY HH:mm:ss",
        disabled: false,
        required: false,
        // rules: {
        //   required: {
        //     value: true,
        //     message: "This field is mandatory.",
        //   },
        // },
      },
    ],

    [
      {
        type: FieldType.TEXT,
        label: "Youtube Streaming Link",
        name: "streamingLink",
        placeholder: "Enter URL",
        disabled: false,
        required: false,
        rules: {},
      },
    ],

    [
      {
        type: FieldType.TEXT,
        label: "Scoring Link",
        name: "scoringLink",
        placeholder: "Enter URL",
        disabled: false,
        required: false,
        rules: {},
      },
    ],
    [
      {
        type: FieldType.TEXT,
        label: "TEAM A LINEUP",
        name: "teamALineUp",
        placeholder: "Enter URL",
        disabled: false,
        required: false,
        rules: {},
      },
    ],
    [
      {
        type: FieldType.TEXT,
        label: "TEAM B LINEUP",
        name: "teamBLineUp",
        placeholder: "Enter URL",
        disabled: false,
        required: false,
        rules: {},
      },
    ],

    [
      {
        type: FieldType.TEXT,
        label: "TEAM A IMAGE LINK",
        name: "teamAImage",
        placeholder: "Enter URL",
        disabled: false,
        required: false,
        rules: {},
      },
    ],

    [
      {
        type: FieldType.TEXT,
        label: "TEAM B IMAGE LINK",
        name: "teamBImage",
        placeholder: "Enter URL",
        disabled: false,
        required: false,
        rules: {},
      },
    ],

    [
      {
        type: FieldType.TEXT,
        label: "TEAM A SCORE",
        name: "teamAScore",
        placeholder: "Enter Team A Score",
        disabled: false,
        required: false,
        rules: {},
      },
    ],

    [
      {
        type: FieldType.TEXT,
        label: "TEAM B SCORE",
        name: "teamBScore",
        placeholder: "Enter Team B Score",
        disabled: false,
        required: false,
        rules: {},
      },
    ],

    [
      {
        type: FieldType.TEXT,
        label: "WINNING TEAM",
        name: "winningTeam",
        placeholder: "Enter Winning Team",
        disabled: false,
        required: false,
        rules: {},
      },
    ],

    [
      {
        type: FieldType.SELECT,
        label: "Match Status",
        name: "status",
        placeholder: "Enter match status",
        disabled: false,
        required: false,
        options: MATCHSTATUSNTPC,
      },
    ],
  ];
  return config;
};
