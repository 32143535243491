import React from "react";

import SideDrawer from "components/Common Components/SideDrawer/SideDrawer";
import CreatePlayerForm from "components/Players/CreatePlayer/CreatePlayerForm";

function CreatePlayer() {
  return (
    <>
      <SideDrawer>
        <div style={{ padding: "3.2rem" }}>
          <CreatePlayerForm />
        </div>
      </SideDrawer>
    </>
  );
}

export default CreatePlayer;
