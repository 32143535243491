import { ENV, apiEndpoints, baseUrl } from "config/apiEndpoints";
import { GET, POST } from "services";
import { HandleLogOut, getAccessToken } from "utils/Helper";
import { TOURNAMENT_PAGE_SIZE } from "utils/constants";

// tournaments V3

export const uploadImagetoS3 = async (file: any) => {
  var headers = new Headers();
  headers.append("accept", "*/*");
  headers.append("authorization", `Bearer ${getAccessToken()}`);

  var formdata = new FormData();
  formdata.append("doc_type", "IMAGE");
  formdata.append("file", file);

  let response: any;

  try {
    response = await fetch(baseUrl[ENV] + apiEndpoints.uploadAsset, {
      method: "POST",
      headers: headers,
      body: formdata,
      redirect: "follow",
    });
  } catch (error) {
    console.log(error);
    return;
  }
  if (response?.status === 401) {
    HandleLogOut();
    return;
  }
  const resultText = await response?.text();
  const result = resultText === "" ? {} : JSON.parse(resultText);
  return result;
};

export const getTournamentsListPaginated = async (data: any) => {
  if (!Object.keys(data).length) {
    data = {
      page: 1,
      pageSize: TOURNAMENT_PAGE_SIZE,
    };
  }
  let result = await POST(
    baseUrl[ENV] + apiEndpoints.tournamentsPaginatedListNew,
    data
  );
  return result;
};

export const getTournamentConfig = async () => {
  let result = await GET(baseUrl[ENV] + apiEndpoints.getTournamentV3Config);
  return result;
};

export const getTournamentConfigById = async (tournamentId: string) => {
  const params = { tournamentId: tournamentId };
  let result = await GET(
    baseUrl[ENV] + apiEndpoints.getTournamentV3Config,
    params
  );
  return result;
};

export const createTournament = async (data: any) => {
  let result = await POST(baseUrl[ENV] + apiEndpoints.createTournamentV3, data);
  return result;
};

export const getVenueListPaginated = async (reqData: any) => {
  if (!Object.keys(reqData).length) {
    reqData = {
      page: 1,
      pageSize: 50,
    };
  }
  let result = await POST(
    baseUrl[ENV] + apiEndpoints.groundsPaginatedList,
    reqData
  );
  return result;
};
