import React, { useEffect, useState} from "react";

import Box from "@mui/material/Box";
import {
  GridColDef,
  GridEventListener,
  GridColumnVisibilityModel,
  GridSortModel,
} from "@mui/x-data-grid";

import { CustomDataTable } from "./DatatableConfig";
import SortAsc from "assets/SortAscending.svg";
import SortDesc from "assets/SortDescending.svg";

function SortAscIcon() {
  return <img src={SortAsc} alt="icon" />;
}

function SortDescIcon() {
  return <img src={SortDesc} alt="icon" />;
}

type Props = {
  data: any;
  columns: GridColDef[];
  totalRows: number;
  pageSize: number;
  visibleColumns?: any;
  onRowClick: Function;
  sortPaginationHandler: Function;
};

export default function DataTable(props: Props) {
  const {
    data,
    columns,
    totalRows,
    visibleColumns,
    onRowClick,
    pageSize,
    sortPaginationHandler,
  } = props;

  const [initialRender, setInitialRender] = useState(true);

  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>(visibleColumns);

  const [paginationModel, setPaginationModel] = useState({
    pageSize: pageSize,
    page: 0,
  });

  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: "", sort: null },
  ]);

  const handleRowClickEvent: GridEventListener<"rowClick"> = (params: any) => {
    onRowClick(params);
  };

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      return;
    }
    sortPaginationHandler(
      paginationModel.page + 1,
      paginationModel.pageSize,
      sortModel[0]?.field,
      sortModel[0]?.sort
    );
  }, [paginationModel]);

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      return;
    }
    setPaginationModel({ page: 0, pageSize: paginationModel.pageSize });
  }, [sortModel]);

  return (
    <Box sx={{ width: "100%" }}>
      <CustomDataTable
        rows={data}
        columns={columns}
        disableRowSelectionOnClick
        rowSelection={false}
        onRowClick={handleRowClickEvent}
        sortModel={sortModel}
        onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={[pageSize ? pageSize : 20]}
        rowCount={totalRows}
        slots={{
          columnSortedDescendingIcon: SortAscIcon,
          columnSortedAscendingIcon: SortDescIcon,
          //   pagination: CustomPagination,
        }}
        slotProps={{
          pagination: {
            labelDisplayedRows(paginationInfo) {
              return (
                <span>
                  Showing {paginationInfo.from}-{paginationInfo.to} of{" "}
                  {paginationInfo.count} items{" "}
                </span>
              );
            },
          },
        }}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
        }
        columnVisibilityModel={
          columnVisibilityModel ? columnVisibilityModel : {}
        }
        onColumnVisibilityModelChange={(newConfig: GridColumnVisibilityModel) =>
          setColumnVisibilityModel(newConfig)
        }
        sx={{ fontSize: "1.4rem", borderRadius: "1.2rem", width: "100%" }}
      />
    </Box>
  );
}
