import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { toast } from "react-toastify";

import FieldMapper from "components/Common Components/Form Fields/FormUtils/FieldMapper";
import HeaderOptionsButton from "components/Common Components/Buttons/HeaderOptionsButton";

import { formatGetPlayerData, getPlayerDetailsById } from "services/players";
import { badmintonColConfig, cricketColConfig, footballColConfig, personalDetailsLeftColConfig, personalDetailsMiddleColConfig, personalDetailssRightColConfig, sportsSectionConfig } from "../formConfig";
import { logFirebaseEvent } from "config/firebase";
import colors from "config/colorConfig";
import styles from "../styles.module.css";
import BackdropLoader from "components/Common Components/Loaders/LoaderBackdrop";

function ViewPlayerForm() {
  const methods = useForm({
    criteriaMode: "firstError",
    mode: "onBlur",
    shouldFocusError: true,
  });

  const { setValue } = methods;

  const navigate = useNavigate();
  const { playerId } = useParams();

  const [formData, setFormData] = useState<any>();
  const [loaderState, setLoaderState] = useState(false);
  const [selectedSports, setSelectedSports] = useState<string[]>([]);

  const initializePageData = async () => {
    setLoaderState(true);
    if (playerId) {
      let result = await getPlayerDetailsById(playerId);
      if (result && !result?.isError) {
        let formattedData = formatGetPlayerData({
          ...result?.data?.playerDetails,
          teams: result?.data?.teams,
          playerAttributes: result?.data?.playerAttributes,
        });
        setPlayerData(formattedData);
        setFormData(formattedData);
        setSelectedSports(formattedData.playerSports || [])
      } else {
        toast.error(result?.reason);
      }
    } else {
      toast.error("There was some error! Please try again");
    }
    setLoaderState(false);
  };

  const setPlayerData = (data: any) => {
    for (const [key, value] of Object.entries(data)) {
      value && setValue(key, value);
    }
    setValue("is_active", data?.is_active);
    setValue("state", data?.state?.label || '');
    setValue("city", data?.city?.label || '');
    
    if(data?.playerSportsOptions){
      setValue('sports', data?.playerSportsOptions);
    }
    if(data?.playerSports && data?.playerSportsConfig){
      data.playerSports.forEach((sport: string )=>{
        data.playerSportsConfig[sport].attributes.forEach((attr:any)=>{
          setValue(attr.fieldName, attr.attributeValue)
        })
        setValue(`${sport.toLowerCase()}_teams`, data.playerSportsConfig[sport].teams)
      })
    }
  };

  useEffect(() => {
    initializePageData();
  }, []);

  const formConfig = {
    name: {
      disabled: true,
    },
    displayName: {
      disabled: true,
    },
    contactNumber: {
      disabled: true,
    },
    state: {
      disabled: true,
    },
    city: {
      disabled: true,
    },
    playerDateOfBirth: {
      disabled: true,
    },
    uploadPhoto: {
      disabled: true,
    },
    isActive: {
      disabled: true,
    },
    gender: {
      disabled: true,
    },
    sports: {
      disabled: true,
    },
  };

  const cricketFormConfig = useMemo(() =>{
    return {
      playerTeam: {
        disabled: true,
      },
      playerType: {
        disabled: true,
      },
      playerBattingHand: {
        disabled: true,
      },
      playerBowlingHand: {
        disabled: true,
      },
      playerBowlingType: {
        disabled: true,
      },
  }
  },[]);

  const footballFormConfig = useMemo(() =>{
    return {
      playerTeam: {
        disabled: true,
      },
      playerType: {
        disabled: true,
      },
      playerPrimaryFoot: {
        disabled: true,
      }
  }
  },[]);

  const badmintonFormConfig = useMemo(() =>{
    return {
      playerTeam: {
        disabled: true,
      },
      playerPrimaryHand: {
        disabled: true,
      }
  }
  },[]);
  
  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <div className={styles["form-heading"]}>
          <Box display="flex" alignItems="center">
            <span> View Player</span>
          </Box>
        </div>

        <div className={styles["header-options"]}>
          <HeaderOptionsButton
            fontColor={colors.grayNinety}
            backgroundColor={colors.white}
            borderColor={colors.grayThirty}
            onClick={() => {
              logFirebaseEvent({
                event_name: "player_edit",
                player_id: playerId,
              });
              navigate("/player/edit/" + playerId, {
                state: { playerData: formData },
              });
            }}
          >
            Edit details
          </HeaderOptionsButton>
        </div>
      </Box>

      <div className={styles["form-container"]}>
        <FormProvider {...methods}>
          <form>
          <Grid container columnSpacing={6}>
              <Grid item xs={12} md={4}>
                <FieldMapper
                  fieldsConfig={personalDetailsLeftColConfig(formConfig, "view")}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <FieldMapper
                  fieldsConfig={personalDetailsMiddleColConfig(formConfig, "view")}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <FieldMapper
                  fieldsConfig={personalDetailssRightColConfig(formConfig, "view")}
                />
              </Grid>
            </Grid>

            <Divider sx={{ mt: 2 ,mb: 0}} />
            
            <Grid container columnSpacing={6}>
              <Grid item xs={12} md={8}>
                <FieldMapper
                  fieldsConfig={sportsSectionConfig(formConfig, "view")}
                />
              </Grid>
               {selectedSports.includes('CRICKET') && (
                <Grid item xs={12} md={8}>
                  <Typography sx={{ mt: 2, fontSize: 16, color: 'blue'}}>
                    1. CRICKET
                  </Typography>
                  <FieldMapper
                    fieldsConfig={cricketColConfig(cricketFormConfig, "view")}
                  />
                </Grid>
              )}
              {selectedSports.includes('FOOTBALL') && (
                <Grid item xs={12} md={8}>
                  <Typography sx={{ mt: 2, fontSize: 16, color: 'blue'}}>
                    2. FOOTBALL
                  </Typography>
                  <FieldMapper
                    fieldsConfig={footballColConfig(footballFormConfig, "view")}
                  />
                </Grid>
              )}
              {selectedSports.includes('BADMINTON') && (
                <Grid item xs={12} md={8}>
                  <Typography sx={{ mt: 2, fontSize: 16, color: 'blue'}}>
                    3. BADMINTON
                  </Typography>
                  <FieldMapper
                    fieldsConfig={badmintonColConfig(badmintonFormConfig, "view")}
                  />
                </Grid>
              )}
            </Grid>
          </form>
        </FormProvider>
      </div>
      <BackdropLoader loaderState={loaderState} />
    </>
  );
}

export default ViewPlayerForm;
