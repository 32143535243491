/* eslint-disable no-unused-vars */
import React, { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { useForm, FormProvider } from "react-hook-form";
import { Divider, Grid, Typography } from "@mui/material";
import { toast } from "react-toastify";

import FieldMapper from "components/Common Components/Form Fields/FormUtils/FieldMapper";
import FormButton from "components/Common Components/Buttons/FormButton";
import EntityDropDown from "components/Common Components/Dropdowns/EntityDropdown";
import BasicModal from "components/Common Components/Modal/BasicModal";
import DiscardModal from "components/Common Components/Modal/DiscardModal";

import { 
  personalDetailsLeftColConfig,
  personalDetailsMiddleColConfig,
  personalDetailssRightColConfig,
  sportsSectionConfig,
  cricketColConfig,
  badmintonColConfig,
  footballColConfig, 
} from "../formConfig";
import { createPlayer, formatPostPlayerData } from "services/players";
import { getTeamsListMapped } from "components/Matches/helper";
import { logFirebaseEvent } from "config/firebase";
import colors from "config/colorConfig";
import styles from "../styles.module.css";
import BackdropLoader from "components/Common Components/Loaders/LoaderBackdrop";
import { getAllStateListMapped, getCitiesListMapped } from "components/Common Components/Location/helper";
import { getAllSportsListMapped } from "components/Common Components/Sports/helper";

function CreatePlayerForm() {
  const methods = useForm({
    criteriaMode: "firstError",
    mode: "onBlur",
    shouldFocusError: true,
  });

  const {
    setValue,
    handleSubmit,
    formState: { isValid, isDirty },
  } = methods;

  const navigate = useNavigate();

  const [modalState, setModalState] = useState(false);
  const [loaderState, setLoaderState] = useState(false);
  const [selectedState, setSelectedState] =  useState<string>('');
  const [selectedSports, setSelectedSports] = useState<string[]>([]);

  const onStateValueChange = useCallback((item: any) => {
    setSelectedState(item.id);
    setValue("city", {id: " ",label:" "});
  },[setSelectedState]);

  const onSportsValueChange = useCallback((values: any) => {
    setSelectedSports(values.map((val: any)=> val.id));
  },[setSelectedSports]);
  
  const formSubmitHandler = async (formData: any) => {
    setLoaderState(true);
    const age =
      new Date().getFullYear() - new Date(formData["dob"]).getFullYear();
    formData["age"] = age;

    formData["is_active"] = Number(formData["is_active"]);

    const formattedData = formatPostPlayerData(formData);

    // console.log("this is the formatted data: ", formattedData);

    const result = await createPlayer(formattedData);

    // console.log("this is the result: ", result);
    if (result && !result?.isError) {
      const playerId = result?.data?.player_id;
      logFirebaseEvent({
        event_name: "player_add_save",
        player_id: playerId,
      });
      toast.success("Player created successfully!");
      navigate("/players");
    } else {
      toast.error(result?.reason);
    }
    setLoaderState(false);
  };

  const formConfig =  useMemo(() => {
    return {
      name: {
        disabled: false,
      },
      displayName: {
        disabled: false,
      },
      contactNumber: {
        disabled: false,
      },
      state: {
        disabled: false,
        optionsFetcher: getAllStateListMapped,
        OptionsDisplayComp: EntityDropDown,
        onValueChange: onStateValueChange
      },
      city: {
        disabled: false,
        optionsFetcher: getCitiesListMapped,
        OptionsDisplayComp: EntityDropDown,
        idParam: selectedState
      },
      playerDateOfBirth: {
        disabled: false,
      },
      uploadPhoto: {
        disabled: false,
      },
      isActive: {
        disabled: false,
      },
      gender: {
        disabled: false,
      },
      linkedin_handle: {
        disabled: false,
      },
      instagram_handle: {
        disabled: false,
      },
      facebook_handle: {
        disabled: false,
      },
      idol: {
        disabled: false,
      },
      sports: {
        disabled: false,
        optionsFetcher: getAllSportsListMapped,
        OptionsDisplayComp: EntityDropDown,
        populateOptions: true,
        onValueChange: onSportsValueChange,
      }
    };
  },[selectedState, onStateValueChange]);

  const cricketFormConfig = useMemo(() =>{
    return {
      playerTeam: {
        disabled: false,
        optionsFetcher: getTeamsListMapped,
        OptionsDisplayComp: EntityDropDown,
      },
      playerType: {
        disabled: false,
      },
      playerBattingHand: {
        disabled: false,
      },
      playerBowlingHand: {
        disabled: false,
      },
      playerBowlingType: {
        disabled: false,
      },
  }
  },[]);

  const footballFormConfig = useMemo(() =>{
    return {
      playerTeam: {
        disabled: false,
        optionsFetcher: getTeamsListMapped,
        OptionsDisplayComp: EntityDropDown,
      },
      playerType: {
        disabled: false,
      },
      playerPrimaryFoot: {
        disabled: false,
      }
  }
  },[]);

  const badmintonFormConfig = useMemo(() =>{
    return {
      playerTeam: {
        disabled: false,
        optionsFetcher: getTeamsListMapped,
        OptionsDisplayComp: EntityDropDown,
      },
      playerPrimaryHand: {
        disabled: false,
      }
  }
  },[]);

  return (
    <>
      <div className={styles["form-heading"]}>Add players</div>

      <div className={styles["form-container"]}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(formSubmitHandler)}>
            <Grid container columnSpacing={6}>
              <Grid item xs={12} md={4}>
                <FieldMapper
                  fieldsConfig={personalDetailsLeftColConfig(formConfig, "create")}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <FieldMapper
                  fieldsConfig={personalDetailsMiddleColConfig(formConfig, "create")}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <FieldMapper
                  fieldsConfig={personalDetailssRightColConfig(formConfig, "create")}
                />
              </Grid>
            </Grid>

            <Divider sx={{ mt: 2 ,mb: 0}} />
            
            <Grid container columnSpacing={6}>
              <Grid item xs={12} md={8}>
                <FieldMapper
                  fieldsConfig={sportsSectionConfig(formConfig, "create")}
                />
              </Grid>
              {selectedSports.includes('CRICKET') && (
                <Grid item xs={12} md={8}>
                  <Typography sx={{ mt: 2, fontSize: 16, color: 'blue'}}>
                    1. CRICKET
                  </Typography>
                  <FieldMapper
                    fieldsConfig={cricketColConfig(cricketFormConfig, "create")}
                  />
                </Grid>
              )}
              {selectedSports.includes('FOOTBALL') && (
                <Grid item xs={12} md={8}>
                  <Typography sx={{ mt: 2, fontSize: 16, color: 'blue'}}>
                    2. FOOTBALL
                  </Typography>
                  <FieldMapper
                    fieldsConfig={footballColConfig(footballFormConfig, "create")}
                  />
                </Grid>
              )}
              {selectedSports.includes('BADMINTON') && (
                <Grid item xs={12} md={8}>
                  <Typography sx={{ mt: 2, fontSize: 16, color: 'blue'}}>
                    3. BADMINTON
                  </Typography>
                  <FieldMapper
                    fieldsConfig={badmintonColConfig(badmintonFormConfig, "create")}
                  />
                </Grid>
              )}
            </Grid>
            
            <Divider sx={{ my: 3 }} />

            <Grid container item columnGap={3}>
              <FormButton
                type="button"
                fontColor={colors.redSixty}
                backgroundColor={colors.white}
                borderColor={colors.grayThirty}
                onClick={() =>
                  !isDirty ? navigate("/players") : setModalState(!modalState)
                }
              >
                Discard
              </FormButton>

              <FormButton
                type="submit"
                fontColor={colors.white}
                backgroundColor={colors.grayNinety}
                disabledColor={colors.grayFifty}
                disabledBackground={colors.grayTwenty}
                disabled={!isValid}
              >
                Save player
              </FormButton>
            </Grid>
          </form>
        </FormProvider>
      </div>
      <BasicModal modalState={modalState} handleModalState={setModalState}>
        <DiscardModal
          modalState={modalState}
          handleModalState={setModalState}
        />
      </BasicModal>
      <BackdropLoader loaderState={loaderState} />
    </>
  );
}

export default CreatePlayerForm;
