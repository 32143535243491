/*eslint-disable*/
import React from "react";
import { GridColDef } from "@mui/x-data-grid";
import {
  StyledBoolean,
  ValueWithCopy,
} from "components/Common Components/DataTable/DatatableConfig";

export const ScorerListConfig: GridColDef[] = [
  {
    field: "name",
    headerName: "Scorer Name",
    flex: 1,
    disableColumnMenu: true,
    hideable: false,
  },
  {
    field: "scorerId",
    headerName: "ID",
    flex: 2,
    disableColumnMenu: true,
    hideable: false,
    renderCell: (params: any) => {
      return ValueWithCopy(params.value);
    },
  },
  {
    field: "city",
    headerName: "City",
    flex: 1,
    disableColumnMenu: true,
    hideable: false,
  },
  {
    field: "gender",
    headerName: "Gender",
    flex: 1,
    disableColumnMenu: true,
    hideable: false,
    renderCell: (params: any) => {
      return <>{params.value?.toUpperCase()}</>;
    },
  },
  {
    field: "deleted",
    headerName: "IS ACTIVE",
    sortable: true,
    flex: 1,
    disableColumnMenu: true,
    renderCell: (params: any) => {
      const value = params.value === 0 ? 1 : 0;
      return StyledBoolean(value);
    },
  },
];
