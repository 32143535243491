import { FieldType } from "components/Common Components/Form Fields/FieldAttributes";

export const leftSideColConfig = (formConfig: any, formType?: string) => {
  const config = [
    [
      {
        type: FieldType.TEXT,
        label: "Team name",
        name: "name",
        placeholder: "Enter team name",
        disabled: formConfig.teamName.disabled,
        required: true,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.TEXT,
        label: "Team display name",
        name: "short_name",
        placeholder: "Enter display name",
        disabled: formConfig.teamDisplayName.disabled,
        required: true,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
          maxLength: {
            value: 10,
            message: "Max. character limit is 10",
          },
        },
      },
    ],
    [
      {
        type: FieldType.TEXT,
        label: "Ticker display name",
        name: "compact_name",
        placeholder: "Enter Ticker display name",
        disabled: formConfig.tickerDisplayName.disabled,
        required: true,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
          maxLength: {
            value: 10,
            message: "Max. character limit is 10",
          },
        },
      },
    ],
    [
      {
        type: formType !== "view" ? FieldType.AUTOCOMPLETE : FieldType.TEXT,
        label: "State",
        name: "state",
        placeholder: "Enter State",
        disabled: formConfig?.state?.disabled || false,
        optionsFetcher: formConfig?.state?.optionsFetcher,
        OptionsDisplayComp: formConfig?.state?.OptionsDisplayComp,
        onValueChange: formConfig?.state?.onValueChange,
        required: true,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: formType !== "view" ? FieldType.AUTOCOMPLETELIVE : FieldType.TEXT,
        label: "City",
        name: "city_id",
        placeholder: "Enter city",
        disabled: formConfig.city.disabled,
        optionsFetcher: formConfig?.city?.optionsFetcher,
        OptionsDisplayComp: formConfig?.city?.OptionsDisplayComp,
        idParam: formConfig?.city?.idParam,
        required: true,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.RADIO,
        label: "isActive",
        name: "is_active",
        disabled: formConfig.isActive.disabled,
        required: true,
        options: ISACTIVE,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
  ];
  return config;
};

export const rightSideColConfig = (formConfig: any, formName?: string) => {
  const config = [
    [
      {
        type: FieldType.FILE,
        label: "Upload Photo",
        name: "image_url",
        disabled: formConfig.uploadPhoto.disabled,
        required: true,
        formType: "TEAM_VIEW",
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.TEXT,
        label: "Facebook",
        name: "facebook_handle",
        placeholder: "Enter facebook handle",
        disabled: formConfig.facebook.disabled,
        required: false,
        rules: {
          required: {
            value: false,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.TEXT,
        label: "Instagram",
        name: "instagram_handle",
        placeholder: "Enter instagram handle",
        disabled: formConfig.instagram.disabled,
        required: false,
        rules: {
          required: {
            value: false,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.TEXT,
        label: "Linkedin",
        name: "linkedin_handle",
        placeholder: "Enter linkedin handle",
        disabled: formConfig.linkedin.disabled,
        required: false,
        rules: {
          required: {
            value: false,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.COLOR,
        label: "Primary Color",
        name: "primary_color",
        placeholder: "Enter primary color hexcode",
        disabled: formConfig.primaryColor.disabled,
        required: false,
        rules: {
          required: {
            value: false,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.COLOR,
        label: "Secondary Color",
        name: "secondary_color",
        placeholder: "Enter secondary color hexcode",
        disabled: formConfig.secondaryColor.disabled,
        required: false,
        rules: {
          required: {
            value: false,
            message: "This field is mandatory.",
          },
        },
      },
    ],
  ];
  return config;
};

const ISACTIVE = [
  {
    label: "True",
    value: 1,
  },
  {
    label: "False",
    value: 0,
  },
];

// const sportsCode = [
//   {
//     label: "Cricket",
//     value: "CRICKET",
//   },
//   {
//     label: "Football",
//     value: "FOOTBALL",
//   },
//   {
//     label: "Badminton",
//     value: "BADMINTON",
//   },
// ];
