import React from "react";
import { Box } from "@mui/material";

import FormButton from "components/Common Components/Buttons/FormButton";
import colors from "config/colorConfig";
import { STATUS_LIST } from "utils/constants";
import CancelIcon from "assets/CrossGray.svg";

type Props = {
  modalState: boolean;
  handleModalState: Function;
  handleMatchStatus: Function;
};

function AbondonMatchModal({
  modalState,
  handleModalState,
  handleMatchStatus,
}: Props) {
  return (
    <>
      <Box textAlign="center">
        <img src={CancelIcon} alt="icon" />
        <p style={{ fontSize: "2rem" }}>
          Are you sure you want to abondon this match?
        </p>

        <Box display="flex" justifyContent="center" gap={2}>
          <FormButton
            type="button"
            fontColor={colors.grayNinety}
            backgroundColor={colors.white}
            borderColor={colors.grayThirty}
            onClick={() => handleModalState(false)}
          >
            I changed my mind, Go back
          </FormButton>

          <FormButton
            type="button"
            fontColor={colors.white}
            backgroundColor={"#000"}
            onClick={() => {
              handleMatchStatus(STATUS_LIST.ABONDENED);
              handleModalState(false);
            }}
          >
            Yes, abondon this match
          </FormButton>
        </Box>
      </Box>
    </>
  );
}

export default AbondonMatchModal;
