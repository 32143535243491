import React from "react";
import { Box } from "@mui/material";

import emptyBox from "assets/emptyBox.svg";

function EmptyList({ message }: { message?: string }) {
  return (
    <>
      <Box display="flex" justifyContent="center" alignContent="center">
        <img src={emptyBox} alt="empty-list" />
      </Box>
      <Box textAlign="center" mt={3}>
        {message ? <h1 style={{ fontSize: "4rem" }}>{message}</h1> : <></>}
      </Box>
    </>
  );
}

export default EmptyList;
