import React from "react";

import TextInput from "components/InputComponentsMultiStep/Text";
import NumberInput from "components/InputComponentsMultiStep/Number";
import RadioSelect from "components/InputComponentsMultiStep/RadioSelect";
import SingleSelectDropdown from "components/InputComponentsMultiStep/SingleSelectDropdown";
import SearchableMultiSelectDropdown from "components/InputComponentsMultiStep/SearchableMultiSelectDropdown";
import DatePicker from "components/InputComponentsMultiStep/DatePicker";
import ImageUpload from "components/InputComponentsMultiStep/ImageUpload";
import CustomInput from "components/InputComponentsMultiStep/CustomInput";

import FormMultiselectLive from "components/InputComponentsMultiStep/MultiSelectLive";

import { getTeamsListMapped } from "components/Matches/helper";

// import { getAllAmenitiesListMapped } from "components/Common Components/Sports/helper";

// import MultiSelectDropdown from "components/InputComponentsMultiStep/MultiSelectDropdown";

const getSearchableOptions = (dropdownOptions: any, inputKey: string) => {
  switch (inputKey) {
    case "venue":
      return dropdownOptions.venue;
    default:
      return [];
  }
};

const optionsMapper: any = {
  team: getTeamsListMapped,
};

export const RenderField = (
  field: any,
  formData: any,
  dropdownOptions: any,
  handleInputChange: any,
  control?: any,
  unregister?: any,
  formType?: string,
  isFullWidth?: boolean
) => {
  const commonProps2 = {
    key: field.inputKey,
    inputKey: field.inputKey,
    label: field?.widgetAttributes?.label,
    placeholder: field?.widgetAttributes?.details?.hint,
    presentValue: formData,
    isRequired: field?.widgetAttributes?.isMandatory,
    isEditable:
      formType === "view" ? false : field?.widgetAttributes?.isEditable,
    onInputChange: handleInputChange,
  };

  switch (field.widgetType) {
    case "inputText":
      return (
        <TextInput // handle change done
          {...commonProps2}
          maxLength={field?.widgetAttributes?.details?.maxLength}
          isFullWidth={isFullWidth}
        />
      );

    case "inputInt":
      return (
        <NumberInput // handle change done
          {...commonProps2}
          maxLength={field?.widgetAttributes?.details?.maxValue}
          isFullWidth={isFullWidth}
        />
      );

    case "datePicker": // handle change done
      return <DatePicker {...commonProps2} isFullWidth={isFullWidth} />;

    case "singleSelect":
      if (field?.widgetAttributes?.details?.options?.length <= 3) {
        return (
          <RadioSelect // handle change done
            {...commonProps2}
            options={field?.widgetAttributes?.details?.options}
            isFullWidth={isFullWidth}
          />
        );
      } else {
        return (
          <SingleSelectDropdown // handle change done
            {...commonProps2}
            options={field?.widgetAttributes?.details?.options}
            isFullWidth={isFullWidth}
          />
        );
      }

    case "multiSelectSearch":
      return (
        <SearchableMultiSelectDropdown // handle change done
          {...commonProps2}
          options={getSearchableOptions(dropdownOptions, field.inputKey)}
          isFullWidth={isFullWidth}
        />
      );

    case "imgUpload": // handle change done
      return <ImageUpload {...commonProps2} isFullWidth={isFullWidth} />;

    case "custom":
      return (
        <CustomInput
          {...commonProps2}
          componentArray={field.componentArray}
          isExtendable={field.isExtendable}
          dropdownOptions={dropdownOptions}
          control={control}
          unregister={unregister}
          formType={formType}
        />
      );

    case "multiSelectLive":
      return (
        <FormMultiselectLive
          {...commonProps2}
          name={field.inputKey}
          optionsFetcher={optionsMapper[field.inputKey]}
          idParam="id"
          populateOptions={true}
          // onChange={handleInputChange}
          isFullWidth={isFullWidth}
        />
      );

    // case "multiSelect":
    //   return (
    //     <MultiSelectDropdown // handle change NOT fixed as component is not used in current scenario
    //       {...commonProps2}
    //       options={field.widgetAttributes.options}
    //     />
    //   );

    default:
      console.warn(`Unsupported widget type: ${field.widgetType}`);
      return null;
  }
};
