/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  Autocomplete,
  FormControl,
  TextField,
  autocompleteClasses,
} from "@mui/material";

import {
  SearchDropdownStyles,
  SelectFieldStyles,
} from "./FormUtils/FormStyles";

import styles from "./styles.module.css";
import Icon from "assets/Search.svg";

const IconPop = () => {
  return <img src={Icon} style={{ marginTop: "0.3rem" }} />;
};

export const FormSearchDropdown = (props: any) => {
  const {
    name,
    label,
    rules,
    placeholder,
    disabled,
    required,
    optionsFetcher,
    idParam,
    OptionsDisplayComp,
    onValueChange,
  } = props;

  const FieldStyles = {
    ...SelectFieldStyles,
    ...{
      [`& .${autocompleteClasses.popupIndicator}`]: {
        transform: "none",
      },
    },
  };

  const { control } = useFormContext();
  const [data, setData] = useState([]);

  const getOptionsData = async () => {
    let data = await optionsFetcher(idParam);
    setData(data);
  };
  useEffect(() => {
    if (optionsFetcher) {
      getOptionsData();
    }
  }, []);
  return (
    <>
      {data ? (
        <FormControl size="small" fullWidth>
          <label className={styles["form-label"]}>
            {label}{" "}
            {required ? (
              <span className={styles["required-symbol"]}>*</span>
            ) : (
              <></>
            )}
          </label>
          <Controller
            control={control}
            name={name}
            rules={rules}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <>
                <Autocomplete
                  disableClearable
                  popupIcon={<IconPop />}
                  onChange={(_event, item) => {
                    onChange(item);
                    if (onValueChange)
                      onValueChange(item);
                  }}
                  getOptionLabel={(option: any) =>
                    option?.label || value?.label || ""
                  }
                  isOptionEqualToValue={(option, value) =>
                    option?.id === value?.id
                  }
                  value={
                    (data.length &&
                      data?.find((item: any) => value?.id === item?.id)) ||
                    value ||
                    null
                  }
                  sx={FieldStyles}
                  disablePortal
                  options={data.length ? data : []}
                  disabled={disabled}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={placeholder}
                      sx={SearchDropdownStyles}
                      size="small"
                      error={!!error}
                    />
                  )}
                  renderOption={(props, option: any) =>
                    OptionsDisplayComp ? (
                      <OptionsDisplayComp data={option} {...props} key={option?.id}/>
                    ) : (
                      <></>
                    )
                  }
                />

                <div className={styles["error-msg"]}>
                  {error ? error.message : null}
                </div>
              </>
            )}
          />
        </FormControl>
      ) : (
        <> </>
      )}
    </>
  );
};
