/* eslint-disable */
enum FieldType {
  TEXT = "text",
  SELECT = "select",
  CHECKBOX = "checkbox",
  RADIO = "radio",
  DATE = "date",
  TIME = "time",
  NUMBER = "number",
  FILE = "file",
  AUTOCOMPLETE = "autocomplete",
  AUTOCOMPLETELIVE = "autocomplete-live",
  MULTISELECTLIVE = "multiselect-live",
  COLOR = "color",
}

export { FieldType };
