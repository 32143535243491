import React from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import styles from "./styles.module.css";
import locale from "../../locale/locale.json";

type LoginFormProps = {
  setPhone: Function;
};

const LoginForm = (props: LoginFormProps) => {
  const content = locale["en"];

  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    watch,
  } = useForm({
    criteriaMode: "all",
    mode: "onSubmit",
  });

  const watchPhone = watch("phone");

  const validationHandler = () => {
    let data = getValues();
    if (data?.phone?.length === 10 && Object.keys(errors).length === 0)
      return true;
    else return false;
  };

  const onSubmitHandler = (data: any) => {
    if (validationHandler()) props.setPhone(data.phone);
    return;
  };

  return (
    <div className={styles["form-wrapper"]}>
      <div className={styles["form-content"]}>
        <div className={styles["form-title"]}>{content.LOGIN_HEADING}</div>

        <form className="form" onSubmit={handleSubmit(onSubmitHandler)}>
          <label className={styles["form-label"]}>
            Enter your phone number
          </label>
          <div style={{ position: "relative" }}>
            <div className={styles["full-number"]}>
              <span
                className={`${styles["phone-number"]} ${styles["span-field"]}`}
              >
                +91
              </span>

              <input
                {...register("phone", {
                  required: {
                    value: true,
                    message: "Phone number is required!",
                  },
                  pattern: {
                    value: /^[6-9]\d{9}$/gi,
                    message: "Please enter a valid number.",
                  },
                })}
                maxLength={10}
                autoFocus
                id="phone"
                type="text"
                className={`${styles["phone-number"]} ${styles["text-field"]}`}
                data-testid="phone-input"
              />
            </div>
            <div className={styles["error-container"]}>
              <ErrorMessage
                errors={errors}
                name="phone"
                render={({ messages }) =>
                  messages &&
                  Object.entries(messages).map(([type, message], idx) => {
                    if (idx === 0)
                      return (
                        <p
                          className={styles["error-msg"]}
                          key={type}
                          data-testid="error-msg"
                        >
                          {message}
                        </p>
                      );
                  })
                }
              />
            </div>
          </div>
          <button
            type="submit"
            className={styles["submit-btn"]}
            disabled={watchPhone?.length === 10 ? false : true}
          >
            Request OTP
          </button>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
