import React from "react";

import SideDrawer from "components/Common Components/SideDrawer/SideDrawer";
import ViewTournamentForm from "components/Tournaments/ViewTournament/ViewTournamentForm";

function ViewTournament() {
  return (
    <>
      <SideDrawer>
        <div style={{ padding: "3.2rem" }}>
          <ViewTournamentForm />
        </div>
      </SideDrawer>
    </>
  );
}

export default ViewTournament;
