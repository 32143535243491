import { FieldType } from "components/Common Components/Form Fields/FieldAttributes";
import {
  DISPLAY_FORMAT,
  FORMATS,
  ISACTIVE,
  SPORTS,
  TYPES,
  BALLTYPES,
  ISSUBSTITUTIONALLOWED,
} from "./TournamentConstants";

export const leftSideColConfig = (formConfig: any, formName?: string) => {
  const config = [
    [
      {
        type: FieldType.TEXT,
        label: "Tournament Name",
        name: "name",
        placeholder: "Tournament name",
        disabled: formConfig.tName.disabled,
        required: true,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.DATE,
        label: "Start date",
        name: "start_date",
        placeholder: "DD/MM/YYYY",
        disabled: formConfig.startDate.disabled,
        required: true,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
      {
        type: FieldType.DATE,
        label: "End date",
        name: "end_date",
        placeholder: "DD/MM/YYYY",
        disabled: formConfig.endDate.disabled,
        required: true,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.RADIO,
        label: "Format",
        name: "format",
        disabled: formConfig.format.disabled,
        required: true,
        options: FORMATS,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.RADIO,
        label: "isActive",
        name: "is_active",
        disabled: formConfig.isActive.disabled,
        required: true,
        options: ISACTIVE,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.RADIO,
        label: "Type",
        name: "type",
        disabled: formConfig.type.disabled,
        required: true,
        options: TYPES,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.RADIO,
        label: "Ball Type",
        name: "ball_type",
        disabled: formConfig.ballType.disabled,
        required: true,
        options: BALLTYPES,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: formName !== "create" ? FieldType.AUTOCOMPLETE : "",
        label: "Player of the series",
        name: "player_of_series",
        placeholder: "Select player",
        disabled: formConfig?.playerOfSeries?.disabled,
        required: true,
        optionsFetcher: formConfig?.playerOfSeries?.optionsFetcher,
        idParam: formConfig?.playerOfSeries?.idParam,
        OptionsDisplayComp: formConfig?.playerOfSeries?.OptionsDisplayComp,
        rules: {
          required: {
            value: false,
            message: "This field is mandatory.",
          },
        },
      },
    ],
  ];
  return config;
};

export const rightSideColConfig = (formConfig: any, formName?: string) => {
  const config = [
    [
      {
        type: FieldType.FILE,
        label: "Upload Photo",
        name: "image_url",
        disabled: formConfig.uploadPhoto.disabled,
        required: true,
        formType: "TOURNAMENT_VIEW",
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.SELECT,
        label: "Display Format",
        name: "sub_format",
        placeholder: "Select format",
        disabled: formConfig.displayFormat.disabled,
        required: true,
        options: DISPLAY_FORMAT,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.TEXT,
        label: "Host City",
        name: "host_city",
        placeholder: "Enter City",
        disabled: formConfig.hostCity.disabled,
        required: true,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.RADIO,
        label: "Sport",
        name: "sport",
        disabled: formConfig.sport.disabled,
        required: true,
        options: SPORTS,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: formName !== "create" ? FieldType.AUTOCOMPLETE : "",
        label: "Winning Team ID",
        name: "winning_team_id",
        placeholder: "Select winning team ID",
        disabled: formConfig?.winningTeamID?.disabled,
        required: false,
        optionsFetcher: formConfig?.winningTeamID?.optionsFetcher,
        idParam: formConfig?.winningTeamID?.idParam,
        OptionsDisplayComp: formConfig?.winningTeamID?.OptionsDisplayComp,
        rules: {
          required: {
            value: false,
            message: "This field is mandatory.",
          },
        },
      },
    ],
    [
      {
        type: FieldType.RADIO,
        label: "Is impact player allowed?",
        name: "isSubstitutionAllowed",
        disabled: formConfig.isSubstitutionAllowed.disabled,
        required: true,
        options: ISSUBSTITUTIONALLOWED,
        rules: {
          required: {
            value: true,
            message: "This field is mandatory.",
          },
        },
      },
    ],
  ];
  return config;
};
