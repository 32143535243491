import React from "react";

import SideDrawer from "components/Common Components/SideDrawer/SideDrawer";
import CreateGroundForm from "components/Grounds/CreateGround//CreateGroundForm";

function CreateScorer() {
  return (
    <>
      <SideDrawer>
        <div style={{ padding: "3.2rem" }}>
          <CreateGroundForm />
        </div>
      </SideDrawer>
    </>
  );
}

export default CreateScorer;
