import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { Box, Divider, Grid } from "@mui/material";
import { toast } from "react-toastify";

import FieldMapper from "components/Common Components/Form Fields/FormUtils/FieldMapper";
import FormButton from "components/Common Components/Buttons/FormButton";
import EntityDropDown from "components/Common Components/Dropdowns/EntityDropdown";
import { StyledStatusChip } from "components/Common Components/DataTable/DatatableConfig";
import { indoorCricketConfig } from "../formConfig";

import {
  leftSideColConfig,
  rightSideColConfig,
  badmintonConfig,
} from "../formConfig";
import colors from "config/colorConfig";
import { STATUS_LIST } from "utils/constants";
import {
  formatGetMatchDetailsData,
  formatPostMatchDetailsData,
  getScorersListMapped,
  getTeamsListMapped,
} from "../helper";
import { editMatch, getMatchDetailsById } from "services/matches";
import styles from "../styles.module.css";
import { logFirebaseEvent } from "config/firebase";
import BackdropLoader from "components/Common Components/Loaders/LoaderBackdrop";

function EditMatchForm() {
  const methods = useForm({
    criteriaMode: "firstError",
    mode: "onBlur",
    shouldFocusError: true,
  });
  const {
    handleSubmit,
    setValue,
    watch,
    setError,
    getValues,
    formState: { isDirty },
  } = methods;

  const { matchId } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();

  const [matchStatus, setMatchStatus] = useState("");
  const [tournamentId, setTournamentId] = useState("");
  const [leftColConfig, setLeftColConfig] = useState<any>();
  const [rightColConfig, setRightColConfig] = useState<any>();
  const [loaderState, setLoaderState] = useState(false);

  const initializePageData = () => {
    if (!state) {
      fetchMatchData();
    } else {
      const { matchData } = state;
      setMatchStatus(matchData?.status);
      setTournamentId(matchData?.tournament_id);
      setPageData(matchData);
    }
  };

  const fetchMatchData = async () => {
    setLoaderState(true);
    if (matchId) {
      const result = await getMatchDetailsById(matchId);

      if (result && !result?.isError && result?.data) {
        const matchData = result?.data;
        setMatchStatus(matchData?.status);
        setTournamentId(matchData?.tournament_id);
        const formattedData = formatGetMatchDetailsData(matchData);

        setPageData(formattedData);
      } else {
        toast.error(result?.reason);
      }
    } else {
      toast.error("There was some error! Please try again.");
    }
    setLoaderState(false);
  };

  const setMatchConfig = () => {
    if (matchStatus === STATUS_LIST.SCHEDULED) {
      setLeftColConfig(leftSideColConfig(formConfigUpcoming, "edit"));
      setRightColConfig(rightSideColConfig(formConfigUpcoming, "edit"));
    } else if (
      matchStatus === STATUS_LIST.ONGOING ||
      matchStatus === STATUS_LIST.PAUSED
    ) {
      setLeftColConfig(leftSideColConfig(formConfigOngoing, "edit"));
      setRightColConfig(rightSideColConfig(formConfigOngoing, "edit"));
    }
  };

  const setPageData = (data: any) => {
    for (const [key, value] of Object.entries(data)) {
      value && setValue(key, value);
    }
    setValue("is_active", data?.is_active);
  };

  const handleValidations = () => {
    const teamAId = getValues("team_a")?.id;
    const teamBId = getValues("team_b")?.id;
    const lineUpSize = Number(getValues("lineup_size"));
    // const oversPerInnings = Number(getValues("overs_per_inning"));

    if (teamAId === teamBId) {
      setError(
        "team_b",
        {
          type: "custom",
          message: "Team A and Team B can not be same!",
        },
        { shouldFocus: true }
      );
      return false;
    }

    if (lineUpSize < 1 || lineUpSize > 15) {
      setError(
        "lineup_size",
        {
          type: "custom",
          message: "Please enter a value between 1 and 15!",
        },
        { shouldFocus: true }
      );
      return false;
    }

    // if (oversPerInnings < 1 || oversPerInnings > 50) {
    //   setError(
    //     "overs_per_inning",
    //     {
    //       type: "custom",
    //       message: "Please enter a value between 1 and 50!",
    //     },
    //     { shouldFocus: true }
    //   );
    //   return false;
    // }

    return true;
  };

  const formSubmitHandler = async (data: any) => {
    if (!handleValidations()) return;

    setLoaderState(true);
    // data["overs_per_inning"] = Number(data["overs_per_inning"]);
    const formattedData = formatPostMatchDetailsData(data);
    formattedData.matchDetails["tournament_id"] = tournamentId;

    let result = await editMatch(formattedData);
    if (result && !result?.isError) {
      logFirebaseEvent({
        event_name: "match_edit_save",
        tournament_id: tournamentId,
        match_id: matchId,
        match_status: matchStatus,
      });
      navigate(-1);
      toast.success("Successful!");
    } else {
      toast.error(result?.reason);
    }
    setLoaderState(false);
  };

  const matchSport = watch("match_sport");

  const formConfigUpcoming = {
    teamAName: {
      disabled: false,
      optionsFetcher: getTeamsListMapped,
      OptionsDisplayComp: EntityDropDown,
      idParam: tournamentId,
    },
    teamBName: {
      disabled: false,
      optionsFetcher: getTeamsListMapped,
      OptionsDisplayComp: EntityDropDown,
      idParam: tournamentId,
    },
    isActive: {
      disabled: false,
    },
    sport: {
      disabled: false,
    },
    scorer: {
      disabled: false,
      optionsFetcher: getScorersListMapped,
      OptionsDisplayComp: EntityDropDown,
    },
    matchType: {
      disabled: false,
    },
    startTime: {
      disabled: false,
    },
    overs: {
      disabled: matchSport === "FOOTBALL" ? true : false,
      required: matchSport === "FOOTBALL" ? false : true,
    },
    venue: {
      disabled: false,
    },
    urlLink: {
      disabled: false,
    },
    lineUpSize: {
      disabled: false,
    },
    maxOvers: {
      disabled: (matchSport === "FOOTBALL" ||matchSport === "INDOOR_CRICKET") ? true : false,
      required: (matchSport === "FOOTBALL" ||matchSport === "INDOOR_CRICKET") ? false : true,
    },
    oversPerBowler: {
      disabled: (matchSport === "FOOTBALL" ||matchSport === "INDOOR_CRICKET") ? true : false,
      required: (matchSport === "FOOTBALL" ||matchSport === "INDOOR_CRICKET") ? false : true,
    },
    ballsPerOver: {
      disabled: false,
    },
    oversPerSkin: {
      disabled: false,
    },
    skinsPerInning: {
      disabled: false,
    },
  };

  const formConfigOngoing = {
    teamAName: {
      disabled: true,
      optionsFetcher: getTeamsListMapped,
      OptionsDisplayComp: EntityDropDown,
      idParam: tournamentId,
    },
    teamBName: {
      disabled: true,
      optionsFetcher: getTeamsListMapped,
      OptionsDisplayComp: EntityDropDown,
      idParam: tournamentId,
    },
    isActive: {
      disabled: true,
    },
    sport: {
      disabled: true,
    },
    scorer: {
      disabled: true,
      optionsFetcher: getScorersListMapped,
      OptionsDisplayComp: EntityDropDown,
    },
    matchType: {
      disabled: true,
    },
    startTime: {
      disabled: true,
    },
    overs: {
      disabled: true,
      required: matchSport === ("FOOTBALL" || "INDOOR_CRICKET") ? false : true,
    },
    venue: {
      disabled: true,
    },
    urlLink: {
      disabled: false,
    },
    lineUpSize: {
      disabled: true,
    },
    maxOvers: {
      disabled: true,
    },
    oversPerBowler: {
      disabled: true,
      required: matchSport === "INDOOR_CRICKET" ? false : true,
    },
    ballsPerOver: {
      disabled: false,
      required: matchSport === "INDOOR_CRICKET" ? false : true,
    },
    oversPerSkin: {
      disabled: false,
    },
    skinsPerInning: {
      disabled: false,
    },
  };

  const formConfig = {
    matchCategory: {
      disabled: false,
    },
    matchName: {
      disabled: false,
    },
    matchNumber: {
      disabled: false,
    },
    sequenceNumber: {
      disabled: false,
    },
    ballsPerOver: {
      disabled: false,
    },
    oversPerSkin: {
      disabled: false,
    },
    skinsPerInning: {
      disabled: false,
    },
  };

  useEffect(() => {
    setMatchConfig();
  }, [matchStatus, matchSport]);

  useEffect(() => {
    initializePageData();
  }, []);

  return (
    <>
      <div className={styles["form-heading"]}>
        <Box display="flex" alignItems="center">
          <span> Edit match</span> &nbsp;
          {matchStatus && <span>{StyledStatusChip(matchStatus)}</span>}
        </Box>

        {matchId && <div className={styles["match-id"]}>ID - #{matchId}</div>}
      </div>

      <div className={styles["form-container"]}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(formSubmitHandler)}>
            <Grid container spacing={6}>
              <Grid item xs={12} md={6}>
                {leftColConfig ? (
                  <FieldMapper fieldsConfig={leftColConfig} />
                ) : (
                  <></>
                )}
              </Grid>

              <Grid item xs={12} md={6}>
                {rightColConfig ? (
                  <FieldMapper fieldsConfig={rightColConfig} />
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>

            <Divider sx={{ my: 3 }} />

            {matchSport === "BADMINTON" && (
              <>
                <Divider sx={{ my: 3 }} />
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  BADMINTON FIELDS
                </div>
                <Grid item xs={12} md={6}>
                  <FieldMapper fieldsConfig={badmintonConfig(formConfig)} />
                </Grid>
              </>
            )}

            {matchSport === "INDOOR_CRICKET" && (
                <>
                  <Divider sx={{ my: 3 }} />
                  <div
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                  >
                    INDOOR CRICKET FIELDS
                  </div>
                  <Grid item xs={12} md={6}>
                    <FieldMapper
                        fieldsConfig={indoorCricketConfig(formConfig)}
                    />
                  </Grid>
                </>
            )}

            <Grid container item columnGap={3}>
              <FormButton
                type="button"
                fontColor={colors.redSixty}
                backgroundColor={colors.white}
                borderColor={colors.grayThirty}
                onClick={() => navigate(-1)}
              >
                Discard
              </FormButton>

              <FormButton
                type="submit"
                fontColor={colors.white}
                backgroundColor={colors.grayNinety}
                disabledColor={colors.grayFifty}
                disabledBackground={colors.grayTwenty}
                disabled={!isDirty}
              >
                Save changes
              </FormButton>
            </Grid>
          </form>
        </FormProvider>
      </div>
      <BackdropLoader loaderState={loaderState} />
    </>
  );
}

export default EditMatchForm;
