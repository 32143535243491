import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Divider } from "@mui/material";
import { toast } from "react-toastify";

import CancelMatchModal from "../Modals/CancelMatch";
import BasicModal from "components/Common Components/Modal/BasicModal";
import AbondonMatchModal from "../Modals/AbondonMatch";

import { updateMatchStatus } from "services/matches";
import { logFirebaseEvent } from "config/firebase";
import { STATUS_LIST } from "utils/constants";
import colors from "config/colorConfig";
import MenuIcon from "assets/Menu.svg";

type Props = {
  selectedMatchData: any;
};

export default function ViewMatchMenu(props: Props) {
  const { selectedMatchData } = props;

  const navigate = useNavigate();

  const menuButtonStyles = {
    borderRadius: "0.8rem",
    border: "0.1rem solid",
    borderColor: colors.grayThirty,
    backgroundColor: colors.white,
    padding: "1.2rem",
    width: "max-content",
    minWidth: "auto",
  };

  const kebabMenuStyles = {
    ".MuiMenuItem-root": {
      fontSize: "1.4rem",
      lineHeight: "2rem",
      fontWeight: 600,
    },
  };

  const menuList = [
    {
      label: "Cancel Match",
      disabled:
        selectedMatchData?.status === STATUS_LIST.SCHEDULED ? false : true,
      color: colors.redSixty,
      clickAction: () => setCancelModalState(!cancelModalState),
    },
    {
      label: "Abandon Match",
      disabled:
        selectedMatchData?.status === STATUS_LIST.SCHEDULED ? true : false,
      color: colors.grayNinety,
      clickAction: () => setAbondonModalState(!abondonModalState),
    },
  ];

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [cancelModalState, setCancelModalState] = useState(false);
  const [abondonModalState, setAbondonModalState] = useState(false);

  const handleMatchStatus = async (newStatus: string) => {
    const result = await updateMatchStatus(
      selectedMatchData?.match_id,
      newStatus
    );
    if (result && !result?.isError) {
      logFirebaseEvent({
        event_name: newStatus===STATUS_LIST.ABONDENED ? "match_abandon" : "match_cancel",
        match_id:selectedMatchData?.match_id,
       tournament_id: selectedMatchData?.tournament_id
      });
      toast.success("Status changed succussfully!");
      navigate(-1);
    } else {
      toast.error(result?.reason);
    }
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={menuButtonStyles}
        disabled={
          selectedMatchData?.status === STATUS_LIST.COMPLETED ||
          selectedMatchData?.status === STATUS_LIST.CANCELLED ||
          selectedMatchData?.status === STATUS_LIST.ABONDENED ||
          selectedMatchData?.status === STATUS_LIST.RESULT
            ? true
            : false
        }
      >
        <img src={MenuIcon} alt="menu-icon" />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={kebabMenuStyles}
      >
        {menuList.map((item: any, key: number) => {
          return (
            <div key={key}>
              <MenuItem
                onClick={item.clickAction}
                disabled={item.disabled}
                sx={{ color: item.color }}
              >
                {item.label}
              </MenuItem>
              {key !== menuList.length - 1 ? <Divider /> : <></>}
            </div>
          );
        })}
      </Menu>
      <BasicModal
        modalState={cancelModalState}
        handleModalState={setCancelModalState}
      >
        <CancelMatchModal
          modalState={cancelModalState}
          handleModalState={setCancelModalState}
          handleMatchStatus={handleMatchStatus}
        />
      </BasicModal>
      <BasicModal
        modalState={abondonModalState}
        handleModalState={setAbondonModalState}
      >
        <AbondonMatchModal
          modalState={abondonModalState}
          handleModalState={setAbondonModalState}
          handleMatchStatus={handleMatchStatus}
        />
      </BasicModal>
    </div>
  );
}
