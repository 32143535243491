import React from "react";
import { Box } from "@mui/material";

import IndexForm from "../components/Login";
import colors from "../config/colorConfig";
import logo from "../assets/pavillion-logo.svg";

const Logo = () => {
  return (
    <header style={{ textAlign: "center", padding: "3.6rem 0 0 0" }}>
      <img src={logo} alt="" />
    </header>
  );
};

const TradeMark = () => {
  return (
    <footer
      style={{
        textAlign: "center",
        width: "100%",
        backgroundColor: colors.grayTen,
      }}
    >
      <p>Pavillion Inc.</p>
    </footer>
  );
};

function LoginIndex() {
  return (
    <>
      <Box
        height="100vh"
        sx={{ backgroundColor: colors.grayTen, minHeight: "100vh" }}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          height="100%"
        >
          <Logo />
          <Box
            justifyContent="center"
            alignItems="center"
            display="flex"
            sx={{ flex: "1" }}
          >
            <IndexForm />
          </Box>
          <TradeMark />
        </Box>
      </Box>
    </>
  );
}

export default LoginIndex;
