import React, { useState } from "react";
import { Controller } from "react-hook-form";

import TextInput from "components/InputComponentsMultiStep/Text";
import NumberInput from "components/InputComponentsMultiStep/Number";
import RadioSelect from "components/InputComponentsMultiStep/RadioSelect";
import ImageUpload from "components/InputComponentsMultiStep/ImageUpload";
import SearchableMultiSelectDropdown from "components/InputComponentsMultiStep/SearchableMultiSelectDropdown";
import FormMultiselectLive from "components/InputComponentsMultiStep/MultiSelectLive";

import { getTeamsListMapped } from "components/Matches/helper";

import styles from "./style.module.scss";

const CustomInput = ({
  inputKey,
  label,
  componentArray,
  isExtendable,
  control,
  isEditable,
  onInputChange,
  dropdownOptions,
  unregister,
  formType,
  presentValue = [],
}: any) => {
  // Ensure 'presentValue' is always an array
  const [values, setValues] = useState(
    Array.isArray(presentValue) ? presentValue : []
  );

  const addNewEntry = () => {
    if (!isEditable) return;
    setValues((prevValues) => [...prevValues, {}]); // Add a new empty entry
  };

  const removeEntry = (index: number) => {
    if (!isEditable) return;

    // Unregister all fields related to this entry index
    componentArray.forEach((field: any) => {
      const fieldName = `${inputKey}[${index}].${field.inputKey}`;
      unregister(fieldName, { keepDirty: false }); // Ensure it doesn't retain values
    });

    // Update local state to remove the entry
    setValues((prevValues) => prevValues.filter((_, i) => i !== index));
  };

  const getSearchableOptions = (dropdownOptions: any, inputKey: string) => {
    switch (inputKey) {
      case "venue":
        return dropdownOptions.venue;
      default:
        return dropdownOptions.venue;
    }
  };

  const optionsMapper: any = {
    team: getTeamsListMapped,
  };

  const renderField = (field: any, entryIndex: number) => {
    const fieldName = `${inputKey}[${entryIndex}].${field.inputKey}`; // Unique name for each entry

    return (
      <Controller
        key={fieldName}
        name={fieldName}
        control={control}
        defaultValue={values[entryIndex]?.[field.inputKey] || ""}
        rules={{
          required: field.widgetAttributes.validations?.isMandatory
            ? "This field is required"
            : false,
        }}
        render={({ field: controllerField }) => {
          const commonProps = {
            ...controllerField,
            label: field.widgetAttributes.label,
            placeholder: field?.widgetAttributes?.details?.hint,
            isRequired: field?.widgetAttributes?.isMandatory,
            isEditable:
              formType === "view" ? false : field?.widgetAttributes?.isEditable,
            inputKey: field.inputKey,
            presentValue:
              values[entryIndex]?.[field.inputKey] ||
              field?.widgetAttributes?.details?.value,
            onInputChange: (value: any) => {
              controllerField.onChange(value);
              setValues((prevValues) => {
                const updatedValues = [...prevValues];
                updatedValues[entryIndex] = {
                  ...updatedValues[entryIndex],
                  [field.inputKey]: value,
                };
                return updatedValues;
              });
            },
          };

          switch (field.widgetType) {
            case "inputText":
              return (
                <TextInput
                  {...commonProps}
                  maxLength={field?.widgetAttributes?.details?.maxLength}
                  isFullWidth={false}
                />
              );

            case "inputInt":
              return (
                <NumberInput // handle change done
                  {...commonProps}
                  maxLength={field?.widgetAttributes?.details?.maxValue}
                  // isFullWidth={false}
                />
              );

            case "singleSelect":
              return (
                <RadioSelect
                  {...commonProps}
                  options={field?.widgetAttributes?.details?.options}
                  isFullWidth={false}
                />
              );

            case "imgUpload":
              return <ImageUpload {...commonProps} isFullWidth={false} />;

            case "multiSelectSearch":
              return (
                <SearchableMultiSelectDropdown
                  {...commonProps}
                  options={getSearchableOptions(
                    dropdownOptions,
                    field.inputKey
                  )}
                  isFullWidth={false}
                />
              );

            case "multiSelectLive":
              return (
                <FormMultiselectLive
                  {...commonProps}
                  name={field.inputKey}
                  optionsFetcher={optionsMapper[field.inputKey]}
                  idParam="id"
                  populateOptions={true}
                  isFullWidth={false}
                  // onChange={handleInputChange}
                />
              );

            default:
              return <div>Unknown widget type: {field.widgetType}</div>;
          }
        }}
      />
    );
  };

  return (
    <div className={styles["custom-input-container"]}>
      {values.map((entry, index) => (
        <div key={index} className={styles["custom-input-entry"]}>
          {componentArray.map((field: any) => (
            <>{renderField(field, index)}</>
          ))}
          {isExtendable && (
            <div className={styles["remove-entry-wrapper"]}>
              <div
                onClick={() => removeEntry(index)}
                className={styles["remove-entry-button"]}
              >
                Remove
              </div>
            </div>
          )}
        </div>
      ))}

      {isExtendable && (
        <div className={styles["add-entry-wrapper"]}>
          <div onClick={addNewEntry} className={styles["add-entry-button"]}>
            Add More
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomInput;
