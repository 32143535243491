import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { toast } from "react-toastify";

import DataTable from "components/Common Components/DataTable/Index";
import EmptyList from "components/Common Components/EmptyList";

import { getPlayersByTeam } from "services/teams";
import { PlayerListConfig } from "./listConfig";
import { logFirebaseEvent } from "config/firebase";
import { PLAYER_PAGE_SIZE } from "utils/constants";
import styles from "./styles.module.css";
import BackdropLoader from "components/Common Components/Loaders/LoaderBackdrop";

function PlayerListByTeam() {
  const navigate = useNavigate();
  const { teamId } = useParams();

  const [dataList, setDataList] = useState([]);
  const [totalRows, setTotalRows] = useState(10);
  const [emptyListFlag, setEmptyListFlag] = useState(false);
  const [loaderState, setLoaderState] = useState(false)

  const getPlayersList = async (reqData: any) => {
    setLoaderState(true)
    let result = await getPlayersByTeam(reqData);
    if (result && !result?.isError) {
      const playerList = result?.data?.list;
      if (!playerList?.length) {
        setLoaderState(false)
        setEmptyListFlag(true);
        return;
      }
      setDataList(playerList);
      setTotalRows(result?.data?.pagination?.totalRows);
    } else {
      toast.error(result?.reason);
    }
    setLoaderState(false)
  };

  const handleRowClick = (playerData: any) => {
    const playerId = playerData?.row?.player_id;
    logFirebaseEvent({
      event_name: "player_view",
      player_id: playerId,
    });
    navigate(`/player/view/${playerId}`);
  };

  const handleSortingAndPagination = (
    page: number,
    pageSize: number,
    sortKey: string,
    sortType: string
  ) => {
    const reqData: any = {};
    const sortOptions: any = {};

    if (sortKey) {
      sortOptions.key = sortKey;
      sortOptions.type = sortType.toUpperCase();

      reqData.sortby = sortOptions;
    }
    reqData.page = page;
    reqData.pageSize = pageSize;
    reqData.filter = { team_id: teamId };

    getPlayersList(reqData);
  };

  useEffect(() => {
    // logFirebaseEvent({ event_name: "player_home" });
    getPlayersList({
      page: 1,
      pageSize: PLAYER_PAGE_SIZE,
      filter: {
        team_id: teamId,
      },
    });
  }, []);

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <div className={styles["form-heading"]}>Players &nbsp;</div>
      </Box>
      {dataList?.length ? (
        <DataTable
          columns={PlayerListConfig}
          data={dataList}
          totalRows={totalRows}
          pageSize={PLAYER_PAGE_SIZE}
          onRowClick={handleRowClick}
          sortPaginationHandler={handleSortingAndPagination}
        />
      ) : (
        <></>
      )}
      {emptyListFlag ? <EmptyList message="No Players in this team!" /> : <></>}
      <BackdropLoader loaderState={loaderState} />
    </>
  );
}

export default PlayerListByTeam;
