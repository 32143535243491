import { GridColDef } from "@mui/x-data-grid";
import dayjs from "dayjs";

import {
  StyledStatusChip,
  StyledBoolean,
  ValueWithCopy,
} from "components/Common Components/DataTable/DatatableConfig";

export const TournamentListConfig: GridColDef[] = [
  {
    field: "name",
    headerName: "TOURNAMENT NAME",
    flex: 1.5,
    disableColumnMenu: true,
    hideable: false,
  },
  {
    field: "tournament_id",
    headerName: "ID",
    flex: 2.5,
    disableColumnMenu: true,
    hideable: false,
    renderCell: (params: any) => {
      return ValueWithCopy(params.value);
    },
  },

  {
    field: "start_date",
    headerName: "START DATE",
    disableColumnMenu: true,
    flex: 1,
    hideable: true,
    renderCell: (params: any) => {
      return dayjs(params.value).format("DD MMM, YYYY");
    },
  },

  {
    field: "status",
    headerName: "STATUS",
    sortable: true,
    flex: 1,
    disableColumnMenu: true,
    renderCell: (params: any) => {
      return StyledStatusChip(params.value);
    },
  },
  {
    field: "sport",
    headerName: "SPORT",
    sortable: true,
    flex: 1,
    disableColumnMenu: true,
  },
  {
    field: "host_city",
    headerName: "HOST CITY",
    sortable: true,
    flex: 1,
    disableColumnMenu: true,
  },
  {
    field: "is_active",
    headerName: "IS ACTIVE",
    sortable: true,
    flex: 1,
    disableColumnMenu: true,
    renderCell: (params: any) => {
      return StyledBoolean(params.value);
    },
  },
];

export const visibilityConfig = {
  firstName: true,
  lastName: false,
};
