import React, { Component, ErrorInfo } from "react";
import { Navigate } from "react-router-dom";
import { Box } from "@mui/material";

import HeaderOptionsButton from "components/Common Components/Buttons/HeaderOptionsButton";
import colors from "config/colorConfig";
import ErrorImage from "assets/error.svg";

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    console.warn(error);
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.warn("There was an error! ", error, errorInfo);
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
          >
            <img src={ErrorImage} alt="error" width="30%" />
            <br />
            <br />
            <div
              style={{
                fontSize: "4rem",
                fontWeight: "600",
                marginBottom: "2rem",
              }}
            >
              Oops! Something went wrong!
            </div>
            <br />
            <br />
            <HeaderOptionsButton
              fontColor={colors.grayNinety}
              backgroundColor={colors.white}
              borderColor={colors.grayForty}
              onClick={() => {
                this.setState({ hasError: false }, () => {
                  <Navigate to={"/"} replace/>;
                });
              }}
            >
              Return to Homepage
            </HeaderOptionsButton>
          </Box>
        </>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
