import React from "react";
import { Box } from "@mui/material";

import colors from "config/colorConfig";
import MapIcon from "assets/MapPinLine.svg";
import ContactIcon from "assets/IdentificationBadge.svg";

function EntityDropDown(props: any) {
  let image = props?.data?.image;
  let location = props?.data?.location;
  let id = props?.data?.id;
  let label = props?.data?.label;
  return (
    <Box
      component="li"
      display="flex"
      sx={{
        "& > img": {
          mr: 2,
          flexShrink: 0,
          width: "4rem",
          borderRadius: "1rem",
        },
        fontSize: "1.4rem",
      }}
      {...props}
    >
      {image ? <img loading="lazy" width="20" height="20" src={image} alt="" /> : <></>}
      <div
        style={{
          color: colors.grayNinety,
          fontWeight: 600,
          fontSize: "1.6rem",
        }}
      >
        {label}
        <div
          style={{
            color: colors.graySixty,
            fontWeight: 400,
            fontSize: "1.4rem",
          }}
        >
          {location ? (
            <span>
              <img
                src={MapIcon}
                alt="map-icon"
                style={{ verticalAlign: "middle" }}
              />
              {location}
            </span>
          ) : (
            <></>
          )}
          &nbsp;
          {id ? (
            <span>
              <img
                src={ContactIcon}
                alt="contact-icon"
                style={{ verticalAlign: "middle" }}
              />{" "}
              {id}
            </span>
          ) : (
            <></>
          )}
        </div>
      </div>
    </Box>
  );
}

export default EntityDropDown;
