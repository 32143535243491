import React, { useState, useEffect } from "react";
import styles from "./style.module.scss";

interface NumberInputProps {
  maxLength: number;
  inputKey: string;
  isRequired: boolean;
  label: string;
  placeholder: string;
  presentValue: string;
  isEditable?: boolean;
  onInputChange: any;
  isFullWidth?: boolean;
}

const NumberInput = ({
  maxLength,
  inputKey,
  isRequired,
  label,
  placeholder,
  presentValue,
  isEditable,
  onInputChange,
  isFullWidth,
}: NumberInputProps) => {
  const [value, setValue] = useState(presentValue || "");

  useEffect(() => {
    // Update the input value when presentValue changes (e.g., when editing)
    setValue(presentValue || "");
  }, [presentValue]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;

    // Allow only numeric input
    if (/^\d*$/.test(newValue) && newValue.length <= maxLength) {
      setValue(newValue); // Update local state
      onInputChange(newValue); // Call the onInputChange function
    }
  };

  return (
    <div
      className={styles["number-input-container"]}
      style={{ width: isFullWidth ? "100%" : "" }}
    >
      <label htmlFor={inputKey}>
        {label}
        {isRequired && <span style={{ color: "red" }}> *</span>}
      </label>
      <input
        type="text"
        id={inputKey}
        placeholder={placeholder}
        maxLength={maxLength}
        required={isRequired}
        value={value}
        disabled={!isEditable}
        onChange={handleChange}
      />
    </div>
  );
};

export default NumberInput;
