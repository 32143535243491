import React from "react";
import MultiStepForm from "components/TournamentV3/Registration";
import Navbar from "components/Navbar";

interface indexProps {
  formType: string;
}

const index = ({ formType }: indexProps) => {
  const styles = {
    padding: "40px",
    height: "100%",
    background: "#0b0b0b",
  };

  const container = {
    display: "flex",
  };

  return (
    <>
      <Navbar />
      <div style={container}>
        <main>
          <div style={styles}>
            <MultiStepForm formType={formType} />
          </div>
        </main>
      </div>
    </>
  );
};

export default index;
