/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useForm, FormProvider } from "react-hook-form";
import { Divider, Grid } from "@mui/material";
import { toast } from "react-toastify";

import FieldMapper from "components/Common Components/Form Fields/FormUtils/FieldMapper";
import FormButton from "components/Common Components/Buttons/FormButton";
import BasicModal from "components/Common Components/Modal/BasicModal";
import DiscardModal from "components/Common Components/Modal/DiscardModal";

import { createMatch, createNtpcMatch } from "services/matches";
import { formatPostMatchDetailsData, formatNtpcMatchData } from "../helper";

import { leftSideColConfig, rightSideColConfig } from "../formConfig";
import { logFirebaseEvent } from "config/firebase";
import colors from "config/colorConfig";
import styles from "../styles.module.css";
import BackdropLoader from "components/Common Components/Loaders/LoaderBackdrop";

function CreateMatchForm() {
  const methods = useForm({
    criteriaMode: "firstError",
    mode: "onBlur",
    shouldFocusError: true,
  });
  const {
    handleSubmit,
    getValues,
    setError,
    formState: { isValid, isDirty },
  } = methods;

  const navigate = useNavigate();
  const { tournamentId } = useParams();
  const { state } = useLocation();

  const [modalState, setModalState] = useState(false);
  const [loaderState, setLoaderState] = useState(false);

  const formSubmitHandler = async (data: any) => {
    setLoaderState(true);
    const formattedData = formatNtpcMatchData(data);
    console.log("formatted data: ", formattedData);

    let result = await createNtpcMatch(formattedData);
    console.log("result: ", result);
    if (result && !result?.isError) {
      navigate(-1);
      toast.success("Match created successfully!");
    } else {
      toast.error(result?.reason);
    }
    setLoaderState(false);
    return;
  };

  const formConfig = {
    teamAName: {
      disabled: false,
    },
    teamBName: {
      disabled: false,
    },
    isActive: {
      disabled: false,
    },
    sport: {
      disabled: false,
    },
    sportType: {
      disabled: false,
    },
    matchName: {
      disabled: false,
    },

    startTime: {
      disabled: false,
    },
    youtubeLink: {
      disabled: false,
    },
    scoringLink: {
      disabled: false,
    },
    teamALineUp: {
      disabled: false,
    },
    teamBLineUp: {
      disabled: false,
    },
  };

  return (
    <>
      <div
        className={styles["form-heading"]}
        data-testid="match-create-heading"
      >
        Add match &nbsp;
        <span className={styles["tournament_name"]}>
          {`(${state?.tournamentName})`}
        </span>
      </div>

      <div className={styles["form-container"]}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(formSubmitHandler)}>
            <Grid container spacing={6}>
              <Grid item xs={12} md={6}>
                <FieldMapper
                  fieldsConfig={leftSideColConfig(formConfig, "create")}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FieldMapper
                  fieldsConfig={rightSideColConfig(formConfig, "create")}
                />
              </Grid>
            </Grid>

            <Divider sx={{ my: 3 }} />

            <Grid container item columnGap={3}>
              <FormButton
                type="button"
                fontColor={colors.redSixty}
                backgroundColor={colors.white}
                borderColor={colors.grayThirty}
                onClick={() =>
                  !isDirty
                    ? navigate("/tournament")
                    : setModalState(!modalState)
                }
              >
                Discard
              </FormButton>

              <FormButton
                type="submit"
                fontColor={colors.white}
                backgroundColor={colors.grayNinety}
                disabledColor={colors.grayFifty}
                disabledBackground={colors.grayTwenty}
                disabled={!isValid}
              >
                Save match
              </FormButton>
            </Grid>
          </form>
        </FormProvider>
      </div>
      <BasicModal modalState={modalState} handleModalState={setModalState}>
        <DiscardModal
          modalState={modalState}
          handleModalState={setModalState}
        />
      </BasicModal>
      <BackdropLoader loaderState={loaderState} />
    </>
  );
}

export default CreateMatchForm;
